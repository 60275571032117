import styled from 'styled-components'
import {useSelector} from 'react-redux'

import {ProductsWithCartItemsAndCategories} from 'src/models/cart'
import ListHeader from './components/ListHeader'
import {selectCategoriesObj} from 'src/models/catalog'
import ProductsListItem from 'src/components/ProductsListItem'
import {ProductContract} from 'src/types/api'
import {forwardRef, useImperativeHandle, useRef} from 'react'

interface DesktopProductsListProps {
  products?: ProductsWithCartItemsAndCategories
  onAdd?: (product: ProductContract) => void
  onIncrease?: (id: string) => void
  onDecrease?: (id: string) => void
  onItemClick: (data: {productId: string; cartId?: string}) => void
}

export interface DesktopProductsListRef {
  getScrollPositionById: (categoryId: string) => number | undefined
  getIdByScrollPosition: (scrollPosition: number) => string | undefined
}

const ListHeaderContainer = styled.div`
  padding: 1.5rem 0;
`

const ProductsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.125rem;
`

const DesktopProductsList = forwardRef<DesktopProductsListRef, DesktopProductsListProps>(
  ({products = {}, onAdd, onDecrease, onIncrease, onItemClick}, ref) => {
    const categoriesObj = useSelector(selectCategoriesObj)

    const categoriesScrollPositions = useRef<{[categoryId: string]: {scrollPosition: number; categoryId: string}}>({})

    const setCategoriesScrollPositions = (itemRef: HTMLDivElement | null, categoryId: string) => {
      if (!itemRef) {
        return
      }

      categoriesScrollPositions.current[categoryId] = {scrollPosition: itemRef.offsetTop, categoryId}
    }

    const getScrollPositionById = (categoryId: string) => {
      const category = categoriesScrollPositions.current[categoryId]
      if (!category) {
        return
      }

      return category.scrollPosition
    }

    const getIdByScrollPosition = (scrollPosition: number) => {
      const foundCategory = [...Object.values(categoriesScrollPositions.current)]
        .reverse()
        .find((category) => scrollPosition >= category.scrollPosition)
      if (!foundCategory) {
        return
      }

      return foundCategory.categoryId
    }

    useImperativeHandle(ref, () => ({getScrollPositionById, getIdByScrollPosition}), [])

    return (
      <div>
        {Object.entries(products).map(([categoryId, items]) => (
          <div key={categoryId} ref={(itemRef) => setCategoriesScrollPositions(itemRef, categoryId)}>
            <ListHeaderContainer>
              <ListHeader>{categoriesObj[categoryId]?.title}</ListHeader>
            </ListHeaderContainer>

            <ProductsContainer>
              {items.map((item, index) => (
                <ProductsListItem
                  key={index}
                  cartItem={item.cartItem}
                  product={item.product}
                  onItemClick={onItemClick}
                  onAdd={onAdd}
                  onDecrease={onDecrease}
                  onIncrease={onIncrease}
                />
              ))}
            </ProductsContainer>
          </div>
        ))}
      </div>
    )
  },
)

export default DesktopProductsList
