import {memo, MouseEventHandler, ReactNode} from 'react'
import styled from 'styled-components'

import Text from 'src/components/Text'

interface OptionButtonProps {
  title: string
  LeftIcon: ReactNode
  RightIcon?: ReactNode
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Button = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 4.25rem;
  border-radius: 2.125rem;
  background-color: ${({theme}) => theme.colors.firstItemColor};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.152453);
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`

const RightContainer = styled.div`
  padding-right: 1.75rem;
`

const LeftIconContainer = styled.div`
  width: 5.375rem;
  display: flex;
  justify-content: center;
`

const OptionButton = (props: OptionButtonProps) => {
  return (
    <Button disabled={props.disabled} onClick={props.onClick}>
      <LeftContainer>
        <LeftIconContainer>{props.LeftIcon}</LeftIconContainer>

        <Text type="payButton" color="primaryTextColor">
          {props.title}
        </Text>
      </LeftContainer>

      {!!props.RightIcon && <RightContainer>{props.RightIcon}</RightContainer>}
    </Button>
  )
}

export default memo(OptionButton)
