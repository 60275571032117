import styled, {AnyStyledComponent} from 'styled-components'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import Category from './components/Category'
import {useElScrollTimeout, useMediaQuery, useValueByScroll} from 'src/utilities/hooks'
import {ProductCategoryContract} from 'src/types/api'
import IconButton from 'src/components/IconButton'
import {sizes} from 'src/utilities/theme'

export const MASTER_CATEGORIES_DESKTOP_NORMAL_HEIGHT = 260 as const
export const MASTER_CATEGORIES_DESKTOP_SMALL_HEIGHT = 160 as const
export const MASTER_CATEGORIES_DESKTOP_HEIGHT_DIFFERENCE =
  MASTER_CATEGORIES_DESKTOP_NORMAL_HEIGHT - MASTER_CATEGORIES_DESKTOP_SMALL_HEIGHT
export const MASTER_CATEGORIES_DESKTOP_LIST_GAP = 60 as const

export const MASTER_CATEGORIES_MOBILE_NORMAL_HEIGHT = 128 as const
export const MASTER_CATEGORIES_MOBILE_SMALL_HEIGHT = 76 as const
export const MASTER_CATEGORIES_MOBILE_HEIGHT_DIFFERENCE =
  MASTER_CATEGORIES_MOBILE_NORMAL_HEIGHT - MASTER_CATEGORIES_MOBILE_SMALL_HEIGHT
export const MASTER_CATEGORIES_MOBILE_LIST_GAP = 10 as const

const TRANSITION_TIME = 100 as const

const getContentWidth = () => {
  const windowWidth = document.documentElement.clientWidth
  const maxContentWidth = sizes.contentContainerWidth
  const listPaddingX = 12 * 2

  if (windowWidth >= maxContentWidth) {
    return maxContentWidth - listPaddingX
  }

  return windowWidth - listPaddingX
}

const getListPaddingX = () => {
  return (document.documentElement.clientWidth - getContentWidth()) / 2
}

enum RefNames {
  categoriesContainerRef = 'categoriesContainerRef',
}

interface MasterCategoriesProps {
  categories?: ProductCategoryContract[]
  activeMasterCategoryId?: string
  offsetTop?: number
  onCategoryClick?: (id: string) => void
}

interface ScrollPosition {
  id: string
  position: number
}

interface CategoriesContainerProps {
  paddingTop?: number
  length?: number
}

interface CategoryStyledProps {
  width?: number
}

interface ArrowContainerProps {
  align?: 'left' | 'right'
  paddingTop?: number
}

const MainContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const CategoriesContainer = styled.div<CategoriesContainerProps>`
  height: ${MASTER_CATEGORIES_DESKTOP_NORMAL_HEIGHT}px;
  display: grid;
  grid-template-columns: repeat(${({length = 0}) => length}, 1fr);
  column-gap: ${MASTER_CATEGORIES_DESKTOP_LIST_GAP}px;
  padding: ${({paddingTop}) => {
    const paddingX = getListPaddingX()

    return `calc(1.5rem + ${paddingTop}px) ${paddingX}px 1rem ${paddingX}px`
  }};
  box-sizing: border-box;
  transition: all ${TRANSITION_TIME}ms;
  overflow-x: auto;
  position: relative;

  @media ${({theme}) => theme.queries.mobile} {
    height: ${MASTER_CATEGORIES_MOBILE_NORMAL_HEIGHT}px;
    column-gap: ${MASTER_CATEGORIES_MOBILE_LIST_GAP}px;
    padding: ${({paddingTop}) => {
      const paddingX = getListPaddingX()

      return `calc(0.75rem + ${paddingTop}px) ${paddingX}px 0.75rem ${paddingX}px`
    }};
  }

  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const CategoryStyled = styled(Category as AnyStyledComponent)<CategoryStyledProps>`
  width: ${({width = 0}) => width}px;
`

const ArrowContainer = styled.div<ArrowContainerProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  transition: all ${TRANSITION_TIME}ms;
  left: ${({align}) => (align === 'left' ? `calc(${getListPaddingX()}px - 0.75rem)` : 'auto')};
  right: ${({align}) => (align === 'right' ? `calc(${getListPaddingX()}px - 0.75rem)` : 'auto')};
  z-index: 1;
  padding: calc(1.5rem + ${({paddingTop}) => paddingTop}px) 0 1rem 0;

  @media ${({theme}) => theme.queries.mobile} {
    padding: calc(0.75rem + ${({paddingTop}) => paddingTop}px) 0 0.75rem 0;
  }
`

const IconButtonStyled = styled(IconButton as unknown as AnyStyledComponent).attrs({
  variant: 'yellow',
})``

const MasterCategories = ({
  categories,
  activeMasterCategoryId,
  offsetTop = 0,
  onCategoryClick,
}: MasterCategoriesProps) => {
  const [categoryWidth, setCategoryWidth] = useState<number>(0)
  const [showScrollLeftButton, setShowScrollLeftButton] = useState<boolean>(false)
  const [showScrollRightButton, setShowScrollRightButton] = useState<boolean>(false)

  const scrollPositions = useRef<{[id: string]: ScrollPosition}>({})
  const refs = useRef<{[key in RefNames]?: HTMLDivElement}>({})

  const {isMobile} = useMediaQuery()

  const heightDifference = useMemo(
    () => (isMobile ? MASTER_CATEGORIES_MOBILE_HEIGHT_DIFFERENCE : MASTER_CATEGORIES_DESKTOP_HEIGHT_DIFFERENCE),
    [isMobile],
  )

  const listGap = useMemo(
    () => (isMobile ? MASTER_CATEGORIES_MOBILE_LIST_GAP : MASTER_CATEGORIES_DESKTOP_LIST_GAP),
    [isMobile],
  )

  const paddingTop = useValueByScroll(heightDifference, heightDifference, offsetTop)

  const getSortedScrollPositions = (reverse?: boolean) => {
    const sortedScrollPositions = [...Object.values(scrollPositions.current)].sort((a, b) => {
      if (reverse) {
        return b.position - a.position
      }

      return a.position - b.position
    })

    return sortedScrollPositions
  }

  const scrollTo = (position: number) => {
    const categoriesContainerRef = refs.current[RefNames.categoriesContainerRef]
    if (!categoriesContainerRef) {
      return
    }

    categoriesContainerRef.scroll({left: position, behavior: 'smooth'})
  }

  const scrollRight = () => {
    const categoriesContainerRef = refs.current[RefNames.categoriesContainerRef]
    if (!categoriesContainerRef) {
      return
    }

    const foundScrollPosition = getSortedScrollPositions().find(
      (scrollPosition) => scrollPosition.position > categoriesContainerRef.scrollLeft + 1,
    )
    if (!foundScrollPosition) {
      return
    }

    scrollTo(foundScrollPosition.position)
  }

  const scrollLeft = () => {
    const categoriesContainerRef = refs.current[RefNames.categoriesContainerRef]
    if (!categoriesContainerRef) {
      return
    }

    const foundScrollPosition = getSortedScrollPositions(true).find(
      (scrollPosition) => scrollPosition.position < categoriesContainerRef.scrollLeft - 1,
    )
    if (!foundScrollPosition) {
      return
    }

    scrollTo(foundScrollPosition.position)
  }

  const calculateCategoryWidth = () => {
    if (!categories?.length) {
      return
    }

    const contentWidth = getContentWidth()

    if (categories.length === 1) {
      setCategoryWidth(contentWidth)
      return
    }

    if (categories.length === 2) {
      setCategoryWidth((contentWidth - listGap) / 2)
      return
    }

    if (categories.length === 3) {
      setCategoryWidth((contentWidth - listGap * 2) / 3)
      return
    }

    setCategoryWidth((contentWidth - listGap * 2) / 3 - (isMobile ? 10 : 0))
  }

  const calculateScrollPositions = useCallback(() => {
    if (!categories?.length || categories.length < 4) {
      return
    }

    categories.forEach((category, index) => {
      const id = category.id!
      const position = (categoryWidth + listGap) * index

      scrollPositions.current[id] = {id, position}
    })
  }, [categories, categoryWidth, listGap])

  const setRefs = (name: RefNames, itemRef?: HTMLDivElement | null) => {
    if (!itemRef) {
      return
    }

    refs.current[name] = itemRef

    switch (name) {
      case RefNames.categoriesContainerRef:
        checkScrollButtons(itemRef)
        calculateCategoryWidth()
        break
      default:
        break
    }
  }

  const scrollToCategory = (categoryId: string) => {
    const scrollPosition = scrollPositions.current[categoryId]
    if (!scrollPosition) {
      return
    }

    scrollTo(scrollPosition.position)
  }

  const checkScrollButtons = (itemRef?: HTMLDivElement) => {
    if (isMobile || !itemRef || !categories?.length || categories.length < 4) {
      return
    }

    setShowScrollLeftButton(itemRef.scrollLeft > 0)

    setShowScrollRightButton(itemRef.scrollLeft + itemRef.clientWidth + 1 < itemRef.scrollWidth)
  }

  const handleCategoryClick = (categoryId: string) => {
    onCategoryClick?.(categoryId)

    scrollToCategory(categoryId)
  }

  useElScrollTimeout(refs.current[RefNames.categoriesContainerRef], () => {
    checkScrollButtons(refs.current[RefNames.categoriesContainerRef])
  })

  useEffect(() => {
    calculateScrollPositions()
  }, [calculateScrollPositions])

  return (
    <MainContainer>
      {showScrollLeftButton && (
        <ArrowContainer align="left" paddingTop={paddingTop}>
          <IconButtonStyled name="chevron-left" onClick={scrollLeft} />
        </ArrowContainer>
      )}

      <CategoriesContainer
        ref={(itemRef) => setRefs(RefNames.categoriesContainerRef, itemRef)}
        paddingTop={paddingTop}
        length={categories?.length}
      >
        {categories?.map((category) => (
          <CategoryStyled
            key={category.id}
            width={categoryWidth}
            transitionTime={TRANSITION_TIME}
            category={category}
            selected={category.id === activeMasterCategoryId}
            offsetTop={offsetTop}
            countTo={heightDifference}
            onCategoryClick={handleCategoryClick}
          />
        ))}
      </CategoriesContainer>

      {showScrollRightButton && (
        <ArrowContainer align="right" paddingTop={paddingTop}>
          <IconButtonStyled name="chevron-right" onClick={scrollRight} />
        </ArrowContainer>
      )}
    </MainContainer>
  )
}

export default MasterCategories
