import styled, {AnyStyledComponent} from 'styled-components'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import DynamicPageLayout from 'src/components/DynamicPageLayout'
import Text from 'src/components/Text'
import Button from 'src/pages/DynamicPage/components/Button'
import {useTabletId} from 'src/utilities/hooks'

interface LocationState {
  description: string
}

const MainContainer = styled.div`
  height: calc(100vh - 22rem);
  display: grid;
  place-items: center;

  @media ${({theme}) => theme.queries.mobile} {
    height: calc(100vh - 17rem);
  }
`

const ContentContainer = styled.div`
  max-width: 26.3125rem;
  width: 100%;
  display: grid;
  row-gap: 2rem;
  justify-items: center;

  @media ${({theme}) => theme.queries.mobile} {
    max-width: none;
    row-gap: 1.5rem;
  }
`

const ButtonContainer = styled.div`
  max-width: 19.9375rem;
  width: 100%;

  @media ${({theme}) => theme.queries.mobile} {
    max-width: none;
  }
`

const Description = styled(Text as unknown as AnyStyledComponent)`
  white-space: pre-wrap;
`

const DynamicPageSuccess = () => {
  const location = useLocation()
  const locationState = location.state as LocationState | null
  const navigate = useNavigate()

  const {t} = useTranslation()

  const tabletId = useTabletId()

  const navigateToMain = () => {
    if (!tabletId) {
      return
    }

    navigate(`/${tabletId}`)
  }

  return (
    <DynamicPageLayout>
      <MainContainer>
        <ContentContainer>
          <Text type="mainTitleDesktop" color="tertiaryTextColor" align="center" fontFamily="secondary">
            {t('pages.dynamicPageSuccess.title')}
          </Text>

          <Description type="descriptionTextDesktop" color="baseItemTextColor" align="center">
            {locationState?.description}
          </Description>

          <ButtonContainer>
            <Button buttonType="primary-regular" onClick={navigateToMain}>
              {t('pages.dynamicPageSuccess.buttonText')}
            </Button>
          </ButtonContainer>
        </ContentContainer>
      </MainContainer>
    </DynamicPageLayout>
  )
}

export default DynamicPageSuccess
