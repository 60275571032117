import styled from 'styled-components'
import {ChangeEventHandler, FormEventHandler, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import NewModal from 'src/components/NewModal'
import {useMediaQuery} from 'src/utilities/hooks'
import ActionButton from './components/ActionButton'
import Text from 'src/components/Text'
import PhoneNumberInput from './components/PhoneNumberInput'
import {RootState} from 'src/utilities/store'
import {selectCallingCode} from 'src/models/profile'

interface PhoneNumberModalProps {
  isOpen?: boolean
  required?: boolean
  onSkip?: () => void
  onConfirm?: (phoneNumber: string) => void
  onClose?: () => void
}

interface FooterContainerProps {
  required?: boolean
}

const ContentContainer = styled.div`
  padding: 3.25rem 3.25rem 1.5rem 3.25rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 3.25rem 1rem 1.5rem 1rem;
  }
`

const DescriptionContainer = styled.div`
  padding: 1rem 0 1.5rem 0;
`

const FooterContainer = styled.div<FooterContainerProps>`
  box-shadow: 0px -2px 14px 0px rgba(0, 0, 0, 0.07);
  padding: 0.75rem 3.25rem;
  display: grid;
  grid-template-columns: ${({required}) => (required ? '10.75rem' : 'repeat(2, 1fr)')};
  justify-content: center;
  column-gap: 2rem;
  background-color: ${({theme}) => theme.colors.baseItemColor};

  @media ${({theme}) => theme.queries.mobile} {
    padding: 0.75rem 1rem;
  }
`

const PhoneNumberModal = ({isOpen, required, onClose, onConfirm, onSkip}: PhoneNumberModalProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [selectedCallingCodeId, setSelectedCallingCodeId] = useState<string>('')

  const user = useSelector((state: RootState) => state.profile.user)

  const callingCode = useSelector((state: RootState) => selectCallingCode(state, selectedCallingCodeId))

  const {isMobile} = useMediaQuery()

  const {t} = useTranslation()

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    if (!callingCode) {
      return
    }

    onConfirm?.(`${callingCode.code}${phoneNumber}`)
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPhoneNumber(e.target.value)
  }

  useEffect(() => {
    if (!isOpen || !user?.callingCodes?.length) {
      return
    }

    const defaultCallingCode = user.callingCodes.find((callingCode) => callingCode.isDefault)

    setSelectedCallingCodeId(defaultCallingCode?.id ?? user.callingCodes[0].id!)
  }, [isOpen, user?.callingCodes])

  return (
    <NewModal isOpen={!!isOpen} width={isMobile ? '100%' : '30rem'} showCloseButton onRequestClose={onClose}>
      <form onSubmit={handleSubmit}>
        <ContentContainer>
          <Text type="errorTitle" align="center" color="baseItemTextColor">
            {t('components.phoneNumberModal.title')}
          </Text>

          <DescriptionContainer>
            <Text type="productOptions" color="baseItemTextColor">
              {t('components.phoneNumberModal.description')}
            </Text>
          </DescriptionContainer>

          <PhoneNumberInput
            info={t('components.phoneNumberModal.input.info')}
            placeholder={t('components.phoneNumberModal.input.placeholder')}
            required={required}
            value={phoneNumber}
            callingCodes={user?.callingCodes!}
            selectedCallingCodeId={selectedCallingCodeId}
            onChange={handleChange}
            onCallingCodeSelect={setSelectedCallingCodeId}
          />
        </ContentContainer>

        <FooterContainer required={required}>
          {!required && (
            <ActionButton type="button" color="secondItemColor" textColor="primaryTextColor" onClick={onSkip}>
              {t('components.phoneNumberModal.skipButtonText')}
            </ActionButton>
          )}

          <ActionButton type="submit">{t('components.phoneNumberModal.confirmButtonText')}</ActionButton>
        </FooterContainer>
      </form>
    </NewModal>
  )
}

export default PhoneNumberModal
