import {createModel} from '@rematch/core'

import {RootModel} from '.'

interface HomeState {
  productsListScrollTopPosition: number
  openRestaurants: boolean
}

const initialState: HomeState = {
  productsListScrollTopPosition: 0,
  openRestaurants: false,
}

export const home = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updateProductsListScrollTopPosition(state, productsListScrollTopPosition: number) {
      state.productsListScrollTopPosition = productsListScrollTopPosition
    },
    setOpenRestaurants(state, openRestaurants: boolean) {
      state.openRestaurants = openRestaurants
    },
  },
})
