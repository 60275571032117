import {PropsWithChildren} from 'react'
import {ThemeProvider as StyledThemeProvider} from 'styled-components'

import {useTheme} from './utilities/theme'

const ThemeProvider = ({children}: PropsWithChildren) => {
  const {theme} = useTheme()

  // @ts-expect-error
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
}

export default ThemeProvider
