import styled, {CSSProperties} from 'styled-components'
import {useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {Trans, useTranslation} from 'react-i18next'
import {useCallback, useLayoutEffect, useMemo, useRef, useState} from 'react'

import {useMediaQuery} from 'src/utilities/hooks'
import {RootState} from 'src/utilities/store'
import FormInput from '../FormInput'
import Button from '../Button'
import Error from '../Error'
import Text from 'src/components/Text'
import {InvoiceOrderContract} from 'src/types/api'
import {priceWithCurrency} from 'src/utilities/functions'
import {DATE_AND_TIME_FORMAT, dayjs} from 'src/utilities/dayjs'
import OrderForm, {OrderFormRef, OrderFormValues} from './components/OrderForm'
import {sizes} from 'src/utilities/theme'
import {HEADER_HEIGHT} from 'src/components/DesktopHeader/components/Header'

const schema: yup.ObjectSchema<InvoiceFormValues> = yup.object({
  email: yup.string().email().required(),
  companyName: yup.string().required(),
  companyCode: yup.string().required(),
  companyVatCode: yup.string(),
  companyAddress: yup.string().required(),
  agreeToPp: yup.boolean().oneOf([true]).required(),
})

const DEFAULT_INVOICE_FORM_VALUES: InvoiceFormValues = {
  email: '',
  companyName: '',
  companyCode: '',
  companyVatCode: '',
  companyAddress: '',
  agreeToPp: false,
}

export interface InvoiceFormData extends InvoiceFormValues, OrderFormValues {}

interface InvoiceFormProps {
  loading?: boolean
  showSuccessMsg?: boolean
  onCheck?: (data: OrderFormValues) => Promise<InvoiceOrderContract | undefined>
  onDownload?: (data: InvoiceFormData) => void
  onSend?: (data: InvoiceFormData) => void
  setShowSuccessMsg?: (value: boolean) => void
}

interface InvoiceFormValues {
  email: string
  companyName: string
  companyCode: string
  companyVatCode?: string
  companyAddress: string
  agreeToPp: boolean
}

interface InputsTitleProps {
  align?: CSSProperties['textAlign']
}

interface InputsTitleContainerProps {
  hasPaddingTop?: boolean
  hasPaddingBottom?: boolean
}

const InputsMainContainer = styled.form`
  display: grid;
  row-gap: 1.25rem;
  max-width: 63.75rem;
  margin: 0 auto;
  width: 100%;

  @media ${({theme}) => theme.queries.mobile} {
    max-width: none;
  }
`

const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;

  @media ${({theme}) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const InputsTitleContainer = styled.div<InputsTitleContainerProps>`
  padding-bottom: ${({hasPaddingBottom}) => (hasPaddingBottom ? 0.9375 : 0)}rem;
  padding-top: ${({hasPaddingTop}) => (hasPaddingTop ? 0.9375 : 0)}rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding-bottom: 0;
  }
`

const InputsTitle = styled.h2<InputsTitleProps>`
  font-family: ${({theme}) => theme.fontFamilies.primary};
  font-size: 2.1875rem;
  font-weight: 900;
  line-height: 2.1875rem;
  color: ${({theme}) => theme.colors.baseItemTextColor};
  text-align: ${({align = 'start'}) => align};
  white-space: pre-wrap;

  @media ${({theme}) => theme.queries.mobile} {
    font-size: 1.25rem;
  }
`

const InfoText = styled(InputsTitle)`
  font-size: 1.5rem;
  font-weight: 400;
`

const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  gap: 1.25rem;

  @media ${({theme}) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 18.75rem);
  gap: 1.25rem;

  @media ${({theme}) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const PPLink = styled.a`
  color: inherit;
  font-weight: 700;
`

const CustomButton = styled(Button)`
  max-width: 18.75rem;
  margin: 0 auto;

  @media ${({theme}) => theme.queries.mobile} {
    max-width: none;
  }
`

const Success = styled(Error).attrs({
  style: {
    color: '#008000',
    display: 'block',
  },
})``

const Bold = styled.span`
  font-weight: 900;
`

const InvoiceForm = ({loading, showSuccessMsg, onCheck, onDownload, onSend, setShowSuccessMsg}: InvoiceFormProps) => {
  const [orderNotFound, setOrderNotFound] = useState<boolean>(false)
  const [order, setOrder] = useState<InvoiceOrderContract | null>(null)

  const websiteData = useSelector((state: RootState) => state.website.websiteData)

  const {isMobile} = useMediaQuery()

  const {t} = useTranslation()

  const orderFormRef = useRef<OrderFormRef>(null)
  const infoTextContainerRef = useRef<HTMLDivElement>(null)
  const successContainerRef = useRef<HTMLDivElement>(null)

  const headerHeight = useMemo(() => (isMobile ? sizes.headerHeight : HEADER_HEIGHT), [isMobile])

  const {
    handleSubmit,
    clearErrors,
    control,
    formState: {errors},
  } = useForm<InvoiceFormValues>({
    defaultValues: DEFAULT_INVOICE_FORM_VALUES,
    resolver: yupResolver(schema),
  })

  const resetShowSuccessMsg = () => {
    setShowSuccessMsg?.(false)
  }

  const resetState = () => {
    setOrderNotFound(false)
    setOrder(null)
    clearErrors()
    resetShowSuccessMsg()
  }

  const scrollTo = useCallback(
    (top: number) => {
      window.scroll({behavior: 'smooth', top: top - headerHeight})
    },
    [headerHeight],
  )

  const scrollToInfoText = useCallback(() => {
    if (!infoTextContainerRef.current) {
      return
    }

    scrollTo(infoTextContainerRef.current.offsetTop)
  }, [scrollTo])

  const scrollToSuccess = useCallback(() => {
    if (!successContainerRef.current) {
      return
    }

    scrollTo(successContainerRef.current.offsetTop)
  }, [scrollTo])

  const handleCheck = () => {
    resetShowSuccessMsg()

    orderFormRef.current?.submit(async (data) => {
      resetState()

      const invoiceOrder = await onCheck?.(data)
      if (!invoiceOrder) {
        setOrderNotFound(true)
        return
      }

      setOrder(invoiceOrder)
    })()
  }

  const handleDownload = handleSubmit((data) => {
    resetShowSuccessMsg()

    const orderFormData = orderFormRef.current?.getValues()
    if (!orderFormData) {
      return
    }

    onDownload?.({...data, ...orderFormData})
  })

  const handleSend = handleSubmit((data) => {
    resetShowSuccessMsg()

    const orderFormData = orderFormRef.current?.getValues()
    if (!orderFormData) {
      return
    }

    onSend?.({...data, ...orderFormData})
  })

  useLayoutEffect(() => {
    if (orderNotFound || order) {
      scrollToInfoText()
    }
  }, [order, orderNotFound, scrollToInfoText])

  useLayoutEffect(() => {
    if (showSuccessMsg) {
      scrollToSuccess()
    }
  }, [scrollToSuccess, showSuccessMsg])

  return (
    <InputsMainContainer>
      <InputsTitleContainer hasPaddingBottom>
        <InputsTitle>{t('pages.dynamicPage.components.invoiceForm.orderTitle')}</InputsTitle>
      </InputsTitleContainer>

      <OrderForm ref={orderFormRef} loading={loading} onValueChange={resetState} />

      <CustomButton disabled={loading} onClick={handleCheck}>
        {t('pages.dynamicPage.components.invoiceForm.checkButtonLabel')}
      </CustomButton>

      {(orderNotFound || !!order) && (
        <InputsTitleContainer ref={infoTextContainerRef} hasPaddingTop>
          <InfoText align="center">
            {order ? (
              <Trans
                i18nKey="pages.dynamicPage.components.invoiceForm.orderFound"
                values={{
                  order: `${order.name}, ${order.restaurant}, ${dayjs(order.startTimeStamp).format(
                    DATE_AND_TIME_FORMAT,
                  )}, ${priceWithCurrency(order.totalPrice)}`,
                }}
                components={{
                  b: <Bold />,
                }}
              />
            ) : (
              <Bold>{t('pages.dynamicPage.components.invoiceForm.orderNotFound')}</Bold>
            )}
          </InfoText>
        </InputsTitleContainer>
      )}

      {!!order && (
        <>
          <InputsTitleContainer hasPaddingTop hasPaddingBottom>
            <InputsTitle>{t('pages.dynamicPage.components.invoiceForm.contactsTitle')}</InputsTitle>
          </InputsTitleContainer>

          <InputsContainer>
            <FormInput
              type="email"
              name="email"
              placeholder={t('pages.dynamicPage.components.invoiceForm.emailPlaceholder')}
              control={control}
              disabled={loading}
              onValueChange={resetShowSuccessMsg}
            />
          </InputsContainer>

          <InputsContainer>
            <FormInput
              type="text"
              name="companyName"
              placeholder={t('pages.dynamicPage.components.invoiceForm.companyName')}
              control={control}
              disabled={loading}
              onValueChange={resetShowSuccessMsg}
            />

            <FormInput
              type="text"
              name="companyCode"
              placeholder={t('pages.dynamicPage.components.invoiceForm.companyCode')}
              control={control}
              disabled={loading}
              onlyIntegers
              onValueChange={resetShowSuccessMsg}
            />
          </InputsContainer>

          <InputsContainer>
            <FormInput
              type="text"
              name="companyVatCode"
              placeholder={t('pages.dynamicPage.components.invoiceForm.companyVatCode')}
              control={control}
              disabled={loading}
              onValueChange={resetShowSuccessMsg}
            />

            <FormInput
              type="text"
              name="companyAddress"
              placeholder={t('pages.dynamicPage.components.invoiceForm.companyAddress')}
              control={control}
              disabled={loading}
              onValueChange={resetShowSuccessMsg}
            />
          </InputsContainer>

          <FooterContainer>
            <FormInput
              type="checkbox"
              name="agreeToPp"
              control={control}
              disabled={loading}
              checkboxLabel={
                <Text type={isMobile ? 'descriptionTextMobile' : 'buttonText'} color="baseItemTextColor">
                  {t('pages.dynamicPage.components.invoiceForm.agreeToPpLabel')}{' '}
                  <PPLink
                    href={websiteData?.privacyPolicyUrl ?? process.env.VITE_APP_PP_URL}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {t('pages.dynamicPage.components.invoiceForm.agreeToPpText')}
                  </PPLink>
                </Text>
              }
              onValueChange={resetShowSuccessMsg}
            />

            <ButtonsContainer>
              <Button buttonType="secondary" disabled={loading} onClick={() => handleDownload()}>
                {t('pages.dynamicPage.components.invoiceForm.downloadButtonLabel')}
              </Button>

              <Button disabled={loading} onClick={() => handleSend()}>
                {t('pages.dynamicPage.components.invoiceForm.sendButtonLabel')}
              </Button>
            </ButtonsContainer>
          </FooterContainer>
        </>
      )}

      {!!Object.keys(errors).length && <Error msg={t('pages.dynamicPage.components.invoiceForm.errorMsg')} />}

      {!!showSuccessMsg && (
        <div ref={successContainerRef}>
          <Success msg={t('pages.dynamicPage.components.invoiceForm.successMsg')} />
        </div>
      )}
    </InputsMainContainer>
  )
}

export default InvoiceForm
