import styled from 'styled-components'
import {PropsWithChildren, useCallback, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import {useMediaQuery, useTabletId} from 'src/utilities/hooks'
import HeaderContent from 'src/components/HeaderContent'
import {Dispatch, RootState} from 'src/utilities/store'
import Header from 'src/components/DesktopHeader/components/Header'
import {mediaUrl} from 'src/utilities/functions'
import ContentContainer from 'src/components/ContentContainer'
import Sidebar from 'src/components/Sidebar'
import Footer from 'src/components/Footer'
import {selectCart, selectCartTotalPrice} from 'src/models/cart'
import CartImg from 'src/assets/images/cart.png'
import {selectAcceptsAllOrderTypes} from 'src/models/profile'
import {OrderType} from 'src/types/api'
import {selectHasCustomPages} from 'src/models/website'
import Text from 'src/components/Text'

interface DynamicPageLayoutProps extends PropsWithChildren {
  headerText?: string
  subHeaderText?: string
}

interface LocationState {
  backgroundLocation: Location
}

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`

const MainContainer = styled.div`
  padding: 5.25rem 0 calc(5.25rem + 7rem) 0;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 3.3125rem 0 calc(3.3125rem + 7rem) 0;
  }
`

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`

const CartImgStyled = styled.img`
  width: 1.625rem;
  height: 1.625rem;
`

const HeadingContainer = styled.div`
  display: grid;
  row-gap: 2.5rem;
  justify-items: center;

  @media ${({theme}) => theme.queries.mobile} {
    row-gap: 1.25rem;
  }
`

const SubHeaderContainer = styled.div`
  width: 100%;
  max-width: 44.5rem;

  @media ${({theme}) => theme.queries.mobile} {
    max-width: none;
  }
`

const DynamicPageLayout = ({children, headerText, subHeaderText}: DynamicPageLayoutProps) => {
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false)

  const {backgroundLocation, sessionId} = useSelector((state: RootState) => state.app)
  const user = useSelector((state: RootState) => state.profile.user)

  const cartTotalPrice = useSelector(selectCartTotalPrice)
  const cart = useSelector(selectCart)
  const acceptsAllOrderTypes = useSelector(selectAcceptsAllOrderTypes)
  const hasCustomPages = useSelector(selectHasCustomPages)

  const dispatch = useDispatch<Dispatch>()

  const {isMobile} = useMediaQuery()

  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as LocationState | null

  const tabletId = useTabletId()

  const toggleSidebar = () => {
    setSidebarVisible((prevSidebarVisible) => !prevSidebarVisible)
  }

  const navigateToCheckout = useCallback(() => {
    if (!tabletId) {
      return
    }

    if (isMobile) {
      navigate(`/${tabletId}/checkout`)
      return
    }

    navigate(`/${tabletId}/checkout`, {
      state: {backgroundLocation: locationState?.backgroundLocation ?? backgroundLocation},
    })
  }, [backgroundLocation, isMobile, locationState?.backgroundLocation, navigate, tabletId])

  const navigateToSearch = useCallback(() => {
    if (!tabletId) {
      return
    }

    navigate(`/${tabletId}/search`, {
      state: {backgroundLocation: locationState?.backgroundLocation ?? backgroundLocation},
    })
  }, [backgroundLocation, locationState?.backgroundLocation, navigate, tabletId])

  const navigateToRestaurants = useCallback(() => {
    if (!tabletId) {
      return
    }

    navigate(`/${tabletId}/restaurants`, {
      state: {backgroundLocation: locationState?.backgroundLocation ?? backgroundLocation},
    })
  }, [backgroundLocation, locationState?.backgroundLocation, navigate, tabletId])

  const handleCartClick = useCallback(() => {
    if (acceptsAllOrderTypes) {
      navigateToCheckout()
      return
    }

    if (user?.isAcceptingTakeAwayOrders) {
      dispatch.cart.setOrderType(OrderType.TakeAway)
    }

    if (user?.isAcceptingTableOrders) {
      dispatch.cart.setOrderType(OrderType.Table)
    }

    navigateToCheckout()
  }, [
    acceptsAllOrderTypes,
    dispatch.cart,
    navigateToCheckout,
    user?.isAcceptingTableOrders,
    user?.isAcceptingTakeAwayOrders,
  ])

  const handleHistoryClick = () => {
    if (!sessionId) {
      return
    }

    navigate(`/orders/${sessionId}`)
  }

  const renderFooter = () => {
    return (
      <Footer
        cartSum={cartTotalPrice}
        cartItemsCount={Object.keys(cart.items).length}
        intercomEnabled={user?.enableSupportChat}
        showOrderHistory={user?.showOrderHistory}
        CartContentComponent={<CartImgStyled src={CartImg} alt="cart" />}
        hideSearch={!user?.isActive}
        onCartClick={handleCartClick}
        onSearchClick={navigateToSearch}
        onHistoryClick={handleHistoryClick}
        onSearchFocus={navigateToSearch}
      />
    )
  }

  const renderHeader = () => {
    if (!headerText && !subHeaderText) {
      return null
    }

    return (
      <HeadingContainer>
        {!!headerText && (
          <Text
            type={isMobile ? 'mainTitleMobile' : 'mainTitleDesktop'}
            color="tertiaryTextColor"
            align="center"
            fontFamily="secondary"
          >
            {headerText}
          </Text>
        )}

        {!!subHeaderText && (
          <SubHeaderContainer>
            <Text
              type={isMobile ? 'descriptionTextMobile' : 'descriptionTextDesktop'}
              color="baseItemTextColor"
              align="center"
            >
              {subHeaderText}
            </Text>
          </SubHeaderContainer>
        )}
      </HeadingContainer>
    )
  }

  return (
    <>
      <HeaderContainer>
        {isMobile ? (
          <HeaderContent onMenuClick={toggleSidebar} onRestaurantClick={navigateToRestaurants} />
        ) : (
          <Header
            brandLogoUrl={mediaUrl(user?.receiptImage, {w: 0, h: 88})}
            onMenuClick={toggleSidebar}
            onRestaurantClick={navigateToRestaurants}
          />
        )}
      </HeaderContainer>

      <ContentContainer>
        <MainContainer>
          {renderHeader()}

          {children}
        </MainContainer>
      </ContentContainer>

      <FooterContainer>{renderFooter()}</FooterContainer>

      {hasCustomPages && <Sidebar visible={sidebarVisible} FooterComponent={renderFooter()} onClose={toggleSidebar} />}
    </>
  )
}

export default DynamicPageLayout
