import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(customParseFormat)

export {dayjs}

export const DATE_FORMAT = 'YYYY-MM-DD'

export const TIME_FORMAT = 'HH:mm'

export const DATE_AND_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`
