import {useSelector} from 'react-redux'
import {Navigate, Outlet} from 'react-router-dom'
import {validate as validateuuidv4} from 'uuid'

import {selectPage} from 'src/models/website'
import DynamicPage from 'src/pages/DynamicPage'
import {CustomPageType} from 'src/types/api'
import {useTabletId} from 'src/utilities/hooks'
import {RootState} from 'src/utilities/store'
import {getMatchTabletId} from 'src/utilities/functions'

const DynamicRoutes = () => {
  const matchTabletId = getMatchTabletId()

  const user = useSelector((state: RootState) => state.profile.user)
  const page = useSelector((state: RootState) => selectPage(state, matchTabletId))

  const tabletId = useTabletId()

  if (!page) {
    if (matchTabletId && !validateuuidv4(matchTabletId)) {
      return <Navigate to="/" replace />
    }

    return <Outlet />
  }

  if (page.type === CustomPageType.ClosedVenuePlaceholder && user?.isActive && tabletId) {
    return <Navigate to={`/${tabletId}`} replace />
  }

  return <DynamicPage />
}

export default DynamicRoutes
