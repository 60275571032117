import DOMPurify from 'dompurify'
import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'

import {RootState} from 'src/utilities/store'

interface CustomHTMLProps {
  html: string
}

interface ContainerProps {
  dynamicStyles: string
}

const Container = styled.div<ContainerProps>`
  .custom-html {
    ${({dynamicStyles}) => dynamicStyles}

    font-family: ${({theme}) => theme.fontFamilies.primary};

    h2 {
      font-family: ${({theme}) => theme.fontFamilies.secondary};
    }
  }
`

const CustomHTML = ({html}: CustomHTMLProps) => {
  const websiteData = useSelector((state: RootState) => state.website.websiteData)

  const sanitizedHTML = useMemo(() => DOMPurify.sanitize(html), [html])

  return (
    <Container dynamicStyles={websiteData?.generalStyle ?? ''}>
      <div className="custom-html" dangerouslySetInnerHTML={{__html: sanitizedHTML}} />
    </Container>
  )
}

export default CustomHTML
