import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import ErrorModal, {TransErrorModalMsg} from './ErrorModal'
import {RootState} from 'src/utilities/store'
import {selectVenue} from 'src/models/group'
import {QrVenueContract} from 'src/types/api'

interface RestaurantModalProps {
  restaurantId?: string
  onClose?: () => void
  onContinue?: (venue: QrVenueContract) => void
}

const RestaurantModal = ({restaurantId, onClose, onContinue}: RestaurantModalProps) => {
  const venue = useSelector((state: RootState) => selectVenue(state, restaurantId))

  const {t} = useTranslation()

  const handleContinue = () => {
    if (!venue) {
      return
    }

    onContinue?.(venue)
  }

  return (
    <ErrorModal
      title={t('components.restaurantModal.title')}
      ErrorMsgComponent={
        venue && (
          <TransErrorModalMsg
            i18nKey="components.restaurantModal.description"
            values={{restaurant: String(venue.restaurantDisplayTitle)}}
          />
        )
      }
      buttonText={t('components.restaurantModal.buttonText')}
      onBackClick={onClose}
      onRetryClick={handleContinue}
    />
  )
}

export default RestaurantModal
