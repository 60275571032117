import {HTMLAttributes, memo} from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import Text from './Text'
import {RootState} from 'src/utilities/store'
import {selectPriceWithCurrency} from 'src/models/profile'

interface AdditionalCartItemProps extends HTMLAttributes<HTMLDivElement> {
  title?: string
  price?: number
  hasShadow?: boolean
}

interface MainContainerProps {
  hasShadow?: boolean
}

const MainContainer = styled.div<MainContainerProps>`
  background-color: ${({theme}) => theme.colors.baseItemColor};
  padding: 0.75rem;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  ${({hasShadow, theme}) =>
    hasShadow &&
    `
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.043679);
    border-radius: 0.563rem;
    border: 1px solid ${theme.colors.baseItemColor};
  `}
`

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1.25rem;
  align-items: center;
`

const AdditionalCartItem = ({title, price, hasShadow, ...props}: AdditionalCartItemProps) => {
  const priceWithCurrency = useSelector((state: RootState) => selectPriceWithCurrency(state, price))

  return (
    <MainContainer hasShadow={hasShadow} {...props}>
      <ContentContainer>
        <Text type="checkoutPageOptionTitle" color="baseItemTextColor">
          {title}
        </Text>
        <Text type="checkoutPageOptionTitle" color="baseItemTextColor">
          {priceWithCurrency}
        </Text>
      </ContentContainer>
    </MainContainer>
  )
}

export default memo(AdditionalCartItem)
