import styled from 'styled-components'
import {CSSProperties} from 'react'

import {TagContract} from 'src/types/api'

export const ALLERGEN_NORMAL_HEIGHT = 32 as const
export const ALLERGEN_SMALL_HEIGHT = 22 as const

type Variant = 'small' | 'normal'

interface AllergensProps {
  variant?: Variant
  allergens?: TagContract[]
  align?: CSSProperties['justifyContent']
}

interface AllergensContainerProps {
  align: CSSProperties['justifyContent']
}

interface AllergenProps {
  bgColor?: string
  color?: string
  variant?: Variant
}

const AllergensContainer = styled.div<AllergensContainerProps>`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: ${({align}) => align};
`

const Allergen = styled.div<AllergenProps>`
  ${({theme}) => ({...theme.typography.productBoxDescription})};
  background-color: ${({bgColor, theme}) => bgColor ?? theme.colors.backgroundColor};
  color: ${({color, theme}) => color ?? theme.colors.primaryTextColor};
  display: grid;
  place-items: center;
  border-radius: 6.25rem;
  overflow: hidden;
  white-space: pre-wrap;

  ${({variant}) => {
    switch (variant) {
      case 'small':
        return `
          height: ${ALLERGEN_SMALL_HEIGHT}px;
          padding: 0 0.625rem;
          font-size: 0.625rem;
        `
      default:
        return `
          height: ${ALLERGEN_NORMAL_HEIGHT}px;
          padding: 0 0.75rem;
        `
    }
  }}
`

const Allergens = ({allergens = [], align = 'start', variant = 'normal'}: AllergensProps) => {
  return (
    <AllergensContainer align={align}>
      {allergens.map((allergen) => (
        <Allergen key={allergen.id} bgColor={allergen.backgroundColor!} color={allergen.textColor!} variant={variant}>
          {allergen.title}
        </Allergen>
      ))}
    </AllergensContainer>
  )
}

export default Allergens
