import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {ChangeEventHandler} from 'react'
import {useSelector} from 'react-redux'

import ContentContainer from 'src/components/ContentContainer'
import BrandLogo from './components/BrandLogo'
import Language from 'src/components/Language'
import IntercomButton from 'src/components/IntercomButton'
import SearchInput from './components/SearchInput'
import CartButton from 'src/components/CartButton'
import Cart from './components/Cart'
import IconButton from 'src/components/IconButton'
import TestGroupHostContainer from 'src/components/TestGroupHostContainer'
import {IS_STAGING} from 'src/utilities/constants'
import {RootState} from 'src/utilities/store'

export const DESKTOP_FOOTER_HEIGHT = 70 as const

interface DesktopFooterProps {
  autoFocus?: boolean
  cartSum?: number
  showZeroCartSum?: boolean
  cartItemsCount?: number
  intercomEnabled?: boolean
  searchValue?: string
  showOrderHistory?: boolean
  hideSearch?: boolean
  onSearchValueChange?: (searchValue: string) => void
  onSearchFocus?: () => void
  onCartClick?: () => void
  onHistoryClick?: () => void
}

const MainContainer = styled.div`
  position: relative;
  height: ${DESKTOP_FOOTER_HEIGHT}px;
  filter: drop-shadow(0px -2px 14px rgba(0, 0, 0, 0.07));
  background-color: ${({theme}) => theme.colors.baseItemColor};
`

const ContentContainerStyled = styled(ContentContainer)`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-content: center;
`

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.625rem;
`

const CartButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: ${({theme}) => theme.colors.baseItemColor};
  border-top-left-radius: 50%;
`

const HistoryButtonContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-right: 0.75rem;
`

const DesktopFooter = ({
  autoFocus,
  cartSum,
  showZeroCartSum,
  cartItemsCount,
  intercomEnabled,
  searchValue,
  showOrderHistory,
  hideSearch,
  onSearchValueChange,
  onSearchFocus,
  onCartClick,
  onHistoryClick,
}: DesktopFooterProps) => {
  const websiteData = useSelector((state: RootState) => state.website.websiteData)

  const {t} = useTranslation()

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onSearchValueChange?.(e.target.value)
  }

  return (
    <MainContainer>
      <ContentContainerStyled>
        <ActionsContainer>
          <Language />

          {!!intercomEnabled && <IntercomButton />}

          {!hideSearch && (
            <SearchInput
              autoFocus={autoFocus}
              value={searchValue}
              onChange={handleChange}
              onFocus={onSearchFocus}
              placeholder={t('pages.search.placeholder')}
            />
          )}
        </ActionsContainer>

        {websiteData?.showBrandOnlyInSidebar ? null : IS_STAGING ? (
          <TestGroupHostContainer>
            <BrandLogo />
          </TestGroupHostContainer>
        ) : (
          <BrandLogo />
        )}
      </ContentContainerStyled>

      {cartSum || showZeroCartSum ? (
        <CartButtonContainer>
          <CartButton
            totalPrice={cartSum}
            ContentComponent={<Cart count={cartItemsCount} />}
            onCartClick={onCartClick}
          />
        </CartButtonContainer>
      ) : onHistoryClick && showOrderHistory ? (
        <HistoryButtonContainer>
          <IconButton name="clipboard" variant="gray" hideShadow onClick={onHistoryClick} />
        </HistoryButtonContainer>
      ) : null}
    </MainContainer>
  )
}

export default DesktopFooter
