import {MouseEventHandler, ReactNode} from 'react'
import styled from 'styled-components'

const SIZE = '2.625rem'

interface TipsButtonProps {
  children?: ReactNode
  disabled?: boolean
  selected?: boolean
  error?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Button = styled.button.attrs(({theme}) => ({
  style: {
    ...theme.typography.ratingLabel,
  },
}))<TipsButtonProps>`
  width: ${SIZE};
  height: ${SIZE};
  padding: 0;
  border: none;
  border-radius: 50%;
  border: 2px solid ${({theme, error}) => (error ? theme.colors.errorTextColor : theme.colors.firstItemColor)};
  background-color: ${({theme, selected}) => (selected ? theme.colors.firstItemColor : theme.colors.secondItemColor)};
  color: ${({theme}) => theme.colors.primaryTextColor};
  cursor: ${({disabled}) => (disabled ? 'auto' : 'pointer')};
`

const TipsButton = (props: TipsButtonProps) => {
  return (
    <Button
      type="button"
      selected={props.selected}
      disabled={props.disabled}
      error={props.error}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  )
}

export default TipsButton
