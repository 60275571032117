import {createModel} from '@rematch/core'
import {AxiosResponse} from 'axios'
import {createSelector} from 'reselect'

import {RootModel} from '.'
import api from 'src/utilities/api'
import {QrGroupContract, QrVenueContract} from 'src/types/api'
import {RootState} from 'src/utilities/store'
import {getGroupHost} from 'src/utilities/functions'

interface GroupState {
  group: QrGroupContract | null
}

const initialState: GroupState = {
  group: null,
}

export const group = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setGroup(state, group: QrGroupContract | null) {
      state.group = group
    },
  },
  effects: (dispatch) => ({
    async fetchGroup() {
      const {data}: AxiosResponse<QrGroupContract> = await api.get('/qr/group', {
        headers: {
          GroupHost: getGroupHost(),
        },
      })

      dispatch.group.setGroup(data)

      return data
    },
    async fetchGroupLanguage() {
      const response: AxiosResponse<QrGroupContract> = await api.get('/qr/group', {
        headers: {
          GroupHost: getGroupHost(),
        },
      })

      return response.data.languageCode
    },
  }),
})

export const selectVenuesObj = createSelector(
  (rootState: RootState) => rootState.group,
  () => {},
  (state) =>
    (state.group?.venues ?? []).reduce<{[tabletId: string]: QrVenueContract}>((obj, venue) => {
      obj[venue.tabletId!] = venue

      return obj
    }, {}),
)

export const selectVenue = createSelector(
  (rootState: RootState) => rootState.group,
  selectVenuesObj,
  (_: RootState, tabletId?: string) => tabletId,
  (_, venuesObj, tabletId) => {
    if (!tabletId) {
      return
    }

    return venuesObj[tabletId]
  },
)
