import {useCallback} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import AddInfoCard from 'src/components/AddInfoCard'
import {Dispatch} from 'src/utilities/store'
import {selectCart} from 'src/models/cart'
import {useMediaQuery} from 'src/utilities/hooks'
import NewModal from 'src/components/NewModal'

const Comment = () => {
  const cart = useSelector(selectCart)

  const dispatch = useDispatch<Dispatch>()

  const navigate = useNavigate()

  const {t} = useTranslation()

  const {isMobile} = useMediaQuery()

  const navigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleDoneClick = useCallback(
    (value: string) => {
      dispatch.cart.updateComment(value)

      navigateBack()
    },
    [dispatch.cart, navigateBack],
  )

  return (
    <NewModal isOpen={true} width={isMobile ? '100%' : '30rem'}>
      <AddInfoCard
        value={cart.comment}
        title={t('pages.comment.title')}
        placeholder={t('pages.comment.placeholder')}
        autoFocus
        onBackClick={navigateBack}
        onDoneClick={handleDoneClick}
      />
    </NewModal>
  )
}

export default Comment
