import styled from 'styled-components'
import {useEffect, useState} from 'react'

import Carousel from 'src/components/Carousel'
import Header, {HEADER_HEIGHT} from './components/Header'

export const BG_IMG_HEIGHT = 400 as const

interface DesktopHeaderProps {
  imgsUrl?: string[]
  brandLogoUrl?: string
  onRestaurantClick?: () => void
  onMenuClick?: () => void
}

interface BgImgProps {
  imgUrl?: string
}

const BgImg = styled.div<BgImgProps>`
  background-image: url(${({imgUrl}) => imgUrl});
  background-size: cover;
  background-position: center;
  height: ${BG_IMG_HEIGHT}px;
`

const CarouselContainer = styled.div`
  height: ${BG_IMG_HEIGHT}px;
  margin-top: -${HEADER_HEIGHT}px;
`

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`

const DesktopHeader = ({imgsUrl = [], brandLogoUrl, onRestaurantClick, onMenuClick}: DesktopHeaderProps) => {
  const [bgImgVisible, setBgImgVisible] = useState<boolean>(true)

  const handleScroll = () => {
    if (window.scrollY + HEADER_HEIGHT < BG_IMG_HEIGHT) {
      setBgImgVisible(true)
      return
    }

    setBgImgVisible(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <HeaderContainer>
        <Header
          bgImgVisible={bgImgVisible}
          brandLogoUrl={brandLogoUrl}
          onMenuClick={onMenuClick}
          onRestaurantClick={onRestaurantClick}
        />
      </HeaderContainer>

      <CarouselContainer>
        <Carousel showIndicators={imgsUrl.length > 1}>
          {imgsUrl.map((imgUrl, index) => (
            <BgImg key={index} imgUrl={imgUrl} />
          ))}
        </Carousel>
      </CarouselContainer>
    </>
  )
}

export default DesktopHeader
