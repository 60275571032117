import {Map, Marker, MapProps, MarkerProps, MapCameraProps, MapCameraChangedEvent} from '@vis.gl/react-google-maps'
import {useEffect, useState} from 'react'

const INITIAL_POSITION: MapCameraProps = {
  center: {
    lat: 54.6872,
    lng: 25.2797,
  },
  zoom: 15,
}

interface GoogleMapProps extends MapProps {
  activePosition?: MapCameraProps
  positions?: MarkerProps['position'][]
}

const GoogleMap = ({positions = [], activePosition, ...props}: GoogleMapProps) => {
  const [position, setPosition] = useState<MapCameraProps>(activePosition ?? INITIAL_POSITION)

  const handleCameraChange = (e: MapCameraChangedEvent) => {
    setPosition(e.detail)
  }

  useEffect(() => {
    if (!activePosition) {
      return
    }

    setPosition(activePosition)
  }, [activePosition])

  return (
    <Map {...position} disableDefaultUI={true} onCameraChanged={handleCameraChange} {...props}>
      {positions.map((position, index) => (
        <Marker key={index} position={position} />
      ))}
    </Map>
  )
}

export default GoogleMap
