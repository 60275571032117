import {CSSProperties} from 'react'

import {useMediaQuery} from 'src/utilities/hooks'
import Text from 'src/components/Text'

interface ErrorProps {
  msg?: string
  style?: CSSProperties
}

const Error = ({msg, style}: ErrorProps) => {
  const {isMobile} = useMediaQuery()

  return (
    <Text
      type={isMobile ? 'descriptionTextMobile' : 'buttonText'}
      align={isMobile ? 'center' : 'end'}
      color="errorTextColor"
      style={style}
    >
      {msg}
    </Text>
  )
}

export default Error
