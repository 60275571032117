import styled, {AnyStyledComponent} from 'styled-components'
import {useTranslation} from 'react-i18next'

import Text from 'src/components/Text'
import {useMediaQuery} from 'src/utilities/hooks'
import SelectButton from 'src/components/SelectButton'

interface RestaurantCardProps {
  address?: string
  workingHours?: string
  onButtonClick?: () => void
}

const MainContainer = styled.div`
  padding: 2.5rem;
  border: 0.125rem solid ${({theme}) => theme.colors.firstItemColor};
  display: grid;
  row-gap: 1.25rem;
  border-radius: 2.125rem;

  @media ${({theme}) => theme.queries.mobile} {
    row-gap: 1.25rem;
    padding: 1.25rem;
  }
`

const Button = styled(SelectButton)`
  height: 3.125rem;
  background-color: ${({theme}) => theme.colors.firstItemColor};
  border-radius: 2.125rem;
  color: ${({theme}) => theme.colors.primaryTextColor};
  ${({theme}) => ({...theme.typography.buttonText})}

  @media ${({theme}) => theme.queries.mobile} {
    font-size: 1rem;
  }
`

const Description = styled(Text as unknown as AnyStyledComponent)`
  white-space: pre-wrap;
`

const RestaurantCard = ({address, workingHours, onButtonClick}: RestaurantCardProps) => {
  const {isMobile} = useMediaQuery()

  const {t} = useTranslation()

  const renderItem = (title: string, description: string) => {
    return (
      <div>
        <Text
          type={isMobile ? 'descriptionTextMobile' : 'descriptionTextDesktop'}
          color="tertiaryTextColor"
          fontFamily="secondary"
        >
          {title}
        </Text>

        <Description type={isMobile ? 'descriptionTextMobile' : 'descriptionTextDesktop'} color="baseItemTextColor">
          {description}
        </Description>
      </div>
    )
  }

  return (
    <MainContainer>
      {renderItem(t('pages.dynamicPage.components.restaurantCard.addressLabel'), address ?? '-')}

      {renderItem(t('pages.dynamicPage.components.restaurantCard.workingHoursLabel'), workingHours ?? '-')}

      <Button onClick={onButtonClick}>{t('pages.dynamicPage.components.restaurantCard.orderButtonText')}</Button>
    </MainContainer>
  )
}

export default RestaurantCard
