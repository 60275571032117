import {memo, ReactNode} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'
import {useSelector} from 'react-redux'

import LogoIcon from 'src/assets/icons/logo.svg?react'
import CartButton from 'src/components/CartButton'
import ProductCounter from 'src/components/ProductCounter'
import IconButton from 'src/components/IconButton'
import Language from 'src/components/Language'
import IntercomButton from 'src/components/IntercomButton'
import {IS_STAGING} from 'src/utilities/constants'
import TestGroupHostContainer from 'src/components/TestGroupHostContainer'
import {RootState} from 'src/utilities/store'
import {mediaUrl} from 'src/utilities/functions'

export const MOBILE_FOOTER_HEIGHT = 58 as const

interface MobileFooterProps {
  cartSum?: number
  showZeroCartSum?: boolean
  count?: number
  hideLeftContent?: boolean
  intercomEnabled?: boolean
  showOrderHistory?: boolean
  hideBrandLogo?: boolean
  addDisabled?: boolean
  cartButtonDisabled?: boolean
  CartContentComponent?: ReactNode
  hideSearch?: boolean
  onCounterAdd?: () => void
  onCounterRemove?: () => void
  onCartClick?: () => void
  onSearchClick?: () => void
  onHistoryClick?: () => void
}

const Container = styled.div`
  width: 100%;
  height: ${MOBILE_FOOTER_HEIGHT}px;
  box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${({theme}) => theme.colors.baseItemColor};
`

const BrandLogoImg = styled.img.attrs({
  alt: 'brand-logo',
})`
  width: auto;
  height: 1.5rem;
`

const BrandLogoIcon = styled(LogoIcon as AnyStyledComponent)`
  width: auto;
  height: 1.25rem;
  color: ${({theme}) => theme.colors.baseItemTextColor};
`

const CartButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
`

const ProductCounterContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  padding-left: 0.75rem;
  display: flex;
  align-items: center;
`

const LeftContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  padding-left: 0.75rem;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`

const HistoryButtonContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-right: 0.75rem;
`

const MobileFooter = (props: MobileFooterProps) => {
  const websiteData = useSelector((state: RootState) => state.website.websiteData)
  const user = useSelector((state: RootState) => state.profile.user)

  return (
    <Container>
      {!props.hideLeftContent ? (
        (props.count ?? 0) > 0 ? (
          <ProductCounterContainer>
            <ProductCounter
              count={props.count}
              addDisabled={props.addDisabled}
              onAdd={props.onCounterAdd}
              onRemove={props.onCounterRemove}
            />
          </ProductCounterContainer>
        ) : (
          <LeftContainer>
            <Language />
            {!props.hideSearch && <IconButton name="search" hideShadow onClick={props.onSearchClick} />}
            {!!props.intercomEnabled && <IntercomButton />}
          </LeftContainer>
        )
      ) : null}
      {websiteData?.showBrandOnlyInSidebar ? (
        user?.receiptImage && !props.hideBrandLogo ? (
          <BrandLogoImg src={mediaUrl(user.receiptImage, {fit: 'clip', h: 72, w: 0})} />
        ) : null
      ) : !props.hideBrandLogo ? (
        IS_STAGING ? (
          <TestGroupHostContainer>
            <BrandLogoIcon />
          </TestGroupHostContainer>
        ) : (
          <BrandLogoIcon />
        )
      ) : null}
      {(props.cartSum ?? 0) > 0 || props.showZeroCartSum ? (
        <CartButtonContainer>
          <CartButton
            totalPrice={props.cartSum}
            ContentComponent={props.CartContentComponent}
            disabled={props.cartButtonDisabled}
            onCartClick={props.onCartClick}
          />
        </CartButtonContainer>
      ) : props.onHistoryClick && props.showOrderHistory ? (
        <HistoryButtonContainer>
          <IconButton name="clipboard" variant="gray" hideShadow onClick={props.onHistoryClick} />
        </HistoryButtonContainer>
      ) : null}
    </Container>
  )
}

export default memo(MobileFooter)
