import styled from 'styled-components'
import {useTranslation} from 'react-i18next'

import Stars from './components/Stars'
import TipsAmount from './components/TipsAmount'
import Label from './components/Label'

const SIZE = '15.875rem'

interface RatingProps {
  ratedRating?: number
  tippedAmount?: number
  disabled?: boolean
  onRate?: (rating: number) => void
}

const MainContainer = styled.div`
  width: ${SIZE};
  height: ${SIZE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.625rem solid #f5a623;
  border-radius: 50%;
  box-sizing: border-box;
`

const StarsContainer = styled.div`
  padding: 1.0625rem 0;
`

const TipsAmountContainer = styled.div`
  padding: 1.0625rem 0 2.125rem 0;
`

const Rating = (props: RatingProps) => {
  const {t} = useTranslation()

  const tipped = !!props.tippedAmount
  const rated = !!props.ratedRating

  return (
    <MainContainer>
      <Label visible={!rated}>{t('components.rating.starsLabel')}</Label>
      <StarsContainer>
        <Stars tipped={tipped} ratedRating={props.ratedRating} disabled={props.disabled} onRate={props.onRate} />
      </StarsContainer>
      {tipped && (
        <TipsAmountContainer>
          <TipsAmount tipped={tipped} amount={props.tippedAmount} />
        </TipsAmountContainer>
      )}
      <Label visible={rated}>{t('components.rating.thanksLabel')}</Label>
    </MainContainer>
  )
}

export default Rating
