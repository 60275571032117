import {FieldErrors, FieldValues} from 'react-hook-form'

import {FILE_SIZE, MAX_UPLOAD_FILE_SIZE, PHONE_NUMBER} from 'src/utilities/constants'
import i18n from 'src/utilities/i18n'

export const getErrorMsgs = <T extends FieldValues>(errors: FieldErrors<T>) => {
  const msgs = Object.values({...errors}).reduce<{[key: string]: string}>((obj, error) => {
    switch (error?.type) {
      case FILE_SIZE:
        obj[FILE_SIZE] = i18n.t('common.fileSizeErrorMsg', {size: MAX_UPLOAD_FILE_SIZE / 1000000})
        break
      case PHONE_NUMBER:
        obj[PHONE_NUMBER] = i18n.t('common.phoneNumberErrorMsg')
        break
      default:
        obj['any'] = i18n.t('pages.dynamicPage.components.reviewForm.errorMsg')
        break
    }

    return obj
  }, {})

  return Object.values(msgs)
}
