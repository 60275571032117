import {memo} from 'react'
import styled from 'styled-components'

import Animation from './Animation'
import circleSpinningAnimation from 'src/assets/lotties/circleSpinningAnimation.json'
import dotsAnimation from 'src/assets/lotties/dotsAnimation.json'
import {useWIndowSizes} from 'src/utilities/hooks'

interface ContainerProps {
  height: number
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${({theme}) => theme.sizes.maxPageContainerWidth}px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({height}) => height}px;
`

const DotsAnimationContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader = () => {
  const sizes = useWIndowSizes()

  return (
    <Container height={sizes.height}>
      <Animation options={{animationData: circleSpinningAnimation}} width="24.5rem" height="24.5rem" />
      <DotsAnimationContainer>
        <Animation options={{animationData: dotsAnimation}} width="5.75rem" height="auto" />
      </DotsAnimationContainer>
    </Container>
  )
}

export default memo(PageLoader)
