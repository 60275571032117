import {forwardRef, useCallback, useImperativeHandle, useRef, FC, useEffect, useMemo} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'
import {
  List as VirtualizedList,
  ListProps,
  ListRowProps,
  WindowScroller as VirtualizedWindowScroller,
  WindowScrollerProps,
} from 'react-virtualized'

import {ProductContract} from 'src/types/api'
import {ProductWithCartItem} from 'src/models/cart'
import {useWIndowSizes} from 'src/utilities/hooks'
import ProductsListItem from 'src/components/ProductsListItem'
import {HEADER_HEIGHT} from 'src/components/Header'
import {MASTER_CATEGORIES_MOBILE_HEIGHT_DIFFERENCE} from 'src/components/MasterCategories'

const LIST_ITEM_PADDING = 10
const LIST_ITEM_HEIGHT = 138
const LIST_ITEM_WITH_TAGS_HEIGHT = 165

// this will fix incompatible react versions types conflicts
const List = VirtualizedList as unknown as FC<ListProps>
const WindowScroller = VirtualizedWindowScroller as unknown as FC<WindowScrollerProps>

export interface ProductsListRef {
  getCategoryByScrollPosition: (scrollTop: number) => string | undefined
  getCategoryById: (categoryId: string) => {categoryId: string; scrollTop: number} | undefined
}

interface ProductsListProps {
  products: ProductWithCartItem[]
  scrollElement?: Element | (Window & typeof globalThis)
  headerImgHeight?: number
  hasMasterCategories?: boolean
  onAdd: (product: ProductContract) => void
  onIncrease: (id: string) => void
  onDecrease: (id: string) => void
  onItemClick: (data: {productId: string; cartId?: string}) => void
}

const ListItemContainer = styled.div`
  padding: ${LIST_ITEM_PADDING}px 0.75rem;
  box-sizing: border-box;
`

const ListStyled = styled(List as unknown as AnyStyledComponent).attrs({
  containerStyle: {paddingBottom: LIST_ITEM_PADDING},
})``

const ProductsList = forwardRef<ProductsListRef, ProductsListProps>((props, ref) => {
  const categories = useRef<{[index: number]: {categoryId: string; startPosition: number; endPosition: number}}>({})
  const listRef = useRef<VirtualizedList>(null)

  const sizes = useWIndowSizes()

  const heights = useMemo(
    () =>
      props.products.map((product) =>
        product.product.tagsIds?.length ? LIST_ITEM_WITH_TAGS_HEIGHT : LIST_ITEM_HEIGHT,
      ),
    [props.products],
  )

  const setCategories = useCallback(() => {
    let offsetTop = 0
    props.products.forEach((product, index) => {
      const itemHeight = heights[index] ?? LIST_ITEM_HEIGHT
      const headerImgHeight = props.headerImgHeight ?? 0
      const masterCategoriesHeight = props.hasMasterCategories ? MASTER_CATEGORIES_MOBILE_HEIGHT_DIFFERENCE : 0

      const startPosition = offsetTop + headerImgHeight - HEADER_HEIGHT + masterCategoriesHeight
      const endPosition = offsetTop + itemHeight + headerImgHeight - HEADER_HEIGHT + masterCategoriesHeight

      categories.current[index] = {
        categoryId: product.product.categoryId!,
        startPosition,
        endPosition,
      }

      offsetTop += itemHeight
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.headerImgHeight, props.products, sizes, props.hasMasterCategories, heights])

  const getCategoryByScrollPosition = useCallback((scrollTop: number) => {
    const foundCategory = Object.values(categories.current).find(
      (category) => scrollTop >= category.startPosition && scrollTop < category.endPosition,
    )
    if (!foundCategory) {
      return
    }

    return foundCategory.categoryId
  }, [])

  const getCategoryById = useCallback((categoryId: string) => {
    const foundCategory = Object.values(categories.current).find((category) => category.categoryId === categoryId)
    if (!foundCategory) {
      return
    }

    return {categoryId: foundCategory.categoryId, scrollTop: foundCategory.startPosition}
  }, [])

  const rowRenderer = useCallback(
    ({key, index, style}: ListRowProps) => {
      const item = props.products[index]

      return (
        <ListItemContainer key={key} style={style}>
          <ProductsListItem
            product={item.product}
            cartItem={item.cartItem}
            onAdd={props.onAdd}
            onIncrease={props.onIncrease}
            onDecrease={props.onDecrease}
            onItemClick={props.onItemClick}
          />
        </ListItemContainer>
      )
    },
    [props.onAdd, props.onDecrease, props.onIncrease, props.onItemClick, props.products],
  )

  useImperativeHandle(
    ref,
    () => {
      return {getCategoryByScrollPosition, getCategoryById}
    },
    [getCategoryByScrollPosition, getCategoryById],
  )

  useEffect(() => {
    setCategories()
  }, [setCategories])

  useEffect(() => {
    listRef.current?.recomputeRowHeights()
  }, [props.products])

  return (
    <WindowScroller scrollElement={props.scrollElement} key={String(props.scrollElement)}>
      {({height, isScrolling, scrollTop, onChildScroll, registerChild}) => (
        // @ts-ignore
        <div ref={registerChild}>
          <ListStyled
            ref={listRef}
            autoHeight
            height={height}
            width={sizes.width}
            // @ts-expect-error
            rowHeight={({index}) => heights[index] ?? LIST_ITEM_HEIGHT}
            rowCount={props.products.length}
            isScrolling={isScrolling}
            scrollTop={scrollTop}
            overscanRowCount={props.products.length}
            rowRenderer={rowRenderer}
            onScroll={onChildScroll}
          />
        </div>
      )}
    </WindowScroller>
  )
})

export default ProductsList
