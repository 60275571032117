import {useTranslation} from 'react-i18next'

import ErrorModal from 'src/components/ErrorModal'

interface TabletOfflineModalProps {
  isOpen?: boolean
  onClose?: () => void
}

const TabletOfflineModal = ({isOpen, onClose}: TabletOfflineModalProps) => {
  const {t} = useTranslation()

  return (
    <ErrorModal
      errorMsg={isOpen ? t('pages.home.components.tabletOfflineModal.msg') : ''}
      buttonText={t('pages.home.components.tabletOfflineModal.buttonText')}
      onBackClick={onClose}
      onRetryClick={onClose}
    />
  )
}

export default TabletOfflineModal
