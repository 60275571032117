import styled, {AnyStyledComponent} from 'styled-components'
import {useSelector} from 'react-redux'

import HeartIcon from 'src/assets/icons/heart.svg?react'
import Text from 'src/components/Text'
import {RootState} from 'src/utilities/store'
import {selectPriceWithCurrency} from 'src/models/profile'

const HEART_SIZE = '5.438rem'

interface TipsAmountProps {
  amount?: number
  tipped?: boolean
}

const MainContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const HeartContainer = styled.div`
  width: ${HEART_SIZE};
  height: ${HEART_SIZE};
  position: absolute;
  left: calc(${HEART_SIZE} / 2 * -1);
`

const HeartIconStyled = styled(HeartIcon as unknown as AnyStyledComponent)`
  width: 100%;
  height: 100%;
`

const TipsAmount = (props: TipsAmountProps) => {
  const priceWithCurrency = useSelector((state: RootState) => selectPriceWithCurrency(state, props.amount))

  return (
    <MainContainer>
      {!!props.tipped && (
        <HeartContainer>
          <HeartIconStyled />
        </HeartContainer>
      )}
      <Text type="tipsAmount" color="baseItemTextColor">
        {priceWithCurrency}
      </Text>
    </MainContainer>
  )
}

export default TipsAmount
