import {useSelector} from 'react-redux'
import styled, {AnyStyledComponent} from 'styled-components'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {OrderContract, OrderType, PaymentType} from 'src/types/api'
import {RootState} from 'src/utilities/store'
import {selectCartFromOrder, selectCartTakeAwayDiscountAmount, selectCartTakeAwayPrice} from 'src/models/cart'
import CartProduct from 'src/components/CartProduct'
import Text from 'src/components/Text'
import AdditionalCartItem from 'src/components/AdditionalCartItem'
import InfoItem from 'src/pages/Status/components/InfoItem'
import {DATE_AND_TIME_FORMAT, dayjs} from 'src/utilities/dayjs'
import {getServiceFeeTitle} from 'src/utilities/functions'

interface OrdersListItemsProps {
  order?: OrderContract
}

const OrderListItemContainer = styled.div`
  background-color: ${({theme}) => theme.colors.baseItemColor};
  padding: 0.75rem;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.043679);
  border-radius: 0.563rem;
  border: 1px solid ${({theme}) => theme.colors.baseItemColor};
  overflow: hidden;
`

const OrderNumberContainer = styled.div`
  padding: 0.625rem 0;
`

const OrderNumber = styled(Text as unknown as AnyStyledComponent).attrs({
  style: {
    fontSize: '2rem',
  },
})``

const CartProductsContainer = styled.div`
  padding-top: 2.563rem;
`

const AdditionalCartItemContainer = styled.div`
  padding-top: 0.75rem;
`

const InfoItemContainer = styled.div`
  padding-top: 1.5rem;
`

const TotalPriceContainer = styled.div`
  padding-top: 2.313rem;
`

const AdditionalCartItemStyled = styled(AdditionalCartItem as unknown as AnyStyledComponent)`
  padding: 0.75rem 0;
`

const OrdersListItems = ({order}: OrdersListItemsProps) => {
  const cart = useSelector((state: RootState) => selectCartFromOrder(state, order))
  const cartTakeAwayPrice = useSelector((state: RootState) => selectCartTakeAwayPrice(state, cart))
  const cartTakeAwayDiscountAmount = useSelector((state: RootState) => selectCartTakeAwayDiscountAmount(state, cart))

  const {t} = useTranslation()

  const tablet = useMemo(() => {
    return order?.commentToKitchen?.replace('QR order:', '').split('|')[0].trim()
  }, [order?.commentToKitchen])

  const comment = useMemo(() => {
    const commentArr = order?.commentToKitchen?.split('|')
    commentArr?.shift()
    return commentArr?.join('|').trim()
  }, [order?.commentToKitchen])

  const getTotalPriceDescription = () => {
    switch (order?.paymentType) {
      case PaymentType.WebCard:
        return t('pages.status.cardPaymentTitle')
      case PaymentType.WebTransfer:
        return t('pages.status.transferPaymentTitle')
      case PaymentType.Cashier:
        return t('pages.status.cashierPaymentTitle')
      case PaymentType.ClientCode:
        return t('pages.status.clientCodePaymentTitle')
      default:
        return ''
    }
  }

  return (
    <OrderListItemContainer>
      <Text type="checkoutPageOptionDescription" color="secondaryTextColor" align="center">
        {dayjs(order?.startTimeStamp).format(DATE_AND_TIME_FORMAT)}
      </Text>

      <OrderNumberContainer>
        <OrderNumber type="checkoutPageTitle" align="center" color="baseItemTextColor">
          #{order?.number}
        </OrderNumber>
      </OrderNumberContainer>

      <Text type="checkoutPageOptionDescription" color="secondaryTextColor" align="center">
        {tablet}
      </Text>

      <CartProductsContainer>
        {Object.values(cart.items).map((cartItem) => (
          <CartProduct key={cartItem.id} cartItem={cartItem} />
        ))}
      </CartProductsContainer>

      {order?.type === OrderType.TakeAway && cartTakeAwayPrice > 0 && (
        <AdditionalCartItemContainer>
          <AdditionalCartItemStyled title={t('common.takeAwayPriceTitle')} price={cartTakeAwayPrice} />
        </AdditionalCartItemContainer>
      )}

      {order?.type === OrderType.TakeAway && cartTakeAwayDiscountAmount > 0 && (
        <AdditionalCartItemContainer>
          <AdditionalCartItemStyled
            title={t('common.takeAwayDiscountAmountTitle')}
            price={cartTakeAwayDiscountAmount}
          />
        </AdditionalCartItemContainer>
      )}

      {!!order?.tipAmount && (
        <AdditionalCartItemContainer>
          <AdditionalCartItemStyled title={t('common.tips')} price={order.tipAmount} />
        </AdditionalCartItemContainer>
      )}

      {!!order?.promoCodeDiscount && order.promoCodeDiscount > 0 && (
        <AdditionalCartItemContainer>
          <AdditionalCartItemStyled title={t('common.discount')} price={-order.promoCodeDiscount} />
        </AdditionalCartItemContainer>
      )}

      {!!order?.loyaltyPaidAmount && order.loyaltyPaidAmount > 0 && (
        <AdditionalCartItemContainer>
          <AdditionalCartItemStyled title={t('common.discount')} price={-order.loyaltyPaidAmount} />
        </AdditionalCartItemContainer>
      )}

      {!!order?.serviceFeeAmount && (
        <AdditionalCartItemContainer>
          <AdditionalCartItemStyled title={getServiceFeeTitle()} price={order.serviceFeeAmount} />
        </AdditionalCartItemContainer>
      )}

      {!!comment && (
        <InfoItemContainer>
          <InfoItem title={t('common.commentTitle')} description={comment} />
        </InfoItemContainer>
      )}

      {!!order?.promoCode && (
        <InfoItemContainer>
          <InfoItem title={t('common.discountTitle')} description={order.promoCode} />
        </InfoItemContainer>
      )}

      {!!order?.loyaltyCode && (
        <InfoItemContainer>
          <InfoItem title={t('common.discountTitle')} description={order.loyaltyCode} />
        </InfoItemContainer>
      )}

      <TotalPriceContainer>
        <InfoItem
          title={t('pages.status.totalPriceTitle')}
          description={getTotalPriceDescription()}
          price={order?.totalPrice}
        />
      </TotalPriceContainer>
    </OrderListItemContainer>
  )
}

export default OrdersListItems
