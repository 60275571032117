import styled from 'styled-components'
import {CSSProperties, MouseEventHandler, ReactNode, useLayoutEffect, useRef, useState} from 'react'
import Modal, {Props} from 'react-modal'
import {useNavigate} from 'react-router-dom'

import IconButton from 'src/components/IconButton'
import CustomScroll from './CustomScroll'
import {setBodyOverflow} from 'src/utilities/functions'
import {Color} from 'src/utilities/theme'

enum Alignment {
  top = 'top',
  center = 'center',
  bottom = 'bottom',
}

interface NewModalProps extends Props {
  alignment?: keyof typeof Alignment
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  noPadding?: boolean
  showCloseButton?: boolean
  scrollPaddingX?: string
  scrollPaddingY?: string
  hasCustomScroll?: boolean
  bgColor?: Color
  FooterComponent?: ReactNode
  StickyHeaderComponent?: ReactNode
  onRequestClose?: () => void
}

interface MainContainerProps {
  alignItems?: CSSProperties['alignItems']
  noPadding?: boolean
}

interface MainContentContainerProps {
  bgColor: Color
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  footerHeight?: number
  noPadding?: boolean
}

const ModalStyled = styled(Modal).attrs<Props>(({style, ...props}) => ({
  style: {
    overlay: {
      backgroundColor: 'rgba(217, 217, 217, 0.40)',
      backdropFilter: 'blur(3px)',
      WebkitBackdropFilter: 'blur(3px)',
      zIndex: 999,
      display: 'flex',
      ...style?.overlay,
    },
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      ...style?.content,
    },
  },
  ...props,
}))<Props>``

const MainContainer = styled.div<MainContainerProps>`
  flex: 1;
  padding: ${({noPadding}) => (noPadding ? 0 : 3)}rem;
  display: flex;
  justify-content: center;
  align-items: ${({alignItems}) => alignItems ?? 'flex-start'};

  @media ${({theme}) => theme.queries.mobile} {
    padding: ${({noPadding}) => (noPadding ? 0 : 0.75)}rem;
  }
`

const MainContentContainer = styled.div<MainContentContainerProps>`
  position: relative;
  max-width: calc(100vw - ${({noPadding}) => (noPadding ? 0 : 6)}rem);
  max-height: calc(100vh - ${({footerHeight}) => footerHeight}px - ${({noPadding}) => (noPadding ? 0 : 6)}rem);
  width: ${({width}) => width ?? 'auto'};
  height: ${({height}) => height ?? 'auto'};
  border-radius: ${({noPadding}) => (noPadding ? '1.25rem 1.25rem 0 0' : '1.25rem')};
  background-color: ${({theme, bgColor}) => theme.colors[bgColor]};
  box-shadow: 0 2px 30px 0 rgba(233, 233, 233, 0.5);
  display: grid;
  grid-template-rows: auto 1fr;

  @media ${({theme}) => theme.queries.mobile} {
    max-width: calc(100vw - ${({noPadding}) => (noPadding ? 0 : 1.5)}rem);
    max-height: calc(100vh - ${({footerHeight}) => footerHeight}px - ${({noPadding}) => (noPadding ? 0 : 1.5)}rem);
  }
`

const MainContentInnerContainer = styled.div`
  overflow: hidden;
  display: grid;
  border-radius: inherit;
`

const ContentContainer = styled.div`
  overflow: auto;
`

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`

const NewModal = ({
  alignment = 'center',
  width = 'auto',
  height = 'auto',
  noPadding,
  showCloseButton,
  scrollPaddingX,
  scrollPaddingY,
  hasCustomScroll = true,
  bgColor = 'backgroundColor',
  FooterComponent,
  StickyHeaderComponent,
  children,
  ...props
}: NewModalProps) => {
  const [footerHeight, setFooterHeight] = useState<number>(0)

  const footerRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  const getAlignment = (): CSSProperties['alignItems'] => {
    switch (alignment) {
      case Alignment.center:
        return 'center'
      case Alignment.bottom:
        return 'flex-end'
      default:
        return 'flex-start'
    }
  }

  const stopPropagation: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
  }

  const handleRequestClose = () => {
    if (!props.onRequestClose) {
      navigate(-1)
      return
    }

    props.onRequestClose()
  }

  const handleAfterOpen = () => {
    setBodyOverflow('hidden')
  }

  const handleAfterClose = () => {
    setBodyOverflow('visible')
  }

  useLayoutEffect(() => {
    if (!FooterComponent || !footerRef.current) {
      return
    }

    setFooterHeight(footerRef.current.clientHeight)
  }, [FooterComponent])

  return (
    <ModalStyled
      ariaHideApp={false}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      shouldFocusAfterRender={false}
      contentElement={(props, children) => (
        <div {...props} onClick={handleRequestClose}>
          {/* @ts-expect-error */}
          {children}
        </div>
      )}
      onRequestClose={handleRequestClose}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      {...props}
    >
      <MainContainer noPadding={noPadding} alignItems={getAlignment()}>
        <MainContentContainer
          noPadding={noPadding}
          width={width}
          height={height}
          footerHeight={footerHeight}
          bgColor={bgColor}
          onClick={stopPropagation}
        >
          <div>{!!StickyHeaderComponent && StickyHeaderComponent}</div>

          <MainContentInnerContainer>
            {hasCustomScroll ? (
              <CustomScroll paddingX={scrollPaddingX} paddingY={scrollPaddingY}>
                {/* @ts-expect-error */}
                {children}
              </CustomScroll>
            ) : (
              <ContentContainer>
                {/* @ts-expect-error */}
                {children}
              </ContentContainer>
            )}
          </MainContentInnerContainer>

          {!!showCloseButton && (
            <CloseButtonContainer>
              <IconButton name="menu-close" onClick={handleRequestClose} />
            </CloseButtonContainer>
          )}
        </MainContentContainer>
      </MainContainer>

      {!!FooterComponent && (
        <div ref={footerRef} onClick={stopPropagation}>
          {FooterComponent}
        </div>
      )}
    </ModalStyled>
  )
}

export default NewModal
