import immerPlugin from '@rematch/immer'
import {init, RematchDispatch, RematchRootState} from '@rematch/core'
import persistPlugin, {getPersistor} from '@rematch/persist'
import loadingPlugin, {ExtraModelsFromLoading} from '@rematch/loading'
import selectPlugin from '@rematch/select'
import {combineReducers} from 'redux'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'

import {models, RootModel} from 'src/models'

type FullModel = ExtraModelsFromLoading<RootModel>

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['app'],
}

const cartPersistConfig = {
  key: 'cart',
  storage: storageSession,
}

const timeSlotsPersistConfig = {
  key: 'timeSlots',
  storage: storageSession,
}

const ordersPersistConfig = {
  key: 'orders',
  storage: storageSession,
}

export const store = init<RootModel, FullModel>({
  models,
  plugins: [persistPlugin(rootPersistConfig), loadingPlugin(), selectPlugin(), immerPlugin()],
  redux: {
    combineReducers: (reducers) =>
      combineReducers({
        ...reducers,
        cart: persistReducer(cartPersistConfig, reducers.cart),
        timeSlots: persistReducer(timeSlotsPersistConfig, reducers.timeSlots),
        orders: persistReducer(ordersPersistConfig, reducers.orders),
      }),
  },
})

export const waitForPersistor = () => {
  const persistor = getPersistor()

  return new Promise<void>((resolve) => {
    if (persistor.getState().bootstrapped) {
      resolve()
      return
    }

    const unsubscribe = persistor.subscribe(() => {
      if (persistor.getState().bootstrapped) {
        unsubscribe()
        resolve()
      }
    })
  })
}

export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel, FullModel>
