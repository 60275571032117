import {createModel} from '@rematch/core'

import {RootModel} from '.'

interface SearchState {
  searchValue: string
}

const initialState: SearchState = {
  searchValue: '',
}

export const search = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSearchValue(state, searchValue: string) {
      state.searchValue = searchValue
    },
  },
})
