import {ChangeEventHandler, PropsWithChildren} from 'react'
import {FieldValues, UseControllerProps, useController} from 'react-hook-form'
import styled from 'styled-components'

import Button from '../Button'

interface FormFileUploadButtonProps<T extends FieldValues> extends UseControllerProps<T> {}

const Label = styled.label`
  display: grid;
  height: 100%;
  place-content: center;
  cursor: pointer;
`

const Input = styled.input`
  display: none;
`

const FormFileUploadButton = <T extends FieldValues>({
  children,
  ...props
}: PropsWithChildren<FormFileUploadButtonProps<T>>) => {
  const {
    field: {value, ...field},
  } = useController(props)

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files
    if (!files) {
      return
    }

    const values = value as File[]

    const filteredFiles = [...files].filter((file) => !values.some((value) => value.name === file.name))

    field.onChange([...values, ...filteredFiles])
  }

  return (
    <Button buttonType="secondary">
      <Input {...field} id="fileInput" type="file" value="" multiple onChange={handleChange} />

      <Label htmlFor="fileInput">{children}</Label>
    </Button>
  )
}

export default FormFileUploadButton
