import {ButtonHTMLAttributes, ImgHTMLAttributes} from 'react'
import styled from 'styled-components'

import Text from './Text'
import {useMediaQuery} from 'src/utilities/hooks'

interface IconActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  error?: boolean
  selected?: boolean
  imgSrc?: ImgHTMLAttributes<HTMLImageElement>['src']
}

interface ButtonProps {
  error?: boolean
  selected?: boolean
}

const Button = styled.button<ButtonProps>`
  padding: 0;
  border-radius: 34px;
  border: 2px solid
    ${({theme, error, selected}) => {
      if (error) {
        return theme.colors.errorTextColor
      }

      if (selected) {
        return theme.colors.firstItemColor
      }

      return theme.colors.secondItemColor
    }};
  background-color: ${({theme, selected}) => (selected ? theme.colors.firstItemColor : theme.colors.secondItemColor)};
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.75rem;
  width: 100%;
  height: 3.125rem;

  :enabled {
    cursor: pointer;
  }

  @media ${({theme}) => theme.queries.mobile} {
    height: 2.188rem;
    column-gap: 0.5rem;
  }
`

const ImageContainer = styled.div`
  display: flex;
`

const Image = styled.img`
  width: auto;
  height: 2rem;

  @media ${({theme}) => theme.queries.mobile} {
    height: 1.5rem;
  }
`

const IconActionButton = ({error, selected, children, imgSrc, ...props}: IconActionButtonProps) => {
  const {isMobile} = useMediaQuery()

  return (
    <Button error={error} selected={selected} {...props}>
      {!!imgSrc && (
        <ImageContainer>
          <Image src={imgSrc} alt="action-btn-icon" />
        </ImageContainer>
      )}

      <Text type={isMobile ? 'productPrice' : 'buttonText'}>{children}</Text>
    </Button>
  )
}

export default IconActionButton
