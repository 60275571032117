import {Models} from '@rematch/core'

import {profile} from './profile'
import {catalog} from './catalog'
import {app} from './app'
import {orders} from './orders'
import {cart} from './cart'
import {home} from './home'
import {search} from './search'
import {appraisals} from './appraisals'
import {group} from './group'
import {timeSlots} from './timeSlots'
import {translations} from './translations'
import {website} from './website'
import {invoice} from './invoice'

export interface RootModel extends Models<RootModel> {
  profile: typeof profile
  catalog: typeof catalog
  app: typeof app
  orders: typeof orders
  cart: typeof cart
  home: typeof home
  search: typeof search
  appraisals: typeof appraisals
  group: typeof group
  timeSlots: typeof timeSlots
  translations: typeof translations
  website: typeof website
  invoice: typeof invoice
}
export const models: RootModel = {
  profile,
  catalog,
  app,
  orders,
  cart,
  home,
  search,
  appraisals,
  group,
  timeSlots,
  translations,
  website,
  invoice,
}
