import {MouseEvent, memo} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import AddIcon from 'src/assets/icons/add.svg?react'

interface ProductsListItemAddButtonProps {
  onAdd: () => void
}

const ProductsListItemAddButtonContainer = styled.button`
  border: none;
  cursor: pointer;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.firstItemColor};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`

const AddIconStyled = styled(AddIcon as unknown as AnyStyledComponent)`
  color: ${({theme}) => theme.colors.primaryTextColor};
`

const ProductsListItemAddButton = (props: ProductsListItemAddButtonProps) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.stopPropagation()
    props.onAdd()
  }

  return (
    <ProductsListItemAddButtonContainer onClick={handleClick}>
      <AddIconStyled />
    </ProductsListItemAddButtonContainer>
  )
}

export default memo(ProductsListItemAddButton)
