import './utilities/sentry'
import React, {Suspense} from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {getPersistor} from '@rematch/persist'
import {PersistGate} from 'redux-persist/lib/integration/react'
import {IntercomProvider} from 'react-use-intercom'
import {APIProvider} from '@vis.gl/react-google-maps'
import {createGlobalStyle} from 'styled-components'
import {HelmetProvider} from 'react-helmet-async'

import Navigation from './pages'
import {store} from './utilities/store'
import PageLoader from './components/PageLoader'
import ThemeProvider from './ThemeProvider'

import './utilities/functions'
import './utilities/i18n'
import './utilities/api'
import './styles/index.scss'

const persistor = getPersistor()

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({theme}) => theme.colors.backgroundColor};
  }
`

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <PersistGate persistor={persistor} loading={<PageLoader />}>
          <IntercomProvider appId={process.env.VITE_APP_INTERCOM_APP_ID}>
            <APIProvider apiKey={process.env.VITE_APP_GOOGLE_MAPS_API_KEY}>
              <HelmetProvider>
                <Suspense fallback={<PageLoader />}>
                  {/* @ts-expect-error */}
                  <GlobalStyle />
                  <Navigation />
                </Suspense>
              </HelmetProvider>
            </APIProvider>
          </IntercomProvider>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
)
