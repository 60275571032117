import {PropsWithChildren} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import OvalIcon from 'src/assets/icons/dynamic-page-oval.svg?react'
import CheckmarkIcon from 'src/assets/icons/dynamic-page-checkmark.svg?react'

interface CheckboxProps extends PropsWithChildren {
  checked?: boolean
  disabled?: boolean
  error?: boolean
  onChange?: (checked: boolean) => void
}

interface OvalProps {
  error?: boolean
}

const MainContainer = styled.button.attrs({
  type: 'button',
})`
  width: 100%;
  background-color: transparent;
  padding: 0;
  line-height: 0;
  border: none;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.875rem;
  align-items: center;

  :enabled {
    cursor: pointer;
  }
`

const CheckmarkContainer = styled.div`
  width: 1.9375rem;
  height: 1.9375rem;
  position: relative;
`

const Oval = styled(OvalIcon as AnyStyledComponent)<OvalProps>`
  width: 1.9375rem;
  height: 1.9375rem;
  color: ${({theme, error}) => (error ? theme.colors.errorTextColor : theme.colors.secondaryTextColor)};
`

const Checkmark = styled(CheckmarkIcon as AnyStyledComponent)`
  width: auto;
  height: 1.5rem;
  position: absolute;
  left: 0.25rem;
  bottom: 0.4375rem;
  color: ${({theme}) => theme.colors.tertiaryTextColor};
`

const Checkbox = ({checked, disabled, error, children, onChange}: CheckboxProps) => {
  const handleClick = () => {
    onChange?.(!checked)
  }

  return (
    <MainContainer disabled={disabled} onClick={handleClick}>
      <CheckmarkContainer>
        <Oval error={error} />

        {!!checked && <Checkmark />}
      </CheckmarkContainer>

      {children}
    </MainContainer>
  )
}

export default Checkbox
