import styled, {AnyStyledComponent} from 'styled-components'

import ApplePayIcon from 'src/assets/icons/apple-pay.svg?react'
import GooglePayIcon from 'src/assets/icons/google-pay.svg?react'

const MainContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
`

const ApplePayIconStyled = styled(ApplePayIcon as unknown as AnyStyledComponent)`
  height: 1.25rem;
  width: auto;
`

const GooglePayIconStyled = styled(GooglePayIcon as unknown as AnyStyledComponent)`
  height: 1.25rem;
  width: auto;
`

const PayIcons = () => {
  return (
    <MainContainer>
      <GooglePayIconStyled />

      <ApplePayIconStyled />
    </MainContainer>
  )
}

export default PayIcons
