import {memo, ReactNode, CSSProperties} from 'react'
import styled from 'styled-components'

import {Color, Typography} from 'src/utilities/theme'

interface TextProps {
  type: Typography
  color?: Color
  children?: ReactNode
  align?: CSSProperties['textAlign']
  uppercase?: boolean
  italic?: boolean
  style?: CSSProperties
  fontFamily?: FontFamily
}

interface TextStyledProps {
  type: Typography
  color: Color
  align: CSSProperties['textAlign']
  uppercase: boolean
  italic?: boolean
  style?: CSSProperties
  fontFamily: FontFamily
}

type FontFamily = 'primary' | 'secondary'

const TextStyled = styled.div.attrs<TextStyledProps>(
  ({type, color, theme, align, uppercase, italic, style, fontFamily}) => ({
    style: {
      ...theme.typography[type],
      color: theme.colors[color],
      textAlign: align,
      textTransform: uppercase ? 'uppercase' : 'none',
      fontStyle: italic ? 'italic' : 'normal',
      fontFamily: theme.fontFamilies[fontFamily],
      ...style,
    },
  }),
)<TextStyledProps>``

const Text = ({
  type,
  color = 'primaryTextColor',
  align = 'start',
  uppercase = false,
  fontFamily = 'primary',
  ...props
}: TextProps) => {
  const as: {[key in Typography]: any} = {
    productBoxName: 'h1',
    productBoxDescription: 'p',
    productBoxPrice: 'span',
    productBoxStrikethroughPrice: 'span',
    verticalMenu: 'span',
    horizontalMenu: 'span',
    productPageTitle: 'h1',
    productPageDescription: 'p',
    productPageOptionName: 'h2',
    productPageOptionDescription: 'p',
    productPageOptionSelection: 'span',
    checkoutPageTitle: 'h1',
    checkoutPageOptionTitle: 'h2',
    checkoutPageOptionDescription: 'p',
    checkoutPageButton: 'span',
    primaryButton: 'span',
    payButton: 'span',
    errorSearchButton: 'span',
    newOrderButton: 'span',
    tipsAmount: 'span',
    ratingLabel: 'span',
    modalOptions: 'span',
    modalTitle: 'h1',
    modalTitleMedium: 'h1',
    buttonText: 'span',
    menuItems: 'span',
    productsCategoryTitle: 'h1',
    productsCategoryTitleMedium: 'h1',
    productTitle: 'h1',
    productDescription: 'p',
    productPrice: 'span',
    productOldPrice: 'span',
    heroTitle: 'h1',
    cartButton: 'span',
    cartItemsNumber: 'span',
    productScreenTitle: 'h1',
    productScreenTitleBold: 'h1',
    productScreenDescription: 'p',
    productOptionsTitle: 'h1',
    productOptions: 'span',
    errorTitle: 'h1',
    errorDescription: 'p',
    errorButtons: 'span',
    screenTitle: 'h1',
    checkoutTitle: 'h1',
    placeholder: 'span',
    checkoutProductTitle: 'h1',
    checkoutProductDescription: 'p',
    payButtons: 'span',
    orderTitle: 'h1',
    orderDescription: 'p',
    orderNumber: 'span',
    orderSmallTitle: 'h1',
    orderTotalPrice: 'span',
    mainTitleDesktop: 'h1',
    mainTitleMobile: 'h1',
    descriptionTextDesktop: 'p',
    descriptionTextMobile: 'p',
  }

  return (
    <TextStyled
      as={as[type]}
      type={type}
      color={color}
      align={align}
      uppercase={uppercase}
      fontFamily={fontFamily}
      {...props}
    />
  )
}

export default memo(Text)
