import {AxiosError} from 'axios'
import {KeyboardEventHandler} from 'react'
import {matchPath} from 'react-router-dom'
import {v4 as uuidv4, validate as validateuuidv4} from 'uuid'
import _ from 'lodash'
import WebFont from 'webfontloader'

import {
  Currency,
  DayOfWeek,
  ErrorContract,
  LanguageCode,
  OptionSetContract,
  ProductContract,
  QrVenueContract,
  ServiceFeeType,
} from 'src/types/api'
import i18n from './i18n'
import {store} from './store'
import {Cart, CartItem, CartItemChildOptionSet, CartItemOptionSet, selectCart} from 'src/models/cart'
import {sizes} from './theme'
import {CURRENCY_SYMBOLS, GEOLOCATION_POSITION_ERROR_CODES, TIPS_FIXED_AMOUNT_ID, IS_STAGING} from './constants'
import {Position} from 'src/types'
import {CustomFontFamilies, CustomFontFamilyKeys, FontEvent} from 'src/types/fonts'
import fonts from 'src/styles/fonts.scss'
import {selectProductsObj} from 'src/models/catalog'
import {ErrorModalProps, TransErrorModalMsg} from 'src/components/ErrorModal'

const t = i18n.t

const MEDIA_URL_DEFAULT_PROPS: MediaUrlProps = {
  fit: 'crop',
  w: sizes.defaultImgSize,
  h: sizes.defaultImgSize,
}

interface MediaUrlProps {
  fit?: 'clamp' | 'clip' | 'crop' | 'facearea' | 'fill' | 'fillmax' | 'max' | 'min' | 'scale'
  w?: number
  h?: number
}

export interface CartItemOptionSets {
  optionSets: CartItemOptionSet
  childOptionSets: CartItemChildOptionSet
}

// https://docs.imgix.com/apis/rendering
export const mediaUrl = (imageName?: string | null, props: MediaUrlProps = MEDIA_URL_DEFAULT_PROPS) => {
  const params = Object.entries({...MEDIA_URL_DEFAULT_PROPS, ...props}).reduce(
    (p, [key, value], index) => (index === 0 ? (p = `?${key}=${value}`) : (p += `&${key}=${value}`)),
    '',
  )
  return imageName ? `${process.env.VITE_APP_IMGIX_URL}/${imageName}${params}` : ''
}

const getProductTitles = (disabledProductIds: string[]) => {
  const productsObj = selectProductsObj(store.getState())

  return disabledProductIds.reduce<string>((titles, disabledProductId) => {
    const title = productsObj[disabledProductId]?.title
    if (title) {
      titles += titles ? `, ${title}` : title
    }

    return titles
  }, '')
}

export const handleError = (error: AxiosError<ErrorContract>): ErrorModalProps | null => {
  const data = error.response?.data
  if (!data) {
    return null
  }

  switch (data.internalErrorCode) {
    case 8:
      return {
        ErrorMsgComponent: TransErrorModalMsg({
          i18nKey: 'backendErrors.8',
          values: {productTitles: getProductTitles(data.orderValidationError?.disabledProductIds ?? [])},
        }),
        buttonText: t('backendErrors.8.buttonText'),
      }
    case 115:
      return {
        errorMsg: t('backendErrors.115'),
        buttonText: t('backendErrors.115.buttonText'),
      }
    default:
      break
  }

  switch (data.message) {
    case 'DishDisabled': {
      const cart = selectCart(store.getState())

      const cartProducts = Object.values(
        Object.values(cart.items).reduce<{
          [productId: string]: {itemId: string; product: ProductContract}
        }>((products, item) => {
          const itemId = item.id

          products[item.product.id!] = {itemId, product: item.product}

          Object.values(item.optionSets ?? {}).forEach((optionSet) =>
            Object.values(optionSet.options ?? {}).forEach(
              (option) => (products[option.option.id!] = {itemId, product: option.option}),
            ),
          )

          return products
        }, {}),
      )

      const productTitles: string[] = []

      data.orderValidationError?.disabledProductIds?.forEach((disabledProductId) =>
        cartProducts.forEach((cartProduct) => {
          if (cartProduct.product.id !== disabledProductId) {
            return
          }

          store.dispatch.cart.removeFromCart(cartProduct.itemId)

          productTitles.push(cartProduct.product.title!)
        }),
      )

      data.orderValidationError?.updatedProducts?.forEach(store.dispatch.catalog.updateProduct)

      return {errorMsg: t('backendErrors.dishDisabled', {titles: Array.from(new Set(productTitles)).join(', ')})}
    }
    case 'PromoCodeNotFound':
      return {errorMsg: t('backendErrors.promoCodeNotFound')}
    default:
      return {errorMsg: data.message}
  }
}

export const getWindowSizes = () => {
  return {
    width: window.innerWidth < sizes.maxPageContainerWidth ? window.innerWidth : sizes.maxPageContainerWidth,
    height: window.innerHeight,
  }
}

export const getUnixTimestamp = (timestamp: number) => {
  return Math.floor(timestamp / 1000)
}

export const isEmailValid = (email: string) => {
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

  return regex.test(email)
}

export const allowOnlyIntegers: KeyboardEventHandler<HTMLElement> = (e) => {
  if (!isNaN(Number(e.key)) || e.key === 'Backspace') {
    return
  }

  e.preventDefault()
}

export const getMatchTabletId = () => {
  return matchPath({path: '/:tabletId', end: true}, window.location.pathname)?.params?.tabletId
}

export const getTabletId = () => {
  const lastTabletId = store.getState().app.lastTabletId
  const venues = store.getState().group.group?.venues

  const matchTabletId = matchPath({path: '/:tabletId', end: false}, window.location.pathname)?.params?.tabletId

  if (matchTabletId && validateuuidv4(matchTabletId)) {
    return matchTabletId
  }

  if (!venues?.length) {
    return
  }

  const foundVenue = venues.find((venue) => venue.tabletId === lastTabletId)
  if (foundVenue) {
    return foundVenue.tabletId
  }

  return venues[0].tabletId
}

export const getDefautlLanguage = async () => {
  try {
    if (getTabletId()) {
      const userLanguages = await store.dispatch.profile.fetchUserLanguages()

      return userLanguages?.[0]?.code ?? LanguageCode.En
    }

    const groupLanguage = await store.dispatch.group.fetchGroupLanguage()

    return groupLanguage ?? LanguageCode.En
  } catch (error) {
    console.error(error)

    return LanguageCode.En
  }
}

export const getOptionsPreselectedCount = (options?: ProductContract[]) => {
  return (options ?? []).reduce<number>((total, option) => {
    const count = option.count ?? 0

    total += count

    return total
  }, 0)
}

export const instantAddToCart = (optionSets?: OptionSetContract[]) => {
  if (!optionSets?.length) {
    return true
  }

  return !optionSets.some((optionSet) => {
    const minSelections = optionSet.minSelections ?? 0
    const maxSelections = optionSet.maxSelections ?? 0

    const count = getOptionsPreselectedCount(optionSet.options!)

    if (count === minSelections || count === maxSelections) {
      return false
    }

    return true
  })
}

export const isObjEmpty = (obj?: object) => {
  return Object.keys(obj ?? {}).length === 0
}

export const getCartItemOptionSets = (optionSets?: OptionSetContract[]): CartItemOptionSets => {
  return (optionSets ?? []).reduce<CartItemOptionSets>(
    (obj, optionSet) => {
      const optionSetId = optionSet.id!

      optionSet.options?.forEach((option) => {
        if (!option.count) {
          return
        }

        if (!obj.optionSets[optionSetId]) {
          obj.optionSets[optionSetId] = {
            optionSet,
            options: {},
          }
        }

        obj.optionSets[optionSetId].options[option.id!] = {
          option,
          count: option.count,
        }

        const childOptionSetsObj = getCartItemOptionSets(option.optionSets!).optionSets
        if (isObjEmpty(childOptionSetsObj)) {
          return
        }

        if (!obj.childOptionSets[optionSetId]) {
          obj.childOptionSets[optionSetId] = {}
        }

        obj.childOptionSets[optionSetId] = {
          ...obj.childOptionSets[optionSetId],
          ...childOptionSetsObj,
        }
      })

      return obj
    },
    {optionSets: {}, childOptionSets: {}},
  )
}

export const getCartItemWithPreselectedOptions = (product: ProductContract): CartItem => {
  return {
    id: uuidv4(),
    product,
    count: 1,
    ...getCartItemOptionSets(product.optionSets!),
  }
}

export const priceWithCurrency = (price?: number, currency?: Currency, hideSymbol?: boolean) => {
  const userCurrency = currency ?? store.getState().profile.user?.currency

  const symbol: string | undefined = userCurrency ? CURRENCY_SYMBOLS[userCurrency] : ''

  let value = (price ?? 0).toFixed(2)

  if (symbol && !hideSymbol) {
    value += ` ${symbol}`
  }

  return value
}

export const setBodyOverflow = (value: 'visible' | 'hidden') => {
  document.body.style.overflow = value
}

export const getCartTotalPrice = (cart?: Cart) => {
  return Object.values(cart?.items ?? {}).reduce<number>((totalPrice, cartItem) => {
    const productPrice = cartItem?.product?.price ?? 0
    const productCount = cartItem?.count ?? 0

    const optionsSetTotalSum = Object.values(cartItem?.optionSets ?? {}).reduce<number>((optionSetsSum, optionSet) => {
      const optionsTotalSum = Object.values(optionSet?.options ?? {}).reduce<number>((optionsSum, option) => {
        const optionPrice = option?.option?.price ?? 0
        const optionCount = option?.count ?? 0

        return (optionsSum += optionPrice * optionCount)
      }, 0)

      return (optionSetsSum += optionsTotalSum)
    }, 0)

    const childOptionsSetTotalSum = Object.values(cartItem?.childOptionSets ?? {}).reduce(
      (parentOptionSetsSum, parentOptionSet) => {
        const childOptionSetsTotalSum = Object.values(parentOptionSet ?? {}).reduce(
          (childOptionSetsSum, childOptionSet) => {
            const optionsTotalSum = Object.values(childOptionSet?.options ?? {}).reduce((optionsSum, option) => {
              const optionPrice = option?.option?.price ?? 0
              const optionCount = option?.count

              return (optionsSum += optionPrice * optionCount)
            }, 0)

            return (childOptionSetsSum += optionsTotalSum)
          },
          0,
        )

        return (parentOptionSetsSum += childOptionSetsTotalSum)
      },
      0,
    )

    return (totalPrice += (productPrice + optionsSetTotalSum + childOptionsSetTotalSum) * productCount)
  }, 0)
}

export const getCartTotalTakeAwayDiscountAmount = (cart?: Cart) => {
  return Object.values(cart?.items ?? {}).reduce<number>((totalPrice, cartItem) => {
    const productPrice = cartItem?.product?.takeAwayDiscountAmount ?? 0
    const productCount = cartItem?.count ?? 0

    const optionsSetTotalSum = Object.values(cartItem?.optionSets ?? {}).reduce<number>((optionSetsSum, optionSet) => {
      const optionsTotalSum = Object.values(optionSet?.options ?? {}).reduce<number>((optionsSum, option) => {
        const optionPrice = option?.option?.takeAwayDiscountAmount ?? 0
        const optionCount = option?.count ?? 0

        return (optionsSum += optionPrice * optionCount)
      }, 0)

      return (optionSetsSum += optionsTotalSum)
    }, 0)

    const childOptionsSetTotalSum = Object.values(cartItem?.childOptionSets ?? {}).reduce(
      (parentOptionSetsSum, parentOptionSet) => {
        const childOptionSetsTotalSum = Object.values(parentOptionSet ?? {}).reduce(
          (childOptionSetsSum, childOptionSet) => {
            const optionsTotalSum = Object.values(childOptionSet?.options ?? {}).reduce((optionsSum, option) => {
              const optionPrice = option?.option?.takeAwayDiscountAmount ?? 0
              const optionCount = option?.count

              return (optionsSum += optionPrice * optionCount)
            }, 0)

            return (childOptionSetsSum += optionsTotalSum)
          },
          0,
        )

        return (parentOptionSetsSum += childOptionSetsTotalSum)
      },
      0,
    )

    return (totalPrice += (productPrice + optionsSetTotalSum + childOptionsSetTotalSum) * productCount)
  }, 0)
}

export const getCartTipsAmount = (cart?: Cart) => {
  if (!cart?.tipsData) {
    return 0
  }

  const totalPrice = getCartTotalPrice(cart)

  if (cart.tipsData.id === TIPS_FIXED_AMOUNT_ID) {
    return getRoundedNumber(cart.tipsData.value)
  }

  return getRoundedNumber((totalPrice * cart.tipsData.value) / 100)
}

export const getRoundedNumber = (value: number, fractionDigits: number = 2) => {
  return Number(value.toFixed(fractionDigits))
}

export const getCurrentPosition = async () => {
  const geolocation = navigator?.geolocation as Geolocation | undefined

  if (!geolocation) {
    throw GEOLOCATION_POSITION_ERROR_CODES.GENERAL
  }

  const position = await new Promise<Position>((resolve, reject) => {
    geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          reject(GEOLOCATION_POSITION_ERROR_CODES.PERMISSION_DENIED)
        }

        reject(GEOLOCATION_POSITION_ERROR_CODES.GENERAL)
      },
    )
  })

  return position
}

export const getDistanceInMetersBetweenPositions = (position1: Position, position2: Position) => {
  const R = 6371e3
  const p1 = (position1.latitude * Math.PI) / 180
  const p2 = (position2.latitude * Math.PI) / 180
  const deltaP = p2 - p1
  const deltaLon = position2.longitude - position1.longitude
  const deltaLambda = (deltaLon * Math.PI) / 180
  const a =
    Math.sin(deltaP / 2) * Math.sin(deltaP / 2) +
    Math.cos(p1) * Math.cos(p2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2)
  const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R
  return d
}

export const getNearestVenue = async (venues?: QrVenueContract[]) => {
  if (!venues?.length) {
    throw GEOLOCATION_POSITION_ERROR_CODES.GENERAL
  }

  if (venues.length === 1) {
    return venues[0]
  }

  const currentPosition = await getCurrentPosition()

  const sortedVenues = [...venues].sort((a, b) => {
    return (
      getDistanceInMetersBetweenPositions({latitude: a.latitude ?? 0, longitude: a.longitude ?? 0}, currentPosition) -
      getDistanceInMetersBetweenPositions({latitude: b.latitude ?? 0, longitude: b.longitude ?? 0}, currentPosition)
    )
  })

  return sortedVenues[0]
}

export const getCartItemWithoutIdAndCount = (cartItem?: CartItem) => {
  if (!cartItem) {
    return
  }

  const {id, count, ...cartItemWithoutIdAndCount} = cartItem

  return cartItemWithoutIdAndCount
}

export const findDuplicatedCartItem = (cartItems?: {[id: string]: CartItem}, cartItem?: CartItem) => {
  if (!cartItems || !cartItem) {
    return
  }

  const cartItemWithoutIdAndCount = getCartItemWithoutIdAndCount(cartItem)

  return Object.values(cartItems).find((item) => {
    const itemWithoutIdAndCount = getCartItemWithoutIdAndCount(item)

    return (
      itemWithoutIdAndCount?.product.id === cartItemWithoutIdAndCount?.product.id &&
      _.isEqual(itemWithoutIdAndCount, cartItemWithoutIdAndCount)
    )
  })
}

export const loadCustomFont = (fontFamily: CustomFontFamilyKeys) => {
  return new Promise<FontEvent>((resolve, reject) => {
    WebFont.load({
      custom: {
        families: [fontFamily],
        urls: [fonts],
      },
      fontactive: (familyName, fvd) => {
        resolve({familyName, fvd})
      },
      fontinactive: (familyName, fvd) => {
        reject({familyName, fvd})
      },
    })
  })
}

export const loadGoogleFont = (fontFamily: string) => {
  return new Promise<FontEvent>((resolve, reject) => {
    WebFont.load({
      google: {
        families: [fontFamily],
      },
      fontactive: (familyName, fvd) => {
        resolve({familyName, fvd})
      },
      fontinactive: (familyName, fvd) => {
        reject({familyName, fvd})
      },
    })
  })
}

export const loadFont = async (fontFamily: string) => {
  try {
    if (CustomFontFamilies[fontFamily as CustomFontFamilyKeys]) {
      const {familyName} = await loadCustomFont(fontFamily as CustomFontFamilyKeys)

      return familyName
    }

    const {familyName} = await loadGoogleFont(fontFamily)

    return familyName
  } catch {
    console.error(`Font "${fontFamily}" can't be loaded.`)
  }
}

export const getWeekDay = (dayOfWeek?: DayOfWeek) => {
  switch (dayOfWeek) {
    case DayOfWeek.Monday:
      return 'I'
    case DayOfWeek.Tuesday:
      return 'II'
    case DayOfWeek.Wednesday:
      return 'III'
    case DayOfWeek.Thursday:
      return 'IV'
    case DayOfWeek.Friday:
      return 'V'
    case DayOfWeek.Saturday:
      return 'VI'
    case DayOfWeek.Sunday:
      return 'VII'
    default:
      return
  }
}

export const getHost = (): string => {
  return IS_STAGING ? store.getState().app.testGroupHost || window.location.origin : window.location.origin
}

export const hasSubdomains = (): boolean => {
  if (IS_STAGING) {
    return !!store.getState().app.testGroupDomain && !!store.getState().app.testGroupSubdomens
  }

  return !!process.env.VITE_APP_DOMAIN && !!process.env.VITE_APP_SUBDOMAINS
}

const getSubdomainHosts = (): Record<string, string> => {
  if (!hasSubdomains()) {
    return {}
  }

  const subdomains = IS_STAGING ? store.getState().app.testGroupSubdomens : process.env.VITE_APP_SUBDOMAINS
  const domain = IS_STAGING ? store.getState().app.testGroupDomain : process.env.VITE_APP_DOMAIN

  return subdomains.split(',').reduce<Record<string, string>>((obj, subdomain) => {
    const host = `https://${subdomain}.${domain}`
    obj[host] = host
    return obj
  }, {})
}

export const isSubdomainHost = (host: string): boolean => {
  return !!getSubdomainHosts()[host]
}

export const getMainHost = (): string => {
  if (!hasSubdomains()) {
    return getHost()
  }

  const domain = IS_STAGING ? store.getState().app.testGroupDomain : process.env.VITE_APP_DOMAIN

  return `https://${domain}`
}

export const getGroupHost = (): string => {
  const host = getHost()

  if (!hasSubdomains()) {
    return host
  }

  if (isSubdomainHost(host)) {
    return getMainHost()
  }

  return host
}

export const getVenueUrlData = (
  venue?: QrVenueContract,
): {url: string; tabletId: string; domain: string} | undefined => {
  const tabletId = venue?.tabletId
  if (!tabletId) {
    return
  }

  const domain = venue.domain || getMainHost()

  if (IS_STAGING) {
    return {url: `${window.location.origin}/${venue.tabletId}`, tabletId, domain}
  }

  return {url: `${domain}/${venue.tabletId}`, tabletId, domain}
}

export const navigateToExternalUrl = (url?: string) => {
  if (!url) {
    return
  }

  window.location.href = url
}

export const downloadFile = (url: string, name: string) => {
  const link = document.createElement('a')
  link.href = url
  link.download = name
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const getServiceFeeTitle = () => {
  const user = store.getState().profile.user

  let serviceFeeTitle = t('common.serviceFeeTitle')

  switch (user?.serviceFeeType) {
    case ServiceFeeType.Percentage:
      serviceFeeTitle += ` (${getRoundedNumber(user?.serviceFee ?? 0)}%):`
      break
    default:
      serviceFeeTitle += ':'
      break
  }

  return serviceFeeTitle
}
