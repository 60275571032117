import {createModel} from '@rematch/core'
import {AxiosResponse} from 'axios'

import {RootModel} from '.'
import api from 'src/utilities/api'
import {TranslationContract} from 'src/types/api'

interface TimeSlotsState {
  translations: TranslationContract[]
}

const initialState: TimeSlotsState = {
  translations: [],
}

export const translations = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setTranslations(state, translations: TranslationContract[]) {
      state.translations = translations
    },
  },
  effects: (dispatch) => ({
    async fetchTranslations() {
      const {data}: AxiosResponse<TranslationContract[]> = await api.get('qr/translations')

      dispatch.translations.setTranslations(data)

      return data
    },
  }),
})
