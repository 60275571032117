import {memo, MouseEventHandler, useCallback, CSSProperties, ButtonHTMLAttributes} from 'react'
import styled from 'styled-components'

import ArrowBackIcon from 'src/assets/icons/arrow-back.svg?react'
import MenuIcon from 'src/assets/icons/menu.svg?react'
import MenuCloseIcon from 'src/assets/icons/menu-close.svg?react'
import SearchIcon from 'src/assets/icons/search.svg?react'
import ChatIcon from 'src/assets/icons/chat.svg?react'
import ClipboardIcon from 'src/assets/icons/clipboard.svg?react'
import LocationIcon from 'src/assets/icons/location.svg?react'
import ChevronDownIcon from 'src/assets/icons/chevron-down.svg?react'
import ChevronLeftIcon from 'src/assets/icons/chevron-left.svg?react'
import ChevronRightIcon from 'src/assets/icons/chevron-right.svg?react'
import {useTheme} from 'src/utilities/theme'

const ICONS = {
  'arrow-back': ArrowBackIcon,
  menu: MenuIcon,
  'menu-close': MenuCloseIcon,
  search: SearchIcon,
  chat: ChatIcon,
  clipboard: ClipboardIcon,
  location: LocationIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-right': ChevronRightIcon,
}

enum Variant {
  white = 'white',
  'white-alternative' = 'white-alternative',
  yellow = 'yellow',
  gray = 'gray',
}

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string
  name: keyof typeof ICONS
  variant?: keyof typeof Variant
  disabled?: boolean
  hideShadow?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Container = styled.button.attrs(({style}) => ({
  style: {...style},
}))`
  border: none;
  cursor: pointer;
  width: ${({theme}) => theme.sizes.iconButtonSize}px;
  height: ${({theme}) => theme.sizes.iconButtonSize}px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.firstItemColor};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`

const IconButton = (props: IconButtonProps) => {
  const {theme} = useTheme()

  const getStyle = useCallback((): CSSProperties => {
    switch (props.variant) {
      case Variant.white:
        return {
          backgroundColor: theme.colors.baseItemColor,
          boxShadow: props.hideShadow
            ? 'none'
            : '0px 5px 14px rgba(61, 72, 135, 0.04), 0px 2px 5px rgba(61, 72, 135, 0.02)',
        }
      case Variant['white-alternative']:
        return {
          backgroundColor: theme.colors.baseItemColor,
          boxShadow: props.hideShadow
            ? 'none'
            : '0px 2px 3px 0px rgba(255, 186, 67, 0.16), 0px 3px 12px 0px rgba(255, 186, 67, 0.20)',
        }
      case Variant.gray:
        return {
          backgroundColor: theme.colors.secondItemColor,
          boxShadow: props.hideShadow
            ? 'none'
            : '0px 3px 12px rgba(218, 218, 218, 0.4), 0px 2px 3px rgba(218, 218, 218, 0.16)',
        }
      default:
        return {
          backgroundColor: theme.colors.firstItemColor,
          boxShadow: props.hideShadow ? 'none' : theme.colors.firstItemShadowColor,
        }
    }
  }, [
    props.variant,
    props.hideShadow,
    theme.colors.baseItemColor,
    theme.colors.secondItemColor,
    theme.colors.firstItemColor,
    theme.colors.firstItemShadowColor,
  ])

  const getIconColor = () => {
    switch (props.variant) {
      case Variant.gray:
        return theme.colors.primaryTextColor
      default:
        return theme.colors.primaryTextColor
    }
  }

  const Icon = ICONS[props.name]

  return (
    <Container type="button" key={props.name} style={getStyle()} {...props}>
      <Icon width="1.5rem" height="1.5rem" color={getIconColor()} />
    </Container>
  )
}

export default memo(IconButton)
