import {createModel} from '@rematch/core'
import {AxiosResponse} from 'axios'
import {createSelector} from 'reselect'

import {RootModel} from '.'
import api from 'src/utilities/api'
import {
  QrCatalogContract,
  OptionSetContract,
  ProductCategoryContract,
  ProductContract,
  ProductType,
  TagContract,
} from 'src/types/api'
import {RootState} from 'src/utilities/store'

export interface OptionSetContractObj extends Omit<OptionSetContract, 'options'> {
  options: {
    [optionId: string]: ProductContract
  }
}

export interface ProductContractObj extends Omit<ProductContract, 'optionSets'> {
  optionSets: {
    [optionSetId: string]: OptionSetContractObj
  }
}

interface CatalogState {
  catalog: QrCatalogContract | null
}

const initialState: CatalogState = {
  catalog: null,
}

export const catalog = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setCatalog(state, catalog: QrCatalogContract) {
      state.catalog = catalog
    },
    updateProduct(state, updatedProduct: ProductContract) {
      state.catalog = {
        ...state.catalog,
        products: state.catalog?.products?.map((product) =>
          product.id === updatedProduct.id ? updatedProduct : product,
        ),
      }
    },
  },
  effects: (dispatch) => ({
    async fetchCatalog() {
      const response: AxiosResponse<QrCatalogContract> = await api.get('/qr/catalog')
      dispatch.catalog.setCatalog(response.data)
    },
  }),
})

export const selectHasMasterCategories = createSelector(
  (rootState: RootState) => rootState.catalog,
  (state) => !!state.catalog?.masterCategories?.length,
)

export const selectCategories = createSelector(
  (rootState: RootState) => rootState.catalog,
  (_: RootState, masterCategoryId?: string) => masterCategoryId,
  (state, masterCategoryId) => {
    const categories = state.catalog?.categories ?? []

    if (!masterCategoryId) {
      return categories
    }

    return categories.filter((category) => category.masterCategoryId === masterCategoryId)
  },
)

export const selectCategoriesObj = createSelector(
  (rootState: RootState) => rootState.catalog,
  selectCategories,
  (_, categories) =>
    categories.reduce<{[id: string]: ProductCategoryContract}>((obj, category) => {
      obj[category.id!] = category

      return obj
    }, {}),
)

export const selectTagsObj = createSelector(
  (rootState: RootState) => rootState.catalog,
  (state) =>
    (state.catalog?.tags ?? []).reduce<{[id: string]: TagContract}>((obj, tag) => {
      obj[tag.id!] = tag

      return obj
    }, {}),
)

export const selectProductTags = createSelector(
  (rootState: RootState) => rootState.catalog,
  selectTagsObj,
  (_: RootState, productId?: string) => productId,
  (state, tagsObj, productId) => {
    if (!productId) {
      return []
    }

    const foundProduct = state.catalog?.products?.find((product) => product.id === productId)
    if (!foundProduct?.tagsIds?.length) {
      return []
    }

    return foundProduct.tagsIds.reduce<TagContract[]>((arr, tagId) => {
      const tag = tagsObj[tagId]
      if (tag) {
        arr.push(tag)
      }

      return arr
    }, [])
  },
)

export const selectSortedAndFilteredProducts = createSelector(
  (rootState: RootState) => rootState.catalog,
  selectCategoriesObj,
  (state, categoriesObj) => {
    const categories = state.catalog?.categories ?? []
    const products = state.catalog?.products ?? []

    const sortedProducts = categories
      .map((category) => products.filter((product) => product.categoryId === category.id))
      .flat()

    return sortedProducts.filter((sortedProduct) => categoriesObj[sortedProduct.categoryId!])
  },
)

export const selectProduct = createSelector(
  (rootState: RootState) => rootState.catalog,
  (_: RootState, productId: string) => productId,
  (state, productId) => state.catalog?.products?.find((product) => product.id === productId),
)

export const selectIsLoyaltyCardType = createSelector(
  (rootState: RootState) => rootState.catalog,
  selectProduct,
  () => {},
  (_, product) => product?.productType === ProductType.LoyaltyCard,
)

export const selectProductsObj = createSelector(
  (rootState: RootState) => rootState.catalog,
  selectSortedAndFilteredProducts,
  () => {},
  (_, sortedProducts) =>
    sortedProducts.reduce<{[productId: string]: ProductContractObj}>((productsObj, product) => {
      productsObj[product.id!] = {
        ...product,
        optionSets: product.optionSets!.reduce<{[optionSetId: string]: OptionSetContractObj}>(
          (optionSetsObj, optionSet) => {
            optionSetsObj[optionSet.id!] = {
              ...optionSet,
              options: optionSet.options!.reduce<{[optionId: string]: ProductContract}>((optionsObj, option) => {
                optionsObj[option.id!] = option
                return optionsObj
              }, {}),
            }
            return optionSetsObj
          },
          {},
        ),
      }
      return productsObj
    }, {}),
)
