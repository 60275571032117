import {forwardRef} from 'react'
import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentProps,
  OverlayScrollbarsComponentRef,
} from 'overlayscrollbars-react'
import styled, {AnyStyledComponent} from 'styled-components'

interface CustomScrollProps extends OverlayScrollbarsComponentProps {
  paddingX?: string
  paddingY?: string
}

const CustomScrollStyled = styled(OverlayScrollbarsComponent as unknown as AnyStyledComponent)`
  .os-theme-custom {
    --os-size: 0.5rem;
    --os-padding-perpendicular: ${({paddingX}) => paddingX};
    --os-padding-axis: ${({paddingY}) => paddingY};
    --os-track-border-radius: 6.25rem;
    --os-track-bg: ${({theme}) => theme.colors.baseItemColor};
    --os-track-bg-hover: ${({theme}) => theme.colors.baseItemColor};
    --os-track-bg-active: ${({theme}) => theme.colors.baseItemColor};
    --os-handle-border-radius: 6.25rem;
    --os-handle-bg: ${({theme}) => theme.colors.firstItemColor};
    --os-handle-bg-hover: ${({theme}) => theme.colors.firstItemColor};
    --os-handle-bg-active: ${({theme}) => theme.colors.firstItemColor};
  }
`

const CustomScroll = forwardRef<OverlayScrollbarsComponentRef, CustomScrollProps>((props, ref) => {
  return (
    <CustomScrollStyled
      ref={ref}
      defer
      options={{
        scrollbars: {
          autoHide: 'scroll',
          theme: 'os-theme-custom',
        },
      }}
      {...props}
    />
  )
})

export default CustomScroll
