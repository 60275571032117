import {ButtonHTMLAttributes} from 'react'
import styled from 'styled-components'

interface SelectButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean
  useHover?: boolean
}

const Button = styled.button<SelectButtonProps>`
  ${({theme}) => ({...theme.typography.modalOptions})}
  border: none;
  padding: 0;
  background-color: ${({theme, selected}) => (selected ? theme.colors.secondItemColor : theme.colors.baseItemColor)};
  width: 100%;
  height: 5.063rem;
  border-radius: 1.25rem;
  overflow: hidden;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  color: ${({theme}) => theme.colors.baseItemTextColor};
  display: block;

  :enabled {
    cursor: pointer;
  }

  ${({useHover, theme}) =>
    !!useHover &&
    `
    :hover:enabled {
      background-color: ${theme.colors.secondItemColor};
      color: ${theme.colors.primaryTextColor};
    }
  `}

  @media ${({theme}) => theme.queries.mobile} {
    ${({theme}) => ({...theme.typography.buttonText})}
    height: 2.813rem;
  }
`

const SelectButton = (props: SelectButtonProps) => {
  return <Button type="button" {...props} />
}

export default SelectButton
