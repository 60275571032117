import {PropsWithChildren, useState} from 'react'
import styled from 'styled-components'

import TestModal from './components/TestModal'

interface TestGroupHostContainerProps extends PropsWithChildren {}

const Container = styled.div`
  display: grid;
`

const TestGroupHostContainer = ({children}: TestGroupHostContainerProps) => {
  const [clickCount, setClickCount] = useState<number>(0)
  const [testModalOpen, setTestModalOpen] = useState<boolean>(false)

  const handleClick = () => {
    if (clickCount === 4) {
      setTestModalOpen(true)

      setClickCount(0)

      return
    }

    setClickCount((prevClickCount) => prevClickCount + 1)
  }

  return (
    <>
      <Container onClick={handleClick}>{children}</Container>

      <TestModal isOpen={testModalOpen} onClose={setTestModalOpen} />
    </>
  )
}

export default TestGroupHostContainer
