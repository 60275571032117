import {Currency} from 'src/types/api'

export const CURRENCY_SYMBOLS: {[key in Currency]: string} = {
  eur: '€',
  usd: '$',
  pln: 'zł',
  gbp: '£',
  try: '₺',
  dkk: 'kr',
} as const

export const TIPS_FIXED_AMOUNT_ID = 'fixed-amount' as const

export const TRANSLATIONS_KEYS = {
  'Timeslot.Info': 'Timeslot.Info',
  'Timeslot.InfoLower': 'Timeslot.InfoLower',
} as const

export const IS_STAGING = process.env.VITE_APP_ENV === 'staging'

export const FILE_SIZE = 'fileSize' as const

export const PHONE_NUMBER = 'phoneNumber' as const

export const MAX_UPLOAD_FILE_SIZE = 10000000 as const

export const GEOLOCATION_POSITION_ERROR_CODES = {
  GENERAL: 1,
  PERMISSION_DENIED: 2,
} as const
