import styled, {AnyStyledComponent} from 'styled-components'

import CheckboxIcon from 'src/assets/icons/checkbox.svg'
import CheckboxCheckedIcon from 'src/assets/icons/checkbox-checked.svg'
import RadioIcon from 'src/assets/icons/radio.svg?react'
import RadioCheckedIcon from 'src/assets/icons/radio-checked.svg?react'
import Text from './Text'

interface CheckboxItemProps {
  type?: 'checkbox' | 'radio'
  checked?: boolean
  label?: string
  onItemClick?: (checked: boolean) => void
}

const CheckboxItemContainer = styled.div`
  display: grid;
  justify-items: start;
`

const CheckboxItemContentContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.75rem;
  align-items: center;
  cursor: pointer;
`

const CheckboxContainer = styled.div`
  width: 1.375rem;
  height: 1.375rem;
  display: grid;
  place-content: center;
`

const Checkbox = styled.img`
  width: 3rem;
  height: 3rem;
`

const RadioContainer = styled.div`
  width: 1.375rem;
`

const RadioIconStyled = styled(RadioIcon as AnyStyledComponent)`
  width: auto;
  height: 1.375rem;
  color: ${({theme}) => theme.colors.secondaryTextColor};
`

const RadioCheckedIconStyled = styled(RadioCheckedIcon as AnyStyledComponent)`
  width: auto;
  height: 1.375rem;
  color: ${({theme}) => theme.colors.tertiaryTextColor};
`

const CheckboxItem = ({type = 'checkbox', checked, label, onItemClick}: CheckboxItemProps) => {
  const handleClick = () => {
    onItemClick?.(!checked)
  }

  const renderItem = () => {
    switch (type) {
      case 'checkbox':
        return (
          <CheckboxContainer>
            <Checkbox src={checked ? CheckboxCheckedIcon : CheckboxIcon} alt="checkbox" />
          </CheckboxContainer>
        )
      case 'radio':
        return <RadioContainer>{checked ? <RadioCheckedIconStyled /> : <RadioIconStyled />}</RadioContainer>
      default:
        return null
    }
  }

  return (
    <CheckboxItemContainer>
      <CheckboxItemContentContainer onClick={handleClick}>
        {renderItem()}

        {!!label && (
          <Text type="menuItems" color="baseItemTextColor">
            {label}
          </Text>
        )}
      </CheckboxItemContentContainer>
    </CheckboxItemContainer>
  )
}

export default CheckboxItem
