export enum CustomFontFamilies {
  Suti = 'Suti',
}

export type CustomFontFamilyKeys = keyof typeof CustomFontFamilies

export interface FontEvent {
  familyName: string
  fvd: string
}
