import {useEffect} from 'react'

import PageLoader from 'src/components/PageLoader'

interface RedirectProps {
  url: string
}

const Redirect = ({url}: RedirectProps) => {
  useEffect(() => {
    window.location.href = url
  }, [url])

  return <PageLoader />
}

export default Redirect
