import {useSelector} from 'react-redux'
import {useForm, UseFormGetValues, UseFormHandleSubmit} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {forwardRef, useImperativeHandle} from 'react'
import styled from 'styled-components'

import {SelectOption} from 'src/pages/DynamicPage/components/Select'
import LocationImg from 'src/assets/images/location.png'
import {RootState} from 'src/utilities/store'
import FormInput from 'src/pages/DynamicPage/components/FormInput'
import Error from 'src/pages/DynamicPage/components/Error'

const schema: yup.ObjectSchema<OrderFormValues> = yup.object({
  orderNumber: yup.string().required(),
  restaurant: yup
    .object({
      value: yup.string().required(),
      title: yup.string().required(),
    })
    .nullable()
    .required(),
})

const DEFAULT_ORDER_FORM_VALUES: OrderFormValues = {
  orderNumber: '',
  restaurant: null,
}

export interface OrderFormRef {
  submit: UseFormHandleSubmit<OrderFormValues, OrderFormValues>
  getValues: UseFormGetValues<OrderFormValues>
}

export interface OrderFormValues {
  orderNumber: string
  restaurant: SelectOption | null
}

interface OrderFormProps {
  loading?: boolean
  onValueChange?: () => void
}

const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;

  @media ${({theme}) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const OrderForm = forwardRef<OrderFormRef, OrderFormProps>(({loading, onValueChange}, ref) => {
  const venues = useSelector((state: RootState) => state.group.group?.venues)

  const {t} = useTranslation()

  const {
    handleSubmit,
    trigger,
    getValues,
    control,
    formState: {errors},
  } = useForm<OrderFormValues>({
    defaultValues: DEFAULT_ORDER_FORM_VALUES,
    resolver: yupResolver(schema),
  })

  const handleRestaurantValueChange = () => {
    trigger('restaurant')

    onValueChange?.()
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        submit: handleSubmit,
        getValues,
      }
    },
    [getValues, handleSubmit],
  )

  return (
    <>
      <InputsContainer>
        <FormInput
          type="text"
          name="orderNumber"
          placeholder={t('pages.dynamicPage.components.invoiceForm.orderNumberPlaceholder')}
          control={control}
          disabled={loading}
          onValueChange={onValueChange}
        />

        <FormInput
          type="select"
          name="restaurant"
          placeholder={t('pages.dynamicPage.components.invoiceForm.restaurantPlaceholder')}
          control={control}
          icon={LocationImg}
          options={(venues ?? []).map((venue) => ({
            title: venue.restaurantDisplayTitle ?? '',
            value: venue.id ?? '',
          }))}
          disabled={loading}
          onValueChange={handleRestaurantValueChange}
        />
      </InputsContainer>

      {!!Object.keys(errors).length && <Error msg={t('pages.dynamicPage.components.invoiceForm.errorMsg')} />}
    </>
  )
})

export default OrderForm
