import {ReactNode, useMemo} from 'react'
import {useSelector} from 'react-redux'

import MobileFooter from './components/MobileFooter'
import DesktopFooter from './components/DesktopFooter'
import {useMediaQuery} from 'src/utilities/hooks'
import {selectIsCartEmpty} from 'src/models/cart'

interface FooterProps {
  autoFocus?: boolean
  cartSum?: number
  cartItemsCount?: number
  count?: number
  hideLeftContent?: boolean
  intercomEnabled?: boolean
  CartContentComponent?: ReactNode
  searchValue?: string
  showMobileVersion?: boolean
  showOrderHistory?: boolean
  hideBrandLogo?: boolean
  addDisabled?: boolean
  cartButtonDisabled?: boolean
  hideSearch?: boolean
  onCounterAdd?: () => void
  onCounterRemove?: () => void
  onCartClick?: () => void
  onSearchClick?: () => void
  onHistoryClick?: () => void
  onSearchValueChange?: (searchValue: string) => void
  onSearchFocus?: () => void
}

const Footer = ({
  autoFocus,
  cartSum,
  cartItemsCount,
  count,
  hideLeftContent,
  intercomEnabled,
  CartContentComponent,
  searchValue,
  showMobileVersion,
  showOrderHistory,
  hideBrandLogo,
  addDisabled,
  cartButtonDisabled,
  hideSearch,
  onCounterAdd,
  onCounterRemove,
  onCartClick,
  onSearchClick,
  onHistoryClick,
  onSearchFocus,
  onSearchValueChange,
}: FooterProps) => {
  const isCartEmpty = useSelector(selectIsCartEmpty)

  const {isMobile} = useMediaQuery()

  const showZeroCartSum = useMemo(() => !isCartEmpty && cartSum === 0, [cartSum, isCartEmpty])

  if (isMobile || showMobileVersion) {
    return (
      <MobileFooter
        cartSum={cartSum}
        showZeroCartSum={showZeroCartSum}
        count={count}
        hideLeftContent={hideLeftContent}
        intercomEnabled={intercomEnabled}
        showOrderHistory={showOrderHistory}
        hideBrandLogo={hideBrandLogo}
        addDisabled={addDisabled}
        cartButtonDisabled={cartButtonDisabled}
        CartContentComponent={CartContentComponent}
        hideSearch={hideSearch}
        onCounterAdd={onCounterAdd}
        onCounterRemove={onCounterRemove}
        onCartClick={onCartClick}
        onSearchClick={onSearchClick}
        onHistoryClick={onHistoryClick}
      />
    )
  }

  return (
    <DesktopFooter
      autoFocus={autoFocus}
      searchValue={searchValue}
      cartSum={cartSum}
      showZeroCartSum={showZeroCartSum}
      cartItemsCount={cartItemsCount}
      showOrderHistory={showOrderHistory}
      intercomEnabled={intercomEnabled}
      hideSearch={hideSearch}
      onCartClick={onCartClick}
      onSearchFocus={onSearchFocus}
      onSearchValueChange={onSearchValueChange}
      onHistoryClick={onHistoryClick}
    />
  )
}

export default Footer
