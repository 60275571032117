import {useTranslation} from 'react-i18next'

import ErrorModal from 'src/components/ErrorModal'

interface ExpiredSessionModalProps {
  isOpen?: boolean
  onClose?: () => void
}

const ExpiredSessionModal = ({isOpen, onClose}: ExpiredSessionModalProps) => {
  const {t} = useTranslation()

  return (
    <ErrorModal
      title={t('pages.checkout.components.expiredSessionModal.title')}
      errorMsg={isOpen ? t('pages.checkout.components.expiredSessionModal.msg') : ''}
      buttonText={t('pages.checkout.components.expiredSessionModal.buttonText')}
      onBackClick={onClose}
      onRetryClick={onClose}
    />
  )
}

export default ExpiredSessionModal
