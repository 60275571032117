import styled from 'styled-components'

import FacebookIcon from 'src/assets/icons/facebook.svg'
import InstagramIcon from 'src/assets/icons/instagram.svg'
import LinkedinIcon from 'src/assets/icons/linkedin.svg'
import XIcon from 'src/assets/icons/x.svg'

const ICONS: {[key in Social]: string} = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedinIcon,
  x: XIcon,
}

export interface Icon {
  url: string
  social: Social
}

interface SocialIconsProps {
  icons?: Icon[]
}

type Social = 'facebook' | 'instagram' | 'linkedin' | 'x'

interface IconsContainerProps {
  length?: number
}

const IconsContainer = styled.div<IconsContainerProps>`
  display: grid;
  grid-template-columns: ${({length = 0}) => `repeat(${length}, auto)`};
  justify-content: start;
  column-gap: 1.625rem;

  @media ${({theme}) => theme.queries.mobile} {
    column-gap: 1.25rem;
  }
`

const IconContainer = styled.a`
  display: block;
  line-height: 0;
  width: 2.5rem;
  height: 2.5rem;

  @media ${({theme}) => theme.queries.mobile} {
    width: 1.875rem;
    height: 1.875rem;
  }
`

const Icon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;

  @media ${({theme}) => theme.queries.mobile} {
    width: 1.875rem;
    height: 1.875rem;
  }
`

const SocialIcons = ({icons}: SocialIconsProps) => {
  const iconsLength = icons?.length

  if (!iconsLength) {
    return
  }

  return (
    <IconsContainer length={iconsLength}>
      {icons.map((icon, index) => (
        <IconContainer key={index} href={icon.url} target="_blank">
          <Icon src={ICONS[icon.social]} alt={icon.social} />
        </IconContainer>
      ))}
    </IconsContainer>
  )
}

export default SocialIcons
