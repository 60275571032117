import {memo, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import {RootState} from 'src/utilities/store'
import {mediaUrl} from 'src/utilities/functions'
import {sizes} from 'src/utilities/theme'

interface MainContainerProps {
  expanded: boolean
}

interface LanguagesContainerProps {
  length: number
  expanded?: boolean
}

interface LanguageContainerProps {
  selected: boolean
}

const MainContainer = styled.div<MainContainerProps>`
  width: ${({theme}) => theme.sizes.iconButtonSize}px;
  height: ${({theme}) => theme.sizes.iconButtonSize}px;
  overflow: ${({expanded}) => (expanded ? 'visible' : 'hidden')};
  position: relative;
`

const LanguagesContainer = styled.div<LanguagesContainerProps>`
  display: grid;
  grid-template-columns: repeat(${({length}) => length}, auto);
  column-gap: 0.625rem;
  background-color: ${({theme}) => theme.colors.baseItemColor};
  border-radius: 6.25rem;
  overflow: hidden;
  position: ${({expanded}) => (expanded ? 'absolute' : 'static')};
  top: 0;
  left: 0;
  z-index: 1;

  @media ${({theme}) => theme.queries.mobile} {
    column-gap: 0.5rem;
  }
`

const LanguageContainer = styled.div<LanguageContainerProps>`
  width: ${({theme}) => theme.sizes.iconButtonSize}px;
  height: ${({theme}) => theme.sizes.iconButtonSize}px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 50%;

  ${({selected}) => selected && `order: -1;`}
`

const LanguageImg = styled.img`
  width: 100%;
  height: 100%;
`

const Language = () => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const user = useSelector((state: RootState) => state.profile.user)

  const {i18n} = useTranslation()

  const toggleExpanded = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded)
  }, [])

  const handleLanguageClick = useCallback(
    (languageCode: string) => {
      if (languageCode === i18n.language) {
        return
      }

      i18n.changeLanguage(languageCode)
    },
    [i18n],
  )

  return (
    <MainContainer expanded={expanded} onClick={toggleExpanded}>
      <LanguagesContainer length={user?.languages?.length ?? 0} expanded={expanded}>
        {user?.languages?.map((language) => (
          <LanguageContainer
            key={language.code}
            selected={language.code === i18n.language}
            onClick={() => handleLanguageClick(language.code!)}
          >
            <LanguageImg
              src={mediaUrl(language.image, {w: sizes.iconButtonSize, h: sizes.iconButtonSize})}
              alt={language.code}
            />
          </LanguageContainer>
        ))}
      </LanguagesContainer>
    </MainContainer>
  )
}

export default memo(Language)
