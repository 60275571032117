import {ComponentPropsWithoutRef} from 'react'
import styled from 'styled-components'

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  buttonType?: 'primary' | 'secondary' | 'primary-regular'
}

const ButtonStyled = styled.button`
  ${({theme}) => ({...theme.typography.buttonText})}
  display: block;
  width: 100%;
  height: 3.125rem;
  padding: 0 0.75rem;
  box-sizing: border-box;
  color: ${({theme}) => theme.colors.primaryTextColor};
  border: none;
  border-radius: 2.125rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);

  :enabled {
    cursor: pointer;
  }

  @media ${({theme}) => theme.queries.mobile} {
    font-size: 1rem;
  }
`

const PrimaryButton = styled(ButtonStyled)`
  background-color: ${({theme}) => theme.colors.firstItemColor};
  font-weight: 700;
  color: ${({theme}) => theme.colors.primaryTextColor};
`

const PrimaryRegularButton = styled(PrimaryButton)`
  font-weight: 400;
`

const SecondaryButton = styled(ButtonStyled)`
  background-color: ${({theme}) => theme.colors.secondItemColor};
  color: ${({theme}) => theme.colors.primaryTextColor};
`

const Button = ({buttonType = 'primary', ...props}: ButtonProps) => {
  switch (buttonType) {
    case 'primary':
      return <PrimaryButton type="button" {...props} />
    case 'secondary':
      return <SecondaryButton type="button" {...props} />
    case 'primary-regular':
      return <PrimaryRegularButton type="button" {...props} />
    default:
      return null
  }
}

export default Button
