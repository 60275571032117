import {ButtonHTMLAttributes} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import Text from 'src/components/Text'
import LocationIcon from 'src/assets/icons/location.svg?react'

interface RestaurantButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Button = styled.button`
  padding: 0 1rem 0 0.75rem;
  border: none;
  height: 2.625rem;
  background-color: ${({theme}) => theme.colors.secondItemColor};
  border-radius: 6.25rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 0.75rem;

  :enabled {
    cursor: pointer;
  }
`

const LocationIconStyled = styled(LocationIcon as unknown as AnyStyledComponent)`
  height: 1.625rem;
  width: auto;
  color: ${({theme}) => theme.colors.primaryTextColor};
`

const RestaurantButton = ({children, ...props}: RestaurantButtonProps) => {
  return (
    <Button {...props}>
      <LocationIconStyled />

      <Text type="menuItems" color="primaryTextColor">
        {children}
      </Text>
    </Button>
  )
}

export default RestaurantButton
