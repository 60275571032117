import {ChangeEvent, FormEvent, HTMLInputTypeAttribute, memo, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import IconButton from './IconButton'
import PrimaryButton from './PrimaryButton'
import Text from './Text'

interface AddInfoCardProps {
  title: string
  disabled?: boolean
  value?: string
  placeholder?: string
  autoFocus?: boolean
  inputType?: HTMLInputTypeAttribute
  required?: boolean
  onDoneClick?: (value: string) => void
  onBackClick?: () => void
}

const MainContainer = styled.form`
  background-color: ${({theme}) => theme.colors.backgroundColor};
  border-radius: 1.25rem;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
`

const TopContainer = styled.div`
  padding: 1rem;
`

const BottomContainer = styled.div`
  padding: 1rem;
  box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.07);
  border-radius: 0 0 1.25rem 1.25rem;
  display: flex;
  justify-content: flex-end;
  background-color: ${({theme}) => theme.colors.baseItemColor};
`

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
  align-items: center;
  padding-bottom: 1rem;
`

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid rgba(60, 60, 67, 0.29);
  border-radius: 0.563rem;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-family: ${({theme}) => theme.fontFamilies.primary};
  color: ${({theme}) => theme.colors.baseItemTextColor};
  font-size: 1.25rem;
  background-color: ${({theme}) => theme.colors.baseItemColor};

  ::placeholder {
    color: ${({theme}) => theme.colors.placeholderTextColor};
    opacity: 0.7;
  }
`

const AddInfoCard = (props: AddInfoCardProps) => {
  const [value, setValue] = useState<string>('')

  const {t} = useTranslation()

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      props.onDoneClick?.(value)
    },
    [props, value],
  )

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  useEffect(() => {
    if (!props.value) {
      return
    }
    setValue(props.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainContainer onSubmit={handleSubmit}>
      <TopContainer>
        <HeaderContainer>
          <Text type="checkoutPageTitle" color="baseItemTextColor">
            {props.title}
          </Text>
          <IconButton name="menu-close" disabled={props.disabled} onClick={props.onBackClick} />
        </HeaderContainer>
        <Input
          value={value}
          placeholder={props.placeholder}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          type={props.inputType}
          required={props.required}
          onChange={handleChange}
        />
      </TopContainer>
      <BottomContainer>
        <PrimaryButton type="submit" width="6.5rem" disabled={props.disabled}>
          {t('common.doneButtonText')}
        </PrimaryButton>
      </BottomContainer>
    </MainContainer>
  )
}

export default memo(AddInfoCard)
