import {ComponentPropsWithRef, forwardRef} from 'react'
import {allowOnlyIntegers} from 'src/utilities/functions'
import {Color} from 'src/utilities/theme'
import styled from 'styled-components'

interface InputProps extends ComponentPropsWithRef<'input'> {
  error?: boolean
  icon?: string
  onlyIntegers?: boolean
  bgColor?: Color
  textColor?: Color
}

interface InputStyledProps {
  error?: boolean
  hasIcon?: boolean
  bgColor: Color
  textColor: Color
}

const InputContainer = styled.div`
  height: 3.125rem;
  position: relative;
`

const InputStyled = styled.input<InputStyledProps>`
  ${({theme}) => ({...theme.typography.buttonText})}
  display: block;
  width: 100%;
  height: 100%;
  padding: ${({hasIcon}) => `0 ${hasIcon ? 3.125 : 0.75}rem 0 1.25rem`};
  box-sizing: border-box;
  background-color: ${({theme, bgColor}) => theme.colors[bgColor]};
  border-style: solid;
  border-width: 0.0625rem;
  border-color: ${({theme, error, bgColor}) => (error ? theme.colors.errorTextColor : theme.colors[bgColor])};
  box-shadow: 0 0 0.9375rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 2.125rem;
  outline: none;
  color: ${({theme, textColor}) => theme.colors[textColor]};

  ::placeholder {
    color: ${({theme}) => theme.colors.placeholderTextColor};
    opacity: 0.7;
  }

  @media ${({theme}) => theme.queries.mobile} {
    font-size: 1rem;
  }
`

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0.75rem;
  bottom: 0;
  display: grid;
  place-content: center;
`

const Icon = styled.img`
  width: 1.625rem;
  height: 1.625rem;
  object-fit: contain;
`

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({error, icon, onlyIntegers, bgColor = 'baseItemColor', textColor = 'baseItemTextColor', ...props}, ref) => {
    return (
      <InputContainer>
        <InputStyled
          ref={ref}
          error={error}
          hasIcon={!!icon}
          bgColor={bgColor}
          textColor={textColor}
          onKeyDown={onlyIntegers ? allowOnlyIntegers : undefined}
          {...props}
        />

        {!!icon && (
          <IconContainer>
            <Icon src={icon} alt={icon} />
          </IconContainer>
        )}
      </InputContainer>
    )
  },
)

export default Input
