import styled from 'styled-components'

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${({theme}) => theme.sizes.contentContainerWidth}px;
  margin: 0 auto;
  padding: 0 0.75rem;
  box-sizing: border-box;
`

export default ContentContainer
