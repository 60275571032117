import {ReactNode, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Navigate, useLocation, useNavigate, useParams} from 'react-router-dom'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet-async'

import {selectPage} from 'src/models/website'
import {CustomPageAccessType, CustomPageType} from 'src/types/api'
import {Dispatch, RootState} from 'src/utilities/store'
import Redirect from 'src/components/Redirect'
import ReviewForm, {ReviewFormValues} from './components/ReviewForm'
import InvoiceForm, {InvoiceFormData} from './components/InvoiceForm'
import ApplicationForm, {ApplicationFormValues} from './components/ApplicationForm'
import {dayjs} from 'src/utilities/dayjs'
import DynamicPageLayout from 'src/components/DynamicPageLayout'
import CustomHTML from 'src/components/CustomHTML'
import Map from './components/Map'
import {useTabletId} from 'src/utilities/hooks'
import ErrorModal from 'src/components/ErrorModal'
import {selectVenuesObj} from 'src/models/group'
import {downloadFile, getVenueUrlData, navigateToExternalUrl} from 'src/utilities/functions'
import {IS_STAGING} from 'src/utilities/constants'
import {OrderFormValues} from './components/InvoiceForm/components/OrderForm'

const FormContainer = styled.div`
  padding-top: 5.25rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding-top: 3.3125rem;
  }
`

const DynamicPage = () => {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false)
  const [showInvoiceFormSuccessMsg, setShowInvoiceFormSuccessMsg] = useState<boolean>(false)

  const params = useParams<{tabletId: string}>()
  const navigate = useNavigate()
  const location = useLocation()

  const group = useSelector((state: RootState) => state.group.group)

  const page = useSelector((state: RootState) => selectPage(state, params.tabletId))
  const venuesObj = useSelector(selectVenuesObj)

  const sendReviewLoading = useSelector((state: RootState) => state.loading.effects.website.sendReview)
  const sendApplicationLoading = useSelector((state: RootState) => state.loading.effects.website.sendApplication)

  const dispatch = useDispatch<Dispatch>()

  const {t} = useTranslation()

  const tabletId = useTabletId()

  const clearErrorMsg = () => {
    setErrorMsg('')
  }

  const navigateToSuccess = (description: string) => {
    navigate(`${location.pathname}/success`, {state: {description}})
  }

  const navigateToTablet = (tabletId: string) => {
    const venue = venuesObj[tabletId]
    if (!venue) {
      return
    }

    const venueUrlData = getVenueUrlData(venue)
    if (!venueUrlData) {
      return
    }

    if (IS_STAGING) {
      dispatch.app.setTestGroupHost(venueUrlData.domain)
    }

    navigateToExternalUrl(venueUrlData.url)
  }

  const handleReviewFormSubmit = async (data: ReviewFormValues) => {
    const groupId = group?.id
    if (!groupId) {
      return {error: true}
    }

    const formData = new FormData()
    formData.append('date', dayjs(data.date).format())
    formData.append('email', data.email)
    formData.append('name', data.fullName)
    formData.append('restaurant', String(data.restaurant?.title))
    formData.append('reviewText', data.comment)
    formData.append('orderNumber', data.orderNumber)
    data.files.forEach((file) => {
      formData.append('files', file)
    })

    try {
      await dispatch.website.sendReview({
        groupId,
        data: formData,
      })

      navigateToSuccess(t('pages.dynamicPage.reviewSuccessDescription'))
    } catch (error) {
      console.error(error)

      setErrorMsg(t('pages.dynamicPage.errorModalDescription'))

      return {error: true}
    }
  }

  const handleApplicationFormSubmit = async (data: ApplicationFormValues) => {
    const groupId = group?.id
    if (!groupId) {
      return {error: true}
    }

    const formData = new FormData()
    formData.append('applicationText', data.comment)
    formData.append('email', data.email)
    formData.append('name', data.fullName)
    formData.append('phoneNumber', data.phone)
    data.files.forEach((file) => {
      formData.append('files', file)
    })

    try {
      await dispatch.website.sendApplication({
        groupId,
        data: formData,
      })

      navigateToSuccess(t('pages.dynamicPage.applicationSuccessDescription'))
    } catch (error) {
      console.error(error)

      setErrorMsg(t('pages.dynamicPage.errorModalDescription'))

      return {error: true}
    }
  }

  const handleInvoiceFormCheck = async (data: OrderFormValues) => {
    try {
      setInvoiceLoading(true)

      const d = {...data}

      return await dispatch.invoice.checkInvoiceOrder({
        orderName: d.orderNumber,
        restaurantId: d.restaurant?.value ?? '',
      })
    } catch (error) {
      console.error(error)
    } finally {
      setInvoiceLoading(false)
    }
  }

  const handleInvoiceFormDownload = async (data: InvoiceFormData) => {
    try {
      setInvoiceLoading(true)

      const d = {...data}

      const url = await dispatch.invoice.getInvoiceDownloadUrl({
        orderName: d.orderNumber,
        restaurantId: d.restaurant?.value ?? '',
        email: d.email,
        companyInformation: {
          name: d.companyName,
          code: d.companyCode,
          vatCode: d.companyVatCode,
          address: d.companyAddress,
        },
      })

      downloadFile(url, d.orderNumber)
    } catch (error) {
      console.error(error)

      setErrorMsg(t('pages.dynamicPage.errorModalDescription'))
    } finally {
      setInvoiceLoading(false)
    }
  }

  const handleInvoiceFormSend = async (data: InvoiceFormData) => {
    try {
      setInvoiceLoading(true)

      const d = {...data}

      await dispatch.invoice.sendInvoiceToEmail({
        orderName: d.orderNumber,
        restaurantId: d.restaurant?.value ?? '',
        email: d.email,
        companyInformation: {
          name: d.companyName,
          code: d.companyCode,
          vatCode: d.companyVatCode,
          address: d.companyAddress,
        },
      })

      setShowInvoiceFormSuccessMsg(true)
    } catch (error) {
      console.error(error)

      setErrorMsg(t('pages.dynamicPage.errorModalDescription'))
    } finally {
      setInvoiceLoading(false)
    }
  }

  useEffect(() => {
    window.scroll({top: 0})
  }, [page])

  const renderPageLayout = (children?: ReactNode, addErrorModal?: boolean) => {
    return (
      <>
        <Helmet>
          {!!page?.metaDescription && (
            <>
              <meta property="og:description" content={page.metaDescription} />
              <meta name="description" content={page.metaDescription} />
            </>
          )}
          {!!page?.metaTitle && (
            <>
              <meta property="og:title" content={page.metaTitle} />
              <title>{page.metaTitle}</title>
            </>
          )}
        </Helmet>

        <DynamicPageLayout headerText={page?.headerText!} subHeaderText={page?.subHeaderText!}>
          {children}
        </DynamicPageLayout>

        {!!addErrorModal && (
          <ErrorModal
            buttonText={t('pages.dynamicPage.errorModalButtonText')}
            errorMsg={errorMsg}
            onBackClick={clearErrorMsg}
            onRetryClick={clearErrorMsg}
          />
        )}
      </>
    )
  }

  if (page?.accessType === CustomPageAccessType.Hidden) {
    return null
  }

  switch (page?.type) {
    case CustomPageType.Menu:
      return <Navigate to={`/${tabletId}`} replace />
    case CustomPageType.CustomHtml:
      return renderPageLayout(<CustomHTML html={page.html!} />)
    case CustomPageType.VenueList:
      return renderPageLayout(<Map tabletId={tabletId} onOrderInTabletClick={navigateToTablet} />)
    case CustomPageType.Redirect: {
      if (!page.redirectUrl) {
        return null
      }

      return <Redirect url={page.redirectUrl} />
    }
    case CustomPageType.ApplicationForm:
      return renderPageLayout(
        <FormContainer>
          <ApplicationForm loading={sendApplicationLoading} onSubmit={handleApplicationFormSubmit} />
        </FormContainer>,
        true,
      )
    case CustomPageType.ReviewForm:
      return renderPageLayout(
        <FormContainer>
          <ReviewForm loading={sendReviewLoading} onSubmit={handleReviewFormSubmit} />
        </FormContainer>,
        true,
      )
    case CustomPageType.InvoiceForm:
      return renderPageLayout(
        <FormContainer>
          <InvoiceForm
            loading={invoiceLoading}
            showSuccessMsg={showInvoiceFormSuccessMsg}
            onCheck={handleInvoiceFormCheck}
            onDownload={handleInvoiceFormDownload}
            onSend={handleInvoiceFormSend}
            setShowSuccessMsg={setShowInvoiceFormSuccessMsg}
          />
        </FormContainer>,
        true,
      )
    case CustomPageType.ClosedVenuePlaceholder:
      return renderPageLayout(<CustomHTML html={page.html!} />)
    default:
      return null
  }
}

export default DynamicPage
