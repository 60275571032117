import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import IconButton from 'src/components/IconButton'
import {ProductCategoryContract} from 'src/types/api'
import ListItem, {Item, LIST_ITEM_HEIGHT} from './components/ListItem'
import {mediaUrl} from 'src/utilities/functions'
import {sizes} from 'src/utilities/theme'
import {useElScrollTimeout} from 'src/utilities/hooks'

export const DESKTOP_CATEGORIES_HEIGHT = 82 as const
const LIST_GAP = 16 as const

enum RefNames {
  centerContainerRef = 'centerContainerRef',
}

interface DesktopCategoriesHorizontalListProps {
  categories?: ProductCategoryContract[]
  activeCategoryId?: string
  expanded?: boolean
  onCategoryClick?: (categoryId: string) => void
  onExpandClick?: () => void
}

export interface DesktopCategoriesHorizontalListRef {
  scrollToCategory: (categoryId: string) => void
}

interface ItemData {
  id: string
  position: number
}

interface CenterContainerProps {
  expanded?: boolean
}

const MainContainer = styled.div`
  height: ${DESKTOP_CATEGORIES_HEIGHT}px;
  position: relative;
`

const ContentContainer = styled.div`
  display: flex;
  column-gap: ${LIST_GAP / 2}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({theme}) => theme.colors.backgroundColor};
`

const LeftContainer = styled.div`
  padding: 0.5rem 0 1.5rem 0;
`

const ScrollContainer = styled.div<CenterContainerProps>`
  flex: 1;
  overflow: auto;
  display: flex;
  gap: ${LIST_GAP}px;
  padding: 0.5rem ${LIST_GAP / 2}px 1.5rem ${LIST_GAP / 2}px;
  flex-wrap: ${({expanded}) => (expanded ? 'wrap' : 'nowrap')};
  position: relative;
  max-height: calc(0.5rem + ((${LIST_ITEM_HEIGHT}px + ${LIST_GAP}px) * 5) + ${LIST_ITEM_HEIGHT / 2}px);
  box-sizing: border-box;

  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const RightContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  padding: 0.5rem 0 1.5rem 0;
`

const IconButtonStyled = styled(IconButton as unknown as AnyStyledComponent)`
  width: 3.125rem;
  height: 3.125rem;
`

const DesktopCategoriesHorizontalList = forwardRef<
  DesktopCategoriesHorizontalListRef,
  DesktopCategoriesHorizontalListProps
>(({categories, activeCategoryId, expanded, onCategoryClick, onExpandClick}, ref) => {
  const [showScrollButtons, setShowScrollButtons] = useState<boolean>(false)
  const [showScrollLeftButton, setShowScrollLeftButton] = useState<boolean>(false)
  const [showScrollRightButton, setShowScrollRightButton] = useState<boolean>(false)
  const [categoriesClientWidth, setCategoriesClientWidth] = useState<number>(0)

  const itemData = useRef<{[id: string]: ItemData}>({})
  const refs = useRef<{[key in RefNames]?: HTMLDivElement}>({})

  const categoryWidth = useMemo(() => {
    if (!categoriesClientWidth) {
      return
    }

    return (categoriesClientWidth - LIST_GAP * 5) / 5
  }, [categoriesClientWidth])

  const setItemData = (itemRef: HTMLDivElement | null) => {
    if (!itemRef) {
      return
    }

    const itemsClientWidth = itemRef.clientWidth - 174 // 174 is three arrow buttons and gaps
    const itemWidth = (itemsClientWidth - LIST_GAP * 5) / 5

    categories?.forEach((category, index) => {
      const id = category.id!
      const position = (itemWidth + LIST_GAP) * index

      itemData.current[id] = {id, position}
    })
  }

  const setRefs = (itemRef: HTMLDivElement | null, name: RefNames) => {
    if (!itemRef) {
      return
    }

    refs.current[name] = itemRef

    switch (name) {
      case RefNames.centerContainerRef:
        checkScrollButtonsVisibility(itemRef)
        break
      default:
        break
    }
  }

  const getSortedItems = (reverse?: boolean) => {
    const sortedItems = [...Object.values(itemData.current)].sort((a, b) => {
      if (reverse) {
        return b.position - a.position
      }

      return a.position - b.position
    })

    return sortedItems
  }

  const scrollTo = (position: number, behavior?: ScrollBehavior) => {
    const centerContainerRef = refs.current[RefNames.centerContainerRef]
    if (!centerContainerRef) {
      return
    }

    centerContainerRef.scroll({left: position, behavior: behavior ?? 'smooth'})
  }

  const scrollRight = (behavior?: ScrollBehavior) => {
    const centerContainerRef = refs.current[RefNames.centerContainerRef]
    if (!centerContainerRef) {
      return
    }

    const foundItem = getSortedItems().find((item) => item.position > centerContainerRef.scrollLeft + 1)
    if (!foundItem) {
      return
    }

    scrollTo(foundItem.position, behavior)
  }

  const scrollLeft = (behavior?: ScrollBehavior) => {
    const centerContainerRef = refs.current[RefNames.centerContainerRef]
    if (!centerContainerRef) {
      return
    }

    const foundItem = getSortedItems(true).find((item) => item.position < centerContainerRef.scrollLeft - 1)
    if (!foundItem) {
      return
    }

    scrollTo(foundItem.position, behavior)
  }

  const scrollToCategory = (categoryId: string) => {
    const item = itemData.current[categoryId]
    if (!item) {
      return
    }

    scrollTo(item.position)
  }

  const checkScrollButtonsVisibility = (itemRef?: HTMLDivElement) => {
    if (!itemRef) {
      return
    }

    const scrollLeft = itemRef.scrollLeft
    const scrollWidth = itemRef.scrollWidth
    const clientWidth = itemRef.clientWidth

    const showRightButton = scrollLeft + 1 + clientWidth < scrollWidth

    setShowScrollButtons(scrollWidth > clientWidth)

    setShowScrollLeftButton(scrollLeft > 0)

    setShowScrollRightButton(showRightButton)

    if (!showRightButton && !expanded) {
      return
    }

    setCategoriesClientWidth(clientWidth)
  }

  const renderItems = () => {
    return categories?.map((category) => {
      const item: Item = {
        id: category.id!,
        imgSrc: mediaUrl(category.imageName, {
          w: sizes.categoriesListItemImgSize,
          h: sizes.categoriesListItemImgSize,
        }),
        title: category.title ?? '',
      }

      return (
        <ListItem
          width={categoryWidth ? `${categoryWidth}px` : undefined}
          key={item.id}
          item={item}
          active={activeCategoryId === item.id}
          onItemClick={onCategoryClick}
        />
      )
    })
  }

  useImperativeHandle(
    ref,
    () => {
      return {scrollToCategory}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useElScrollTimeout(refs.current[RefNames.centerContainerRef], () => {
    checkScrollButtonsVisibility(refs.current[RefNames.centerContainerRef])
  })

  return (
    <MainContainer key={String(expanded)} ref={setItemData}>
      <ContentContainer>
        <LeftContainer>
          <IconButtonStyled name={expanded ? 'menu-close' : 'chevron-down'} onClick={onExpandClick} />
        </LeftContainer>

        <ScrollContainer ref={(itemRef) => setRefs(itemRef, RefNames.centerContainerRef)} expanded={expanded}>
          {renderItems()}
        </ScrollContainer>

        {showScrollButtons && !expanded && (
          <RightContainer>
            {showScrollLeftButton && (
              <IconButtonStyled name="chevron-left" variant="gray" onClick={() => scrollLeft()} />
            )}

            {showScrollRightButton && (
              <IconButtonStyled name="chevron-right" variant="gray" onClick={() => scrollRight()} />
            )}
          </RightContainer>
        )}
      </ContentContainer>
    </MainContainer>
  )
})

export default DesktopCategoriesHorizontalList
