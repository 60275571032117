import {ChangeEventHandler, memo} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import SearchIcon from 'src/assets/icons/search.svg?react'

interface SearchInputProps {
  value?: string | number | readonly string[]
  placeholder?: string
  autoFocus?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const InputContainer = styled.div`
  background-color: ${({theme}) => theme.colors.fifthItemColor};
  padding: 0.625rem;
  border-radius: 1.186rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.152453);
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.625rem;
  align-items: center;
`

const Input = styled.input`
  padding: 0;
  outline: none;
  border: none;
  font-family: ${({theme}) => theme.fontFamilies.primary};
  color: ${({theme}) => theme.colors.primaryTextColor};
  font-size: 1.125rem;
  background-color: transparent;

  ::placeholder {
    color: ${({theme}) => theme.colors.placeholderTextColor};
    opacity: 0.7;
  }
`

const SearchIconStyled = styled(SearchIcon as AnyStyledComponent)`
  width: 1.25rem;
  height: 1.25rem;
  color: ${({theme}) => theme.colors.primaryTextColor};
`

const SearchInput = (props: SearchInputProps) => {
  return (
    <InputContainer>
      <SearchIconStyled />
      <Input
        value={props.value}
        placeholder={props.placeholder}
        autoFocus={props.autoFocus}
        onChange={props.onChange}
      />
    </InputContainer>
  )
}

export default memo(SearchInput)
