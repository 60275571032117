import {createModel} from '@rematch/core'
import {AxiosResponse} from 'axios'
import {createSelector} from 'reselect'

import {RootModel} from '.'
import api from 'src/utilities/api'
import {CreateOrderContract, ReservedTimeSlotContract, TimeSlotContract} from 'src/types/api'
import {RootState} from 'src/utilities/store'

interface TimeSlotsState {
  timeSlots: TimeSlotContract[]
  selectedTimeSlot: TimeSlotContract | null
  reservedTimeSlot: ReservedTimeSlotContract | null
  soonAsPossible: boolean
}

const initialState: TimeSlotsState = {
  timeSlots: [],
  selectedTimeSlot: null,
  reservedTimeSlot: null,
  soonAsPossible: false,
}

export const timeSlots = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setTimeSlots(state, timeSlots: TimeSlotContract[]) {
      state.timeSlots = timeSlots
    },
    setSelectedTimeSlot(state, selectedTimeSlot: TimeSlotContract | null) {
      state.selectedTimeSlot = selectedTimeSlot
    },
    setReservedTimeSlot(state, reservedTimeSlot: ReservedTimeSlotContract | null) {
      state.reservedTimeSlot = reservedTimeSlot
    },
    setSoonAsPossible(state, soonAsPossible: boolean) {
      state.soonAsPossible = soonAsPossible
    },
    resetState(state) {
      state.timeSlots = initialState.timeSlots
      state.selectedTimeSlot = initialState.selectedTimeSlot
      state.reservedTimeSlot = initialState.reservedTimeSlot
      state.soonAsPossible = initialState.soonAsPossible
    },
  },
  effects: (dispatch) => ({
    async fetchTimeSlots() {
      const res: AxiosResponse<TimeSlotContract[]> = await api.get('/timeSlots/available')

      dispatch.timeSlots.setTimeSlots(res.data)
    },
    async reserveTimeSlot({id, payload}: {id: string; payload: CreateOrderContract}) {
      const res: AxiosResponse<ReservedTimeSlotContract> = await api.post(`/timeSlots/${id}/reserve`, payload)

      dispatch.timeSlots.setReservedTimeSlot(res.data)
    },
    async releaseTimeSlot(id: string) {
      await api.post(`/timeSlots/${id}/release`)
    },
  }),
})

export const selectTimeSlotsObj = createSelector(
  (rootState: RootState) => rootState.timeSlots,
  () => {},
  (state) =>
    state.timeSlots.reduce<{[id: string]: TimeSlotContract}>((obj, timeSlot) => {
      obj[timeSlot.id!] = timeSlot

      return obj
    }, {}),
)
