import styled, {AnyStyledComponent} from 'styled-components'

import LogoIcon from 'src/assets/icons/logo.svg?react'

interface BrandLogoProps {
  hidePrefix?: boolean
}

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`

const Prefix = styled.span`
  font-family: ${({theme}) => theme.fontFamilies.primary};
  font-size: 0.875rem;
  color: ${({theme}) => theme.colors.baseItemTextColor};
`

const LogoIconStyled = styled(LogoIcon as unknown as AnyStyledComponent)`
  width: 4.542rem;
  height: auto;
  color: ${({theme}) => theme.colors.baseItemTextColor};

  @media ${({theme}) => theme.queries.mobile} {
    width: 2.875rem;
  }
`

const BrandLogo = ({hidePrefix}: BrandLogoProps) => {
  return (
    <Container>
      {!hidePrefix && <Prefix>powered by</Prefix>}

      <LogoIconStyled />
    </Container>
  )
}

export default BrandLogo
