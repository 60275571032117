import styled from 'styled-components'
import {useState} from 'react'

import Text from 'src/components/Text'
import TipsButton from 'src/components/Tips/components/TipsButton'
import Dots from 'src/components/Tips/components/Dots'
import TipsAmountModal from 'src/components/Tips/components/TipsAmountModal'
import {useMediaQuery} from 'src/utilities/hooks'
import {TIPS_FIXED_AMOUNT_ID} from 'src/utilities/constants'

export interface TipsData {
  id: string
  value: number
}

interface TipsCardProps {
  title?: string
  icon?: string
  values?: number[]
  error?: boolean
  selectedValue?: TipsData
  onValueChange?: (data: TipsData) => void
}

const MainContainer = styled.div`
  @media ${({theme}) => theme.queries.mobile} {
    display: grid;
    row-gap: 1.125rem;
  }
`

const CardContainer = styled.div`
  background-color: ${({theme}) => theme.colors.baseItemColor};
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.043679);
  border-radius: 0.563rem;
  overflow: hidden;
  padding: 1rem 0.75rem 1.5rem 0;
  display: grid;
  grid-template-columns: auto 1fr;
  overflow-x: auto;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 1rem 0.75rem 1rem 0;
  }
`

const IconContainer = styled.div`
  width: 5rem;
  display: grid;
  justify-content: center;
  padding-top: 0.75rem;

  @media ${({theme}) => theme.queries.mobile} {
    width: 3.813rem;
    padding-top: 0;
    align-items: center;
  }
`

const Icon = styled.img.attrs({
  alt: 'icon',
})`
  width: auto;
  height: 2.188rem;

  @media ${({theme}) => theme.queries.mobile} {
    height: 1.813rem;
  }
`

const ContentContainer = styled.div`
  display: grid;
  row-gap: 1rem;
`

const ValuesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  column-gap: 0.75rem;
  justify-content: start;
`

const TipsCard = ({title, icon, values, error, selectedValue, onValueChange}: TipsCardProps) => {
  const [tipsAmountModalOpen, setTipsAmountModalOpen] = useState<boolean>(false)

  const {isMobile} = useMediaQuery()

  const toggleTipsAmountModal = () => {
    setTipsAmountModalOpen((prevTipsAmountModalOpen) => !prevTipsAmountModalOpen)
  }

  const handleTipsAmountModalDone = (amount: number) => {
    onValueChange?.({id: TIPS_FIXED_AMOUNT_ID, value: amount})

    toggleTipsAmountModal()
  }

  return (
    <>
      <MainContainer>
        {isMobile && (
          <Text type="productPageOptionName" color={error ? 'errorTextColor' : 'baseItemTextColor'}>
            {title}
          </Text>
        )}

        <CardContainer>
          <IconContainer>
            <Icon src={icon} />
          </IconContainer>

          <ContentContainer>
            {!isMobile && (
              <Text type="checkoutTitle" color={error ? 'errorTextColor' : 'baseItemTextColor'}>
                {title}
              </Text>
            )}

            <ValuesContainer>
              {values?.map((value, index) => {
                const id = `percentages-${index + 1}`

                return (
                  <TipsButton
                    key={id}
                    error={!!error && index === 0}
                    selected={selectedValue?.id === id}
                    onClick={() => onValueChange?.({id, value})}
                  >
                    {value}%
                  </TipsButton>
                )
              })}

              <TipsButton selected={selectedValue?.id === TIPS_FIXED_AMOUNT_ID} onClick={toggleTipsAmountModal}>
                <Dots />
              </TipsButton>
            </ValuesContainer>
          </ContentContainer>
        </CardContainer>
      </MainContainer>

      <TipsAmountModal
        isOpen={tipsAmountModalOpen}
        value={selectedValue?.id === TIPS_FIXED_AMOUNT_ID ? String(selectedValue.value) : ''}
        onDone={handleTipsAmountModalDone}
        onBack={toggleTipsAmountModal}
      />
    </>
  )
}

export default TipsCard
