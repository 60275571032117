import styled, {AnyStyledComponent} from 'styled-components'

import LocationIcon from 'src/assets/icons/location.svg?react'
import Text from 'src/components/Text'
import {useMediaQuery} from 'src/utilities/hooks'

interface LocationProps {
  locationTitle?: string
}

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 0.5rem;

  @media ${({theme}) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const LocationImg = styled(LocationIcon as unknown as AnyStyledComponent)`
  width: 1.625rem;
  height: 1.625rem;
  color: ${({theme}) => theme.colors.baseItemTextColor};
`

const Location = ({locationTitle}: LocationProps) => {
  const {isMobile} = useMediaQuery()

  return (
    <Container>
      {!isMobile && <LocationImg />}

      <Text type="checkoutPageOptionTitle" align={isMobile ? 'center' : 'start'} color="baseItemTextColor">
        {locationTitle}
      </Text>
    </Container>
  )
}

export default Location
