import styled from 'styled-components'

import RevolutImg from 'src/assets/images/revolut.png'
import SwedbankImg from 'src/assets/images/swedbank.png'
import SebIcon from 'src/assets/icons/seb.svg'

const MainContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  pointer-events: none;
`

const Revolut = styled.img.attrs({
  src: RevolutImg,
  alt: 'revolut',
})`
  height: 1.25rem;
  width: auto;
`

const Swedbank = styled.img.attrs({
  src: SwedbankImg,
  alt: 'swedbank',
})`
  height: 1.75rem;
  width: auto;
`

const Seb = styled.img.attrs({
  src: SebIcon,
  alt: 'seb',
})`
  height: 1.25rem;
  width: auto;
`

const BankIcons = () => {
  return (
    <MainContainer>
      <Revolut />

      <Seb />

      <Swedbank />
    </MainContainer>
  )
}

export default BankIcons
