import styled, {CSSProperties} from 'styled-components'
import {useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'

import {useMediaQuery} from 'src/utilities/hooks'
import {SelectOption} from '../Select'
import LocationImg from 'src/assets/images/location.png'
import CalendarImg from 'src/assets/images/calendar.png'
import {RootState} from 'src/utilities/store'
import FormInput from '../FormInput'
import Button from '../Button'
import Error from '../Error'
import Text from 'src/components/Text'
import FormFileUploadButton from '../FormFileUploadButton'
import FileItemsList from '../FileItemsList'
import {FILE_SIZE, MAX_UPLOAD_FILE_SIZE} from 'src/utilities/constants'
import {getErrorMsgs} from 'src/pages/DynamicPage/functions'

const schema: yup.ObjectSchema<ReviewFormValues> = yup.object({
  fullName: yup.string().required(),
  email: yup.string().email().required(),
  orderNumber: yup.string().required(),
  comment: yup.string().required(),
  date: yup.date().nullable().required(),
  restaurant: yup
    .object({
      value: yup.string().required(),
      title: yup.string().required(),
    })
    .nullable()
    .required(),
  agreeToPp: yup.boolean().oneOf([true]).required(),
  files: yup
    .array()
    .required()
    .test(FILE_SIZE, (files: File[]) => {
      return !files.some((file) => file.size > MAX_UPLOAD_FILE_SIZE)
    }),
})

const DEFAULT_REVIEW_FORM_VALUES: ReviewFormValues = {
  fullName: '',
  email: '',
  orderNumber: '',
  comment: '',
  date: null,
  restaurant: null,
  agreeToPp: false,
  files: [],
}

export interface ReviewFormValues {
  fullName: string
  email: string
  orderNumber: string
  comment: string
  date: Date | null
  restaurant: SelectOption | null
  agreeToPp: boolean
  files: File[]
}

interface ReviewFormProps {
  loading?: boolean
  onSubmit?: (data: ReviewFormValues) => Promise<{error: boolean} | undefined>
}

interface InputsTitleProps {
  align?: CSSProperties['textAlign']
}

const InputsContainer = styled.form`
  display: grid;
  row-gap: 1.25rem;
  max-width: 63.75rem;
  margin: 0 auto;
  width: 100%;

  @media ${({theme}) => theme.queries.mobile} {
    max-width: none;
  }
`

const InputsWithTitleMainContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;

  @media ${({theme}) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const InputsWithTitleContainer = styled.div`
  display: grid;
  row-gap: 1.25rem;
`

const InputsTitleContainer = styled.div`
  padding-bottom: 0.9375rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding-bottom: 0;
  }
`

const InputsTitle = styled.h2<InputsTitleProps>`
  font-family: ${({theme}) => theme.fontFamilies.primary};
  font-size: 2.1875rem;
  font-weight: 900;
  line-height: 2.1875rem;
  color: ${({theme}) => theme.colors.baseItemTextColor};
  text-align: ${({align = 'start'}) => align};

  @media ${({theme}) => theme.queries.mobile} {
    font-size: 1.25rem;
  }
`

const DateAndOrderInputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.25rem;
`

const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  gap: 1.25rem;

  @media ${({theme}) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 18.75rem);
  gap: 1.25rem;

  @media ${({theme}) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const PPLink = styled.a`
  color: inherit;
  font-weight: 700;
`

const InfoContainer = styled.div`
  padding-bottom: 1.25rem;
`

const Info = styled.p`
  ${({theme}) => ({...theme.typography.buttonText})}
  color: ${({theme}) => theme.colors.baseItemTextColor};
  opacity: 0.7;
  white-space: pre-wrap;

  @media ${({theme}) => theme.queries.mobile} {
    font-size: 1rem;
  }
`

const ReviewForm = ({loading, onSubmit}: ReviewFormProps) => {
  const venues = useSelector((state: RootState) => state.group.group?.venues)
  const websiteData = useSelector((state: RootState) => state.website.websiteData)

  const {isMobile} = useMediaQuery()

  const {t} = useTranslation()

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    control,
    formState: {errors, isSubmitted},
  } = useForm<ReviewFormValues>({
    defaultValues: DEFAULT_REVIEW_FORM_VALUES,
    resolver: yupResolver(schema),
  })

  const [files, restaurant, date] = watch(['files', 'restaurant', 'date'])

  const errorMsgs = getErrorMsgs(errors)

  const handleRemoveClick = (file: File) => {
    setValue(
      'files',
      files.filter((f) => f.name !== file.name),
    )
  }

  const handleFormSubmit = handleSubmit(async (data) => {
    const res = await onSubmit?.(data)
    if (res?.error) {
      return
    }

    reset(DEFAULT_REVIEW_FORM_VALUES)
  })

  useEffect(() => {
    if (!isSubmitted) {
      return
    }

    trigger(['files', 'restaurant', 'date'])
  }, [isSubmitted, trigger, files, restaurant, date])

  return (
    <InputsContainer onSubmit={handleFormSubmit}>
      <InfoContainer>
        <Info>
          {t('pages.dynamicPage.components.reviewForm.info').concat(
            t('pages.dynamicPage.components.reviewForm.infoAdditional'),
          )}
        </Info>
      </InfoContainer>

      <InputsWithTitleMainContainer>
        <InputsWithTitleContainer>
          <InputsTitleContainer>
            <InputsTitle>{t('pages.dynamicPage.components.reviewForm.contactsTitle')}</InputsTitle>
          </InputsTitleContainer>

          <FormInput
            type="text"
            name="fullName"
            placeholder={t('pages.dynamicPage.components.reviewForm.fullNamePlaceholder')}
            control={control}
            disabled={loading}
          />

          <FormInput
            type="email"
            name="email"
            placeholder={t('pages.dynamicPage.components.reviewForm.emailPlaceholder')}
            control={control}
            disabled={loading}
          />
        </InputsWithTitleContainer>

        <InputsWithTitleContainer>
          <InputsTitleContainer>
            <InputsTitle align={isMobile ? 'start' : 'end'}>
              {t('pages.dynamicPage.components.reviewForm.visitDetailsTitle')}
            </InputsTitle>
          </InputsTitleContainer>

          <FormInput
            type="select"
            name="restaurant"
            placeholder={t('pages.dynamicPage.components.reviewForm.restaurantPlaceholder')}
            control={control}
            icon={LocationImg}
            options={(venues ?? []).map((venue) => ({
              title: venue.restaurantDisplayTitle ?? '',
              value: venue.id ?? '',
            }))}
            disabled={loading}
          />

          <DateAndOrderInputsContainer>
            <FormInput
              type="date"
              name="date"
              placeholder={t('pages.dynamicPage.components.reviewForm.datePlaceholder')}
              control={control}
              icon={CalendarImg}
              disabled={loading}
            />

            <FormInput
              type="text"
              name="orderNumber"
              placeholder={t('pages.dynamicPage.components.reviewForm.orderNumberPlaceholder')}
              control={control}
              disabled={loading}
            />
          </DateAndOrderInputsContainer>
        </InputsWithTitleContainer>
      </InputsWithTitleMainContainer>

      <FormInput
        type="textarea"
        name="comment"
        placeholder={t('pages.dynamicPage.components.reviewForm.commentPlaceholder')}
        control={control}
        height={isMobile ? '23.75rem' : '18.125rem'}
        disabled={loading}
      />

      <FileItemsList files={files} showErrors={isSubmitted} loading={loading} onRemoveClick={handleRemoveClick} />

      <FooterContainer>
        <FormInput
          type="checkbox"
          name="agreeToPp"
          control={control}
          disabled={loading}
          checkboxLabel={
            <Text type={isMobile ? 'descriptionTextMobile' : 'buttonText'} color="baseItemTextColor">
              {t('pages.dynamicPage.components.reviewForm.agreeToPpLabel')}{' '}
              <PPLink
                href={websiteData?.privacyPolicyUrl ?? process.env.VITE_APP_PP_URL}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                {t('pages.dynamicPage.components.reviewForm.agreeToPpText')}
              </PPLink>
            </Text>
          }
        />

        <ButtonsContainer>
          <FormFileUploadButton control={control} name="files" disabled={loading}>
            {t('pages.dynamicPage.components.reviewForm.uploadButtonLabel')}
          </FormFileUploadButton>

          <Button type="submit" disabled={loading}>
            {t('pages.dynamicPage.components.reviewForm.sendButtonLabel')}
          </Button>
        </ButtonsContainer>
      </FooterContainer>

      {errorMsgs.map((errorMsg, index) => (
        <Error key={index} msg={errorMsg} />
      ))}
    </InputsContainer>
  )
}

export default ReviewForm
