import {memo, useCallback} from 'react'
import {useSelector} from 'react-redux'
import styled, {AnyStyledComponent} from 'styled-components'

import {CartItem, selectCartItemTotalPrice} from 'src/models/cart'
import {ProductContract} from 'src/types/api'
import {RootState} from 'src/utilities/store'
import Text from 'src/components/Text'
import {mediaUrl} from 'src/utilities/functions'
import ProductCounter from 'src/components/ProductCounter'
import ProductsListItemAddButton from './components/ProductsListItemAddButton'
import {selectIsLoyaltyCardType, selectProductTags} from 'src/models/catalog'
import {useMediaQuery} from 'src/utilities/hooks'
import {selectOnlyMenu, selectPriceWithCurrency} from 'src/models/profile'
import Allergens, {ALLERGEN_SMALL_HEIGHT} from 'src/components/Allergens'

interface ProductsListItemProps {
  cartItem?: CartItem
  product: ProductContract
  onAdd?: (product: ProductContract) => void
  onIncrease?: (id: string) => void
  onDecrease?: (id: string) => void
  onItemClick: (data: {productId: string; cartId?: string}) => void
}

interface ProductsListItemContainerProps {
  inCart: boolean
}

interface ProductsListItemDescriptionProps {
  isMobile?: boolean
}

const ProductsListItemContainer = styled.div<ProductsListItemContainerProps>`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.152453);
  padding: 0.375rem;
  border-radius: 1.186rem;
  position: relative;
  border-style: solid;
  border-width: 0.125rem;
  border-color: ${({inCart, theme}) => (inCart ? theme.colors.firstItemColor : 'transparent')};
  cursor: pointer;
  background-color: ${({theme}) => theme.colors.baseItemColor};
`

const ProductsListItemInnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media ${({theme}) => theme.queries.mobile} {
    grid-template-columns: auto 1fr;
  }
`

const ProductsListItemImgContainer = styled.div`
  width: 100%;
  height: 12.5rem;
  border-radius: 0.873rem;
  overflow: hidden;
  position: relative;

  @media ${({theme}) => theme.queries.mobile} {
    width: 7rem;
    height: 7rem;
  }
`

const ProductsListItemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ProductsListItemContentContainer = styled.div`
  padding: 0 0.5rem 0.5rem 0.5rem;
  display: grid;
  grid-template-rows: auto auto 1fr;
  row-gap: 0.25rem;
  height: 7.5rem;
  box-sizing: border-box;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 0.5rem 0.5rem 0.5rem 0;
    height: 7rem;
  }
`

const ProductsListItemTitle = styled(Text as unknown as AnyStyledComponent).attrs({
  color: 'baseItemTextColor',
  type: 'productBoxName',
  style: {
    wordBreak: 'break-word',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
})``

const ProductsListItemDescription = styled(
  Text as unknown as AnyStyledComponent,
).attrs<ProductsListItemDescriptionProps>(({isMobile}) => ({
  color: 'secondaryTextColor',
  style: {
    wordBreak: 'break-word',
    display: '-webkit-box',
    WebkitLineClamp: isMobile ? 1 : 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
}))<ProductsListItemDescriptionProps>``

const ProductsListItemPriceContainer = styled.div`
  padding-top: 0.25rem;
  align-self: end;
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  align-items: center;
  column-gap: 0.75rem;
`

const ProductsListItemActionsContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.625rem;
`

const AllergensDesktopContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0.3125rem;
  overflow: hidden;
`

const AllergensMobileContainer = styled.div`
  height: ${ALLERGEN_SMALL_HEIGHT}px;
  margin-bottom: 0.3125rem;
  overflow: hidden;
`

const ProductsListItem = (props: ProductsListItemProps) => {
  const cartItemTotalPrice = useSelector((state: RootState) =>
    selectCartItemTotalPrice(state, {cartItem: props.cartItem, product: props.product}),
  )
  const isLoyaltyCardType = useSelector((state: RootState) => selectIsLoyaltyCardType(state, props.product?.id!))

  const {isMobile} = useMediaQuery()

  const title = props.product.title!
  const price = cartItemTotalPrice
  const discountAmount = props.product.discountAmount!
  const priceWithoutDiscount = props.product.price! + discountAmount
  const inCart = !!props.cartItem

  const priceWithCurrency = useSelector((state: RootState) => selectPriceWithCurrency(state, price))
  const priceWithoutDiscountWithCurrency = useSelector((state: RootState) =>
    selectPriceWithCurrency(state, priceWithoutDiscount),
  )
  const onlyMenu = useSelector(selectOnlyMenu)
  const productTags = useSelector((state: RootState) => selectProductTags(state, props.product.id))

  const handleAdd = useCallback(() => {
    props.onAdd?.(props.product)
  }, [props])

  const handleIncrease = useCallback(() => {
    if (!props.cartItem) {
      return
    }
    props.onIncrease?.(props.cartItem.id)
  }, [props])

  const handleDecrease = useCallback(() => {
    if (!props.cartItem) {
      return
    }
    props.onDecrease?.(props.cartItem.id)
  }, [props])

  const handleItemClick = useCallback(() => {
    props.onItemClick({productId: props.product.id!, cartId: props.cartItem?.id})
  }, [props])

  return (
    <ProductsListItemContainer inCart={inCart} onClick={handleItemClick}>
      {isMobile && !!productTags.length && (
        <AllergensMobileContainer>
          <Allergens allergens={productTags} variant="small" />
        </AllergensMobileContainer>
      )}
      <ProductsListItemInnerContainer>
        <ProductsListItemImgContainer>
          <ProductsListItemImg src={mediaUrl(props.product.images?.[0])} alt={title} />
          {!isMobile && !!productTags.length && (
            <AllergensDesktopContainer>
              <Allergens allergens={productTags} align="end" />
            </AllergensDesktopContainer>
          )}
        </ProductsListItemImgContainer>
        <ProductsListItemContentContainer>
          <ProductsListItemTitle>{title}</ProductsListItemTitle>
          <ProductsListItemDescription type="productBoxDescription" isMobile={isMobile}>
            {props.product.description}
          </ProductsListItemDescription>
          <ProductsListItemPriceContainer>
            {onlyMenu && price > 0 ? (
              <>
                {price > 0 && (
                  <Text type="productBoxPrice" color="baseItemTextColor">
                    {priceWithoutDiscountWithCurrency}
                  </Text>
                )}
              </>
            ) : (
              <>
                {price > 0 && (
                  <Text type="productBoxPrice" color="baseItemTextColor">
                    {priceWithCurrency}
                  </Text>
                )}
                {discountAmount > 0 && (
                  <Text type="productBoxStrikethroughPrice" color="secondaryTextColor">
                    {priceWithoutDiscountWithCurrency}
                  </Text>
                )}
              </>
            )}
          </ProductsListItemPriceContainer>
        </ProductsListItemContentContainer>
        {!onlyMenu && (
          <ProductsListItemActionsContainer>
            {inCart ? (
              <ProductCounter
                count={props.cartItem?.count}
                addDisabled={isLoyaltyCardType}
                onAdd={handleIncrease}
                onRemove={handleDecrease}
              />
            ) : (
              <ProductsListItemAddButton onAdd={handleAdd} />
            )}
          </ProductsListItemActionsContainer>
        )}
      </ProductsListItemInnerContainer>
    </ProductsListItemContainer>
  )
}

export default memo(ProductsListItem)
