import styled from 'styled-components'
import {Fragment, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import OptionButton from './OptionButton'
import {TimeSlotContract} from 'src/types/api'
import {DATE_FORMAT, TIME_FORMAT, dayjs} from 'src/utilities/dayjs'
import Text from 'src/components/Text'

interface OptionsListProps {
  timeSlots?: TimeSlotContract[]
  disabled?: boolean
  onOptionClick?: (id: string) => void
}

const MainContainer = styled.div`
  display: grid;
  row-gap: 1.5rem;

  @media ${({theme}) => theme.queries.mobile} {
    row-gap: 1rem;
  }
`

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;

  @media ${({theme}) => theme.queries.mobile} {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
`

const OptionsList = ({timeSlots = [], disabled, onOptionClick}: OptionsListProps) => {
  const {t} = useTranslation()

  const options = useMemo(
    () =>
      timeSlots.reduce<{[date: string]: TimeSlotContract[]}>((obj, timeSlot) => {
        const date = dayjs.utc(timeSlot.date).format(DATE_FORMAT)

        if (!obj[date]) {
          obj[date] = []
        }

        obj[date].push(timeSlot)

        return obj
      }, {}),
    [timeSlots],
  )

  const getDateLabel = (date: string) => {
    const utc = dayjs.utc(date)
    const day = utc.format('D')

    if (utc.isToday()) {
      return t('pages.deliveryTime.components.optionsList.todaysDay', {day})
    }

    if (utc.isTomorrow()) {
      return t('pages.deliveryTime.components.optionsList.tomorrowsDay', {day})
    }

    return day
  }

  return (
    <MainContainer>
      {Object.entries(options).map(([date, options]) => (
        <Fragment key={date}>
          <Text type="checkoutPageOptionTitle" color="secondaryTextColor">
            {getDateLabel(date)}
          </Text>

          <OptionsContainer>
            {options.map((option) => (
              <OptionButton key={option.id} disabled={disabled} onClick={() => onOptionClick?.(option.id!)}>
                {dayjs.utc(option.date).format(TIME_FORMAT)}
              </OptionButton>
            ))}
          </OptionsContainer>
        </Fragment>
      ))}
    </MainContainer>
  )
}

export default OptionsList
