import {memo} from 'react'
import Lottie, {Options, LottieProps} from 'react-lottie'

const Animation = ({options, ...props}: LottieProps) => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    ...options,
  }

  // @ts-expect-error */
  return <Lottie options={defaultOptions} {...props} />
}

export default memo(Animation)
