import styled, {AnyStyledComponent} from 'styled-components'

import Text from 'src/components/Text'
import NewModal from 'src/components/NewModal'
import {useMediaQuery} from 'src/utilities/hooks'
import Animation from 'src/components/Animation'
import circleSpinningAnimation from 'src/assets/lotties/circleSpinningAnimation.json'
import dotsAnimation from 'src/assets/lotties/dotsAnimation.json'

interface OrderLoadingModalProps {
  isOpen?: boolean
  title?: string
  description?: string
}

const MainContainer = styled.div`
  border-radius: 1.25rem;
`

const ContentContainer = styled.div`
  display: grid;
  justify-items: center;
  row-gap: 1.375rem;
  padding: 4.5rem 0.75rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 2.125rem 0.75rem;
  }
`

const Title = styled(Text as unknown as AnyStyledComponent).attrs({
  color: 'baseItemTextColor',
  style: {
    fontWeight: 400,
    fontSize: '1.5rem',
  },
})``

const OuterAnimationContainer = styled.div`
  width: 10.583rem;
  height: 10.583rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const InnerAnimationContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OrderLoadingModal = ({isOpen, title, description}: OrderLoadingModalProps) => {
  const {isMobile} = useMediaQuery()

  return (
    <NewModal isOpen={!!isOpen} width={isMobile ? '100%' : '30rem'} onRequestClose={() => {}}>
      <MainContainer>
        <ContentContainer>
          <OuterAnimationContainer>
            <Animation options={{animationData: circleSpinningAnimation}} width="16.333rem" height="16.333rem" />
            <InnerAnimationContainer>
              <Animation options={{animationData: dotsAnimation}} width="3.833rem" height="auto" />
            </InnerAnimationContainer>
          </OuterAnimationContainer>
          <Title type="checkoutPageTitle" align="center">
            {title}
          </Title>
          <Text type="checkoutPageOptionTitle" align="center" color="baseItemTextColor">
            {description}
          </Text>
        </ContentContainer>
      </MainContainer>
    </NewModal>
  )
}

export default OrderLoadingModal
