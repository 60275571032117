import {memo} from 'react'
import {useIntercom} from 'react-use-intercom'

import IconButton from './IconButton'

const IntercomButton = () => {
  const intercom = useIntercom()

  const handleClick = () => {
    if (intercom.isOpen) {
      intercom.hide()
      return
    }

    intercom.show()
  }

  return <IconButton name="chat" variant="gray" hideShadow onClick={handleClick} />
}

export default memo(IntercomButton)
