import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'

import FormInput from '../FormInput'
import Button from '../Button'
import Error from '../Error'
import FileItemsList from '../FileItemsList'
import FormFileUploadButton from '../FormFileUploadButton'
import {FILE_SIZE, MAX_UPLOAD_FILE_SIZE, PHONE_NUMBER} from 'src/utilities/constants'
import {getErrorMsgs} from 'src/pages/DynamicPage/functions'

const schema: yup.ObjectSchema<ApplicationFormValues> = yup.object({
  fullName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .required()
    .test(PHONE_NUMBER, (phone) => {
      return phone.startsWith('+3706') && phone.length === 12
    }),
  comment: yup.string().required(),
  files: yup
    .array()
    .required()
    .test(FILE_SIZE, (files: File[]) => {
      return !files.some((file) => file.size > MAX_UPLOAD_FILE_SIZE)
    }),
})

const DEFAULT_APPLICATION_FORM_VALUES: ApplicationFormValues = {
  fullName: '',
  email: '',
  phone: '',
  comment: '',
  files: [],
}

export interface ApplicationFormValues {
  fullName: string
  email: string
  phone: string
  comment: string
  files: File[]
}

interface ApplicationFormProps {
  loading?: boolean
  onSubmit?: (data: ApplicationFormValues) => Promise<{error: boolean} | undefined>
}

const InputsContainer = styled.form`
  display: grid;
  row-gap: 1.25rem;
  max-width: 28.125rem;
  margin: 0 auto;
  width: 100%;

  @media ${({theme}) => theme.queries.mobile} {
    max-width: none;
  }
`

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;

  @media ${({theme}) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const ApplicationForm = ({loading, onSubmit}: ApplicationFormProps) => {
  const {t} = useTranslation()

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    control,
    formState: {errors, isSubmitted},
  } = useForm<ApplicationFormValues>({
    defaultValues: DEFAULT_APPLICATION_FORM_VALUES,
    resolver: yupResolver(schema),
  })

  const files = watch('files')

  const errorMsgs = getErrorMsgs(errors)

  const handleRemoveClick = (file: File) => {
    setValue(
      'files',
      files.filter((f) => f.name !== file.name),
    )
  }

  const handleFormSubmit = handleSubmit(async (data) => {
    const res = await onSubmit?.(data)
    if (res?.error) {
      return
    }

    reset(DEFAULT_APPLICATION_FORM_VALUES)
  })

  useEffect(() => {
    if (!isSubmitted) {
      return
    }

    trigger('files')
  }, [isSubmitted, trigger, files])

  return (
    <InputsContainer onSubmit={handleFormSubmit}>
      <FormInput
        type="text"
        name="fullName"
        placeholder={t('pages.dynamicPage.components.applicationForm.fullNamePlaceholder')}
        control={control}
        disabled={loading}
      />

      <FormInput
        type="email"
        name="email"
        placeholder={t('pages.dynamicPage.components.applicationForm.emailPlaceholder')}
        control={control}
        disabled={loading}
      />

      <FormInput
        type="text"
        name="phone"
        placeholder={t('pages.dynamicPage.components.applicationForm.phonePlaceholder')}
        control={control}
        disabled={loading}
      />

      <FormInput
        type="textarea"
        name="comment"
        placeholder={t('pages.dynamicPage.components.applicationForm.commentPlaceholder')}
        control={control}
        disabled={loading}
        height="11.25rem"
      />

      <FileItemsList files={files} loading={loading} showErrors={isSubmitted} onRemoveClick={handleRemoveClick} />

      <ButtonsContainer>
        <FormFileUploadButton control={control} name="files" disabled={loading}>
          {t('pages.dynamicPage.components.applicationForm.uploadButtonLabel')}
        </FormFileUploadButton>

        <Button type="submit" disabled={loading}>
          {t('pages.dynamicPage.components.applicationForm.sendButtonLabel')}
        </Button>
      </ButtonsContainer>

      {errorMsgs.map((errorMsg, index) => (
        <Error key={index} msg={errorMsg} />
      ))}
    </InputsContainer>
  )
}

export default ApplicationForm
