import {ButtonHTMLAttributes, ReactNode} from 'react'
import styled from 'styled-components'

import Text from 'src/components/Text'
import {useMediaQuery} from 'src/utilities/hooks'

interface OptionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  RightComponent?: ReactNode
}

const MainContainer = styled.div`
  height: 3.375rem;
  display: grid;
  background-color: ${({theme}) => theme.colors.baseItemColor};
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 1.25rem;
  position: relative;

  @media ${({theme}) => theme.queries.mobile} {
    height: 2.25rem;
    border-radius: 0.938rem;
  }
`

const Button = styled.button`
  border: none;
  padding: 0;
  border-radius: inherit;
  overflow: hidden;
  background-color: transparent;

  :enabled {
    cursor: pointer;
  }
`

const RightContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
  display: grid;
  align-content: center;
`

const OptionButton = ({children, RightComponent, ...props}: OptionButtonProps) => {
  const {isMobile} = useMediaQuery()

  return (
    <MainContainer>
      <Button type="button" {...props}>
        <Text align="center" type={isMobile ? 'checkoutPageOptionTitle' : 'modalOptions'} color="baseItemTextColor">
          {children}
        </Text>
      </Button>

      {!!RightComponent && <RightContainer>{RightComponent}</RightContainer>}
    </MainContainer>
  )
}

export default OptionButton
