import {createModel} from '@rematch/core'
import {AxiosResponse} from 'axios'

import {RootModel} from '.'
import {AppraisalContract, CreateAppraisalContract, TipAppraisalContract, RateAppraisalContract} from 'src/types/api'
import api from 'src/utilities/api'

interface AppraisalsState {}

const initialState: AppraisalsState = {}

export const appraisals = createModel<RootModel>()({
  state: initialState,
  reducers: {},
  effects: () => ({
    async createAppraisal(payload: CreateAppraisalContract) {
      const response: AxiosResponse<AppraisalContract> = await api.post('/appraisals', payload)
      return response.data
    },
    async getAppraisal(id: string) {
      const response: AxiosResponse<AppraisalContract> = await api.get(`/appraisals/${id}`)
      return response.data
    },
    async addTipToAppraisal({id, ...data}: {id: string} & TipAppraisalContract) {
      const response: AxiosResponse<AppraisalContract> = await api.post(`/appraisals/${id}/tip`, data)
      return response.data
    },
    async addRatingToAppraisal({id, ...data}: {id: string} & RateAppraisalContract) {
      const response: AxiosResponse<AppraisalContract> = await api.post(`/appraisals/${id}/rate`, data)
      return response.data
    },
  }),
})
