import {createModel} from '@rematch/core'
import {AxiosResponse} from 'axios'
import {createSelector} from 'reselect'

import {RootModel} from '.'
import api from 'src/utilities/api'
import {QrUserProfileContract} from 'src/types/api'
import {RootState} from 'src/utilities/store'
import {priceWithCurrency} from 'src/utilities/functions'

interface ProfileState {
  user: QrUserProfileContract | null
  userLoading: boolean
}

const initialState: ProfileState = {
  user: null,
  userLoading: false,
}

export const profile = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setUser(state, user: QrUserProfileContract) {
      state.user = user
    },
    setUserLoading(state, userLoading: boolean) {
      state.userLoading = userLoading
    },
  },
  effects: (dispatch) => ({
    async fetchUser() {
      const response: AxiosResponse<QrUserProfileContract> = await api.get('/qr/profile')
      dispatch.profile.setUser(response.data)
      return response.data
    },
    async fetchUserLanguages() {
      const response: AxiosResponse<QrUserProfileContract> = await api.get('/qr/profile')
      return response.data.languages
    },
  }),
})

export const selectCallingCode = createSelector(
  (rootState: RootState) => rootState.profile,
  (_: RootState, callingCodeId?: string) => callingCodeId,
  (state, callingCodeId) => state.user?.callingCodes?.find((callingCode) => callingCode?.id === callingCodeId),
)

export const selectAcceptsAllOrderTypes = createSelector(
  (rootState: RootState) => rootState.profile,
  () => {},
  (state) => !!state.user?.isAcceptingTakeAwayOrders && !!state.user?.isAcceptingTableOrders,
)

export const selectPriceWithCurrency = createSelector(
  (rootState: RootState) => rootState.profile,
  (_: RootState, price?: number) => price,
  (state, price) => priceWithCurrency(price, state.user?.currency, state.user?.hideCurrencySymbol),
)

export const selectOnlyMenu = createSelector(
  (rootState: RootState) => rootState.profile,
  (state: RootState) => state.timeSlots.timeSlots,
  (state, timeSlots) =>
    (!state.user?.isAcceptingTakeAwayOrders && !state.user?.isAcceptingTableOrders) ||
    !state.user.isActive ||
    (!state.user.isOpen && !!state.user.enableTimeSlots && !timeSlots.length),
)
