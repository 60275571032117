import styled from 'styled-components'

import StarImg from 'src/assets/images/star.png'
import StarFilledImg from 'src/assets/images/star-filled.png'

const STAR_ICON_SIZE = '2.125rem'
const STAR_ICON_SM_SIZE = '1.5rem'

interface StarsProps {
  ratedRating?: number
  disabled?: boolean
  tipped?: boolean
  onRate?: (rating: number) => void
}

interface StarsContainerProps {
  tipped?: boolean
}

interface StarProps extends StarsProps {
  rating: number
}

interface StarContainerProps {
  disabled?: boolean
  tipped?: boolean
}

const StarsContainer = styled.div<StarsContainerProps>`
  display: flex;
  column-gap: ${({tipped}) => (tipped ? 0.45 : 0.625)}rem;
`

const StarContainer = styled.div<StarContainerProps>`
  width: ${({tipped}) => (tipped ? STAR_ICON_SM_SIZE : STAR_ICON_SIZE)};
  height: ${({tipped}) => (tipped ? STAR_ICON_SM_SIZE : STAR_ICON_SIZE)};
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
  cursor: pointer;
`

const StarImgStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Star = (props: StarProps) => {
  const rated = (props.ratedRating ?? 0) >= props.rating

  const handleRate = () => {
    props.onRate?.(props.rating)
  }

  return (
    <StarContainer tipped={props.tipped} disabled={props.disabled} onClick={handleRate}>
      <StarImgStyled src={rated ? StarFilledImg : StarImg} alt={`star-${props.rating}`} />
    </StarContainer>
  )
}

const Stars = (props: StarsProps) => {
  return (
    <StarsContainer tipped={props.tipped}>
      {Array.from({length: 5}).map((_, index) => (
        <Star
          key={index}
          ratedRating={props.ratedRating}
          rating={index + 1}
          disabled={props.disabled}
          tipped={props.tipped}
          onRate={props.onRate}
        />
      ))}
    </StarsContainer>
  )
}

export default Stars
