import {ComponentPropsWithoutRef} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import SelectButton from 'src/components/SelectButton'
import LocationIcon from 'src/assets/icons/location.svg?react'

type Variant = 'primary' | 'secondary'

interface NearestRestaurantButtonProps extends ComponentPropsWithoutRef<'button'> {
  variant?: Variant
}

interface LocationImgProps {
  variant?: Variant
}

const SelectButtonStyled = styled(SelectButton)<NearestRestaurantButtonProps>`
  ${({variant, theme}) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.secondItemColor};
          color: ${theme.colors.primaryTextColor};
        `
      case 'secondary':
        return `
          background-color: ${theme.colors.firstItemColor};
          color: ${theme.colors.primaryTextColor};
        `
      default:
        return
    }
  }}
`

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
`

const LocationImg = styled(LocationIcon as unknown as AnyStyledComponent)<LocationImgProps>`
  width: 2.125rem;
  height: 2.125rem;
  ${({variant, theme}) => {
    switch (variant) {
      case 'primary':
        return `color: ${theme.colors.primaryTextColor};`
      default:
        return `color: ${theme.colors.primaryTextColor};`
    }
  }}

  @media ${({theme}) => theme.queries.mobile} {
    width: 1.625rem;
    height: 1.625rem;
  }
`

const NearestRestaurantButton = ({children, variant = 'primary', ...props}: NearestRestaurantButtonProps) => {
  return (
    <SelectButtonStyled variant={variant} {...props}>
      <ContentContainer>
        <LocationImg variant={variant} />

        {children}
      </ContentContainer>
    </SelectButtonStyled>
  )
}

export default NearestRestaurantButton
