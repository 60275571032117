import {useTranslation} from 'react-i18next'

import AddInfoCard from 'src/components/AddInfoCard'
import NewModal from 'src/components/NewModal'
import {useMediaQuery} from 'src/utilities/hooks'

interface TipsAmountModalProps {
  isOpen?: boolean
  value?: string
  onBack?: () => void
  onDone?: (amount: number) => void
}

const TipsAmountModal = (props: TipsAmountModalProps) => {
  const {t} = useTranslation()

  const {isMobile} = useMediaQuery()

  const handleDone = (value: string) => {
    const amount = Number(value)
    props.onDone?.(amount > 0 ? amount : 0)
  }

  return (
    <NewModal isOpen={!!props.isOpen} width={isMobile ? '100%' : '30rem'} onRequestClose={props.onBack}>
      <AddInfoCard
        value={props.value}
        title={t('components.tipsAmountModal.title')}
        placeholder={t('components.tipsAmountModal.placeholder')}
        autoFocus
        inputType="number"
        onBackClick={props.onBack}
        onDoneClick={handleDone}
      />
    </NewModal>
  )
}

export default TipsAmountModal
