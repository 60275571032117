import {memo, useEffect, useState} from 'react'
import styled from 'styled-components'

import {getWindowSizes} from 'src/utilities/functions'

interface ContainerProps {
  height: number
}

const Container = styled.div<ContainerProps>`
  height: ${({height}) => height}px;
  transition: height 0.25s;
`

const CollapsibleContainer = () => {
  const [height, setHeight] = useState<number>(getWindowSizes().height)

  useEffect(() => {
    setHeight(0)
  }, [])

  return <Container height={height} />
}

export default memo(CollapsibleContainer)
