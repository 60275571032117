import {useTranslation} from 'react-i18next'

import AddInfoCard from 'src/components/AddInfoCard'
import NewModal from 'src/components/NewModal'
import {useMediaQuery} from 'src/utilities/hooks'

interface GuestIdentificationModalProps {
  isOpen?: boolean
  guestIdentification?: string
  disabled?: boolean
  required?: boolean
  onClose?: () => void
  onDone?: (guestIdentification: string) => void
}

const GuestIdentificationModal = (props: GuestIdentificationModalProps) => {
  const {t} = useTranslation()

  const {isMobile} = useMediaQuery()

  return (
    <NewModal isOpen={!!props.isOpen} width={isMobile ? '100%' : '30rem'} onRequestClose={props.onClose}>
      <AddInfoCard
        value={props.guestIdentification}
        title={t('pages.checkout.components.guestIdentificationModal.title')}
        placeholder={t('pages.checkout.components.guestIdentificationModal.placeholder')}
        inputType="text"
        disabled={props.disabled}
        autoFocus
        required={props.required}
        onBackClick={props.onClose}
        onDoneClick={props.onDone}
      />
    </NewModal>
  )
}

export default GuestIdentificationModal
