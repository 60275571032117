import {Fragment, ReactNode, useEffect, useMemo, useRef} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'
import {NavLink} from 'react-router-dom'
import {useSelector} from 'react-redux'

import IconButton from 'src/components/IconButton'
import {useClickOutside, useTabletId} from 'src/utilities/hooks'
import {RootState} from 'src/utilities/store'
import SocialIcons, {Icon as SocialIconsIcon} from './components/SocialIcons'
import {DESKTOP_FOOTER_HEIGHT} from 'src/components/Footer/components/DesktopFooter'
import {MOBILE_FOOTER_HEIGHT} from 'src/components/Footer/components/MobileFooter'
import BrandLogo from 'src/components/Footer/components/DesktopFooter/components/BrandLogo'
import TestGroupHostContainer from 'src/components/TestGroupHostContainer'
import {IS_STAGING} from 'src/utilities/constants'
import {selectCustomPages, selectMenuFooterPages} from 'src/models/website'
import CustomHTML from 'src/components/CustomHTML'
import {setBodyOverflow} from 'src/utilities/functions'
import {CustomPageType} from 'src/types/api'

interface SidebarProps {
  visible?: boolean
  FooterComponent?: ReactNode
  onClose?: () => void
}

interface MainContainerProps {
  visible?: boolean
}

interface ItemTitleProps {
  active?: boolean
}

interface Item {
  to: string
  title: string
}

const MainContainer = styled.div<MainContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #d9d9d966;
  z-index: 2;
  display: ${({visible}) => (visible ? 'grid' : 'none')};
  align-content: end;
  backdrop-filter: blur(0.125rem);
`

const SidebarContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  max-width: 37.5rem;
  width: 100%;
  background-color: ${({theme}) => theme.colors.backgroundColor};
  box-shadow: 0 2px 30px 0 #e9e9e980;
  border-top-left-radius: 1.25rem;
  padding-bottom: calc(${DESKTOP_FOOTER_HEIGHT}px + 2rem);
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr auto;

  @media ${({theme}) => theme.queries.mobile} {
    max-width: none;
    width: calc(100vw - 4.5rem);
    padding-bottom: calc(${MOBILE_FOOTER_HEIGHT}px + 0.75rem);
  }
`

const SidebarTopContainer = styled.div`
  overflow: auto;
  padding: 0.9375rem 8.75rem 0 3.75rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 0.375rem 0.75rem 0 1.5rem;
  }
`

const SidebarBottomContainer = styled.div`
  padding: 2rem 8.75rem 0 3.75rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 0.75rem 0.75rem 0 1.5rem;
  }
`

const CloseButtonContainer = styled.div`
  display: grid;
  justify-content: end;
`

const ItemsContainer = styled.div`
  display: grid;
  row-gap: 1.875rem;
  padding-top: 2rem;

  @media ${({theme}) => theme.queries.mobile} {
    row-gap: 1.563rem;
  }
`

const ItemLink = styled(NavLink as AnyStyledComponent)`
  text-decoration: none;
`

const ItemTitle = styled.span<ItemTitleProps>`
  ${({theme}) => ({...theme.typography.modalTitleMedium})}
  color: ${({theme, active}) => (active ? theme.colors.tertiaryTextColor : theme.colors.baseItemTextColor)};
  display: block;
  text-align: end;

  @media ${({theme}) => theme.queries.mobile} {
    ${({theme}) => ({...theme.typography.payButton})}
  }

  :hover {
    color: ${({theme}) => theme.colors.tertiaryTextColor};
  }
`

const Divider = styled.div`
  height: 0.125rem;
  background-color: ${({theme}) => theme.colors.secondaryTextColor}80;
  margin: 3rem 0;

  @media ${({theme}) => theme.queries.mobile} {
    margin: 1.5rem 0;
  }
`

const SocialIconsContainer = styled.div`
  display: grid;
  justify-content: end;
`

const MenuFooterPagesContainer = styled.div`
  padding-top: 3rem;
  display: grid;
  row-gap: 1.875rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding-top: 1.5rem;
    row-gap: 1.563rem;
  }
`

const Sidebar = ({visible, FooterComponent, onClose}: SidebarProps) => {
  const websiteData = useSelector((state: RootState) => state.website.websiteData)

  const menuFooterPages = useSelector(selectMenuFooterPages)
  const customPages = useSelector(selectCustomPages)

  const sidebarRef = useRef<HTMLDivElement>(null)

  const tabletId = useTabletId()

  const items = useMemo((): Item[] => {
    return customPages.map((page) => {
      switch (page.type) {
        case CustomPageType.Menu:
          return {
            title: page.title ?? '',
            to: `/${tabletId}`,
          }
        default:
          return {
            title: page.title ?? '',
            to: `/${page.path}`,
          }
      }
    })
  }, [customPages, tabletId])

  const socialIcons = useMemo((): SocialIconsIcon[] => {
    const icons: SocialIconsIcon[] = []

    const facebookUrl = websiteData?.facebookUrl
    const instagramUrl = websiteData?.instagramUrl
    const linkedInUrl = websiteData?.linkedInUrl
    const xUrl = websiteData?.twitterUrl

    if (facebookUrl) {
      icons.push({url: facebookUrl, social: 'facebook'})
    }
    if (instagramUrl) {
      icons.push({url: instagramUrl, social: 'instagram'})
    }
    if (linkedInUrl) {
      icons.push({url: linkedInUrl, social: 'linkedin'})
    }
    if (xUrl) {
      icons.push({url: xUrl, social: 'x'})
    }

    return icons
  }, [websiteData?.facebookUrl, websiteData?.instagramUrl, websiteData?.linkedInUrl, websiteData?.twitterUrl])

  const handleClose = () => {
    setBodyOverflow('visible')

    onClose?.()
  }

  useClickOutside(sidebarRef.current, () => {
    if (!visible) {
      return
    }

    handleClose()
  })

  useEffect(() => {
    if (!visible) {
      return
    }

    setBodyOverflow('hidden')
  }, [visible])

  const renderItemLink = (item: Item) => {
    return (
      <ItemLink to={item.to} end onClick={handleClose}>
        {/* @ts-expect-error */}
        {({isActive}) => <ItemTitle active={isActive}>{item.title}</ItemTitle>}
      </ItemLink>
    )
  }

  return (
    <MainContainer visible={visible}>
      <SidebarContainer ref={sidebarRef}>
        <SidebarTopContainer>
          <CloseButtonContainer>
            <IconButton name="menu-close" onClick={handleClose} />
          </CloseButtonContainer>

          <ItemsContainer>
            {items.map((item, index) => (
              <Fragment key={index}>{renderItemLink(item)}</Fragment>
            ))}
          </ItemsContainer>

          <Divider />

          <SocialIconsContainer>
            <SocialIcons icons={socialIcons} />
          </SocialIconsContainer>

          {!!menuFooterPages.length && (
            <MenuFooterPagesContainer>
              {menuFooterPages.map((page) => (
                <CustomHTML key={page.id} html={page.html!} />
              ))}
            </MenuFooterPagesContainer>
          )}
        </SidebarTopContainer>

        {!!websiteData?.showBrandOnlyInSidebar && (
          <SidebarBottomContainer>
            {IS_STAGING ? (
              <TestGroupHostContainer>
                <BrandLogo hidePrefix />
              </TestGroupHostContainer>
            ) : (
              <BrandLogo hidePrefix />
            )}
          </SidebarBottomContainer>
        )}
      </SidebarContainer>

      {FooterComponent}
    </MainContainer>
  )
}

export default Sidebar
