import {memo, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import AddInfoCard from 'src/components/AddInfoCard'
import {useMediaQuery} from 'src/utilities/hooks'
import NewModal from 'src/components/NewModal'

interface ClientCodeModalProps {
  isOpen: boolean
  disabled?: boolean
  onClose?: () => void
  onDone?: (code: string) => void
}

const ClientCodeModal = (props: ClientCodeModalProps) => {
  const {t} = useTranslation()

  const {isMobile} = useMediaQuery()

  const handleDoneClick = useCallback(
    (code: string) => {
      props.onDone?.(code)
    },
    [props],
  )

  return (
    <NewModal isOpen={props.isOpen} width={isMobile ? '100%' : '30rem'} onRequestClose={props.onClose}>
      <AddInfoCard
        title={t('pages.checkout.components.clientCodeModal.title')}
        placeholder={t('pages.checkout.components.clientCodeModal.placeholder')}
        autoFocus
        disabled={props.disabled}
        inputType="text"
        onBackClick={props.onClose}
        onDoneClick={handleDoneClick}
      />
    </NewModal>
  )
}

export default memo(ClientCodeModal)
