import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  InputHTMLAttributes,
  SVGProps,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import Text from './Text'
import {useMediaQuery} from 'src/utilities/hooks'
import MenuCloseIcon from 'src/assets/icons/menu-close.svg?react'

export interface CardInputRef {
  clearInputValue: () => void
}

interface CardInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: boolean
  inputRequired?: boolean
  showRemoveButton?: boolean
  Icon?: FC<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  onInputChange?: (value: string) => void
  onInputBlur?: (value: string) => void
  onRemoveClick?: () => void
}

interface LabelProps {
  error?: boolean
}

interface InputProps {
  showRemoveButton?: boolean
}

const MainContainer = styled.div`
  @media ${({theme}) => theme.queries.mobile} {
    display: grid;
    row-gap: 1.125rem;
  }
`

const CardContainer = styled.div`
  background-color: ${({theme}) => theme.colors.baseItemColor};
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.043679);
  border-radius: 0.563rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 1rem 0.75rem 1rem 0;
`

const CardContentContainer = styled.div`
  display: grid;
  row-gap: 0.5rem;
`

const IconContainer = styled.div`
  width: 5rem;
  display: grid;
  justify-content: center;

  @media ${({theme}) => theme.queries.mobile} {
    width: 3.813rem;
  }
`

const Input = styled.input<InputProps>`
  height: 2.063rem;
  padding: 0 ${({showRemoveButton}) => (showRemoveButton ? 'calc(0.75rem + 1rem + 0.75rem)' : '0.75rem')} 0 0.75rem;
  border: 1px solid rgba(60, 60, 67, 0.29);
  border-radius: 0.563rem;
  outline: none;
  color: ${({theme}) => theme.colors.primaryTextColor};
  background-color: ${({theme}) => theme.colors.fifthItemColor};
  ${({theme}) => ({...theme.typography.placeholder})};

  ::placeholder {
    color: ${({theme}) => theme.colors.placeholderTextColor};
    opacity: 0.7;
  }
`

const InputContainer = styled.div`
  display: grid;
  position: relative;
`

const RemoveButtonContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
`

const RemoveButton = styled.button.attrs({
  type: 'button',
})`
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: block;
  width: 1rem;
  height: 1rem;

  :enabled {
    cursor: pointer;
  }
`

const RemoveButtonImage = styled(MenuCloseIcon as unknown as AnyStyledComponent)`
  width: 100%;
  height: 100%;
  color: ${({theme}) => theme.colors.primaryTextColor};
`

const Label = styled(Text as unknown as AnyStyledComponent).attrs<LabelProps>(({error}) => ({
  color: error ? 'errorTextColor' : 'baseItemTextColor',
}))<LabelProps>``

const CardInput = forwardRef<CardInputRef, CardInputProps>(
  (
    {value, label, error, inputRequired, showRemoveButton, Icon, onInputChange, onInputBlur, onRemoveClick, ...props},
    ref,
  ) => {
    const [inputValue, setInputValue] = useState<string>('')

    const {isMobile} = useMediaQuery()

    const formattedLabel = useMemo(() => {
      return inputRequired ? `${label}*` : label
    }, [label, inputRequired])

    const clearInputValue = () => {
      setInputValue('')
    }

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      const value = e.target.value

      setInputValue(value)

      onInputChange?.(value)
    }

    const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      onInputBlur?.(e.target.value)
    }

    useEffect(() => {
      if (!value) {
        return
      }

      setInputValue(String(value))
    }, [value])

    useImperativeHandle(
      ref,
      () => {
        return {clearInputValue}
      },
      [],
    )

    return (
      <MainContainer>
        {isMobile && !!label && (
          <Label type="productPageOptionName" error={error}>
            {formattedLabel}
          </Label>
        )}

        <CardContainer>
          {!!Icon && (
            <IconContainer>
              <Icon width="auto" height={isMobile ? '1.813rem' : '2.188rem'} />
            </IconContainer>
          )}

          <CardContentContainer>
            {!isMobile && label && (
              <Label type="checkoutTitle" error={error}>
                {formattedLabel}
              </Label>
            )}

            <InputContainer>
              <Input
                value={inputValue}
                showRemoveButton={showRemoveButton}
                onBlur={handleBlur}
                onChange={handleChange}
                {...props}
              />

              {!!showRemoveButton && (
                <RemoveButtonContainer>
                  <RemoveButton onClick={onRemoveClick}>
                    <RemoveButtonImage />
                  </RemoveButton>
                </RemoveButtonContainer>
              )}
            </InputContainer>
          </CardContentContainer>
        </CardContainer>
      </MainContainer>
    )
  },
)

export default CardInput
