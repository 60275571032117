import {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import Label from '../Rating/components/Label'
import TipsAmount from '../Rating/components/TipsAmount'
import Dots from './components/Dots'
import TipsAmountModal from './components/TipsAmountModal'
import TipsButton from './components/TipsButton'
import {RootState} from 'src/utilities/store'
import {CURRENCY_SYMBOLS} from 'src/utilities/constants'

export enum Type {
  PERCENTAGES = 'PERCENTAGES',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
}

interface TipsProps {
  type: Type
  values?: number[]
  orderPrice?: number
  disabled?: boolean
  tipped?: boolean
  amount?: number
  onAmountChange?: (amount: number) => void
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TipsButtonsContainer = styled.div`
  display: flex;
  column-gap: 0.8125rem;
  padding: 0.9375rem 0 2.9375rem 0;
`

const Tips = (props: TipsProps) => {
  const [tipsModalOpen, setTipsModalOpen] = useState<boolean>(false)

  const currency = useSelector((state: RootState) => state.profile.user?.currency)

  const {t} = useTranslation()

  const amounts = useMemo(() => {
    if (props.type === Type.FIXED_AMOUNT) {
      return {...props.values}
    }

    return props.values?.reduce<{[key: number]: number}>((obj, value, index) => {
      if (!props.orderPrice) {
        return obj
      }

      const calculatedAmount = (props.orderPrice * value) / 100

      obj[index] = Number(calculatedAmount.toFixed(2))

      return obj
    }, {})
  }, [props.orderPrice, props.type, props.values])

  const selected = useCallback(
    (index: number) => {
      if (index === props.values?.length) {
        return !!props.amount && !Object.values(amounts ?? {}).some((amount) => amount === props.amount)
      }

      return props.amount === amounts?.[index]
    },
    [amounts, props.amount, props.values?.length],
  )

  const toggleTipsModal = () => {
    setTipsModalOpen((prevTipsModalOpen) => !prevTipsModalOpen)
  }

  const handleTipsModalDone = (amount: number) => {
    props.onAmountChange?.(amount)
    toggleTipsModal()
  }

  return (
    <>
      <MainContainer>
        <Label visible={!props.tipped}>{t('components.tips.label')}</Label>
        <TipsButtonsContainer>
          {props.values?.map((value, index) => (
            <TipsButton
              key={value}
              disabled={props.disabled}
              selected={selected(index)}
              onClick={() => props.onAmountChange?.(amounts?.[index] ?? 0)}
            >{`${value}${
              props.type === Type.FIXED_AMOUNT ? (currency ? CURRENCY_SYMBOLS[currency] : '') : '%'
            }`}</TipsButton>
          ))}
          <TipsButton
            disabled={props.disabled}
            selected={selected(props.values?.length ?? 0)}
            onClick={toggleTipsModal}
          >
            <Dots />
          </TipsButton>
        </TipsButtonsContainer>
        <TipsAmount amount={props.amount} tipped={props.tipped} />
      </MainContainer>
      <TipsAmountModal isOpen={tipsModalOpen} onDone={handleTipsModalDone} onBack={toggleTipsModal} />
    </>
  )
}

export default Tips
