import {useTranslation} from 'react-i18next'

import AddInfoCard from 'src/components/AddInfoCard'
import NewModal from 'src/components/NewModal'
import {useMediaQuery} from 'src/utilities/hooks'

interface PagerNumberModalProps {
  isOpen?: boolean
  pagerNumber?: string
  onClose?: () => void
  onDone?: (pagerNumber: string) => void
}

const PagerNumberModal = (props: PagerNumberModalProps) => {
  const {t} = useTranslation()

  const {isMobile} = useMediaQuery()

  return (
    <NewModal isOpen={!!props.isOpen} width={isMobile ? '100%' : '30rem'} onRequestClose={props.onClose}>
      <AddInfoCard
        value={props.pagerNumber}
        title={t('pages.checkout.components.pagerNumberModal.title')}
        placeholder={t('pages.checkout.components.pagerNumberModal.placeholder')}
        autoFocus={true}
        required={true}
        onBackClick={props.onClose}
        onDoneClick={props.onDone}
      />
    </NewModal>
  )
}

export default PagerNumberModal
