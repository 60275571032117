import {memo, MouseEventHandler, ReactNode} from 'react'
import styled from 'styled-components'

import Text from './Text'

interface PrimaryButtonProps {
  width?: string
  maxWidth?: string
  disabled?: boolean
  children?: ReactNode
  type?: 'button' | 'submit' | 'reset'
  onClick?: MouseEventHandler<HTMLButtonElement>
}

interface ButtonProps {
  width?: string
  maxWidth?: string
}

const Button = styled.button<ButtonProps>`
  padding: 0;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
  box-shadow: ${({theme}) => theme.colors.firstItemShadowColor};
  background-color: ${({theme}) => theme.colors.firstItemColor};
  width: ${({width}) => width ?? '100%'};
  height: 2.75rem;
  max-width: ${({maxWidth}) => maxWidth ?? 'none'};
`

const PrimaryButton = (props: PrimaryButtonProps) => {
  return (
    <Button
      type={props.type}
      width={props.width}
      maxWidth={props.maxWidth}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Text type="productPageOptionName" align="center" color="primaryTextColor">
        {props.children}
      </Text>
    </Button>
  )
}

export default memo(PrimaryButton)
